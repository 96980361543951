import React from 'react';

const TextEN = () => {
  return (
    <>
      <h1>PRIVACY POLICY</h1>
      <h2>1. PRIVACY POLICY AT A GLANCE </h2>

      <h3>GENERAL INFORMATION</h3>

      <p>The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data is any data that can be used to personally identify you. For detailed information on data protection, please refer to our privacy policy listed below this text.</p>

      <h3>DATA COLLECTION ON THIS WEBSITE </h3>

      <h4>WHO IS RESPONSIBLE FOR DATA COLLECTION ON THIS WEBSITE? </h4>
      <p>Data processing on this website is carried out by the website operator. You can find their contact details in the “Note on the responsible party” section of this privacy policy.</p>

      <h4>HOW DO WE COLLECT YOUR DATA?</h4> 
      <p>Your data is collected when you provide it to us. This can, for example, be data you enter into a contact form.</p>

      <p>Other data is automatically collected by our IT systems when you visit the website. This is mainly technical data (e.g. internet browser, operating system or time of page visit). This data is collected automatically as soon as you enter our website.</p>

      <h4>WHAT DO WE USE YOUR DATA FOR? </h4>
      <p>Some of the data is collected to ensure the proper functioning of the website. Other data can be used to analyze your user behavior.</p>

      <h4>WHAT RIGHTS DO YOU HAVE REGARDING YOUR DATA? </h4>
      <p>You have the right to receive free information about the origin, recipient, and purpose of your stored personal data at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to the processing of data, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.</p>

      <p>You can contact us at any time with regard to this and other questions about data protection.</p>

      <h2>2. HOSTING </h2>
      <p>We host the contents of our website with the following provider:</p>

      <h3>EXTERNAL HOSTING </h3>
      <p>This website is hosted externally. The personal data collected on this website is stored on the servers of the host or hosts. This may include IP addresses, contact requests, metadata, and communication data, contract data, contact details, names, website access, and other data generated by a website.</p>

      <p>External hosting is carried out for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 para. 1 lit. b DSGVO) and in the interest of a secure, fast, and efficient provision of our online offer by a professional provider (Art. 6 para. 1 lit. f DSGVO). If consent has been requested, processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information on the user’s terminal device (e.g. device fingerprinting) in accordance with the TTDSG. The consent can be revoked at any time.</p>

      <p>Our host or hosts will only process your data to the extent necessary to fulfill their contractual obligations and will follow our instructions regarding this data.</p>

      <p>We use the following host(s): easy.gr</p>

      <h4>ORDER PROCESSING</h4> 
      <p>We have concluded a contract for order processing (AVV) for the use of the above-mentioned service. This is a contract required by data protection law, which ensures that the personal data of our website visitors is processed only in accordance with our instructions and in compliance with the DSGVO.</p>

      <h2>3. GENERAL INFORMATION AND MANDATORY INFORMATION ON DATA PROTECTION </h2>
      <h3>DATA PROTECTION </h3>
      <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.</p>
      <p>When you use this website, various personal data is collected. Personal data is data with which you can be personally identified. This data protection declaration explains which data we collect and what we use it for. It also explains how and for what purpose this is done.</p>

      <p>We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible.</p>

      <h3>NOTE ON THE RESPONSIBLE PARTY</h3>
      <p>The responsible party for data processing on this website is:</p>

      <p>Georgios Mavraganis 
      Langhansstr. 7 
      13086 Berlin</p>

      <p>E-mail: provokatsia.project@gmail.com</p>

      <p>The responsible party is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (e.g. names, e-mail addresses, etc.).</p>

      <h3>Storage period </h3>
      <p>Unless a more specific storage period has been mentioned within this privacy policy, your personal data will remain with us until the purpose for data processing ceases to exist. If you make a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. tax or commercial retention periods); in the latter case, deletion will occur after these reasons cease to exist.</p>

      <h3>General information on the legal bases of data processing </h3>
      <p>If you have given your consent to data processing, we process your personal data on the basis of Art. 6 para. 1 lit. a DSGVO or Art. 9 para. 2 lit. a DSGVO if special categories of data are processed in accordance with Art. 9 para. 1 DSGVO. In the event of explicit consent to the transfer of personal data to third countries, data processing is also carried out on the basis of Art. 49 para. 1 lit. a DSGVO. If you have consented to the storage of cookies or access to information on your terminal device (e.g. via device fingerprinting), data processing is additionally carried out on the basis of § 25 para. 1 TTDSG. The consent can be revoked at any time. If your data is necessary for the fulfillment of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6 para. 1 lit. b DSGVO. Furthermore, we process your data if this is necessary to fulfill a legal obligation on the basis of Art. 6 para. 1 lit. c DSGVO. Data processing can also be carried out on the basis of our legitimate interest pursuant to Art. 6 para. 1 lit. f DSGVO. The relevant legal basis for data processing on this website will be explained in the following paragraphs of this privacy policy.</p>

      <h3>Recipients of personal data </h3>
      <p>As part of our business activities, we work with various external entities. In some cases, it is necessary to transmit personal data to these external entities. We only pass on personal data to external entities if this is necessary for the fulfillment of a contract, if we are legally obliged to do so (e.g. disclosure of data to tax authorities), if we have a legitimate interest pursuant to Art. 6 para. 1 lit. f DSGVO in the disclosure, or if another legal basis permits the data transfer. When using contract processors, we only pass on personal data of our customers on the basis of a valid contract for contract processing. In the case of joint processing, a contract for joint processing is concluded.</p>

      <h3>Revocation of your consent to data processing </h3>
      <p>Many data processing operations are only possible with your express consent. You can revoke your consent at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>

      <p>Right to object to data collection in special cases and to direct advertising (Art. 21 DSGVO) 
      If data processing is carried out on the basis of Art. 6 para. 1 lit. e or f DSGVO, you have the right to object to the processing of your personal data for reasons arising from your particular situation; this also applies to profiling based on these provisions. The respective legal basis on which processing is based can be found in this privacy policy. If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing that override your interests, rights, and freedoms or the processing serves to assert, exercise, or defend legal claims (objection pursuant to Art. 21 para. 1 DSGVO).</p>

      <p>If your personal data is processed for the purpose of direct advertising, you have the right to object at any time to the processing of your personal data for the purpose of such advertising; this also applies to profiling insofar as it is associated with such direct advertising. If you object, your personal data will subsequently no longer be used for the purpose of direct advertising (objection pursuant to Art. 21 para. 2 DSGVO).</p>

      <h3>Right to lodge a complaint with a supervisory authority </h3>
      <p>In the event of breaches of the DSGVO, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, place of work, or the place of the alleged breach. The right to lodge a complaint is without prejudice to any other administrative or judicial remedy.</p>

      <h3>Right to data portability </h3>
      <p>You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done to the extent that it is technically feasible.</p>

      <h3>Right to information, correction, and deletion </h3>
      <p>You have the right, within the framework of the applicable legal provisions, to obtain free information about your stored personal data, its origin and recipients, and the purpose of data processing, and, if applicable, a right to correct or delete this data. For this purpose, as well as for further questions regarding personal data, you can contact us at any time.</p>

      <h3>RIGHT TO RESTRICTION OF PROCESSING</h3>
      <p>You have the right to request the restriction of processing of your personal data. You can contact us at any time to exercise this right. The right to restrict processing exists in the following cases:</p>

      <ul>
      <li>If you dispute the accuracy of your personal data stored with us, we usually need time to verify this. For the duration of the verification, you have the right to request the restriction of processing of your personal data.</li>
      <li>If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of deletion.</li>
      <li>If we no longer need your personal data, but you need it for the exercise, defense, or assertion of legal claims, you have the right to request the restriction of processing of your personal data instead of deletion.</li>
      <li>If you have lodged an objection pursuant to Art. 21 para. 1 DSGVO, a balance must be struck between your interests and ours. As long as it is not yet clear whose interests prevail, you have the right to request the restriction of processing of your personal data.</li>
      </ul>

      <p>If you have restricted the processing of your personal data, these data - apart from their storage - may only be processed with your consent or for the assertion, exercise, or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or a Member State.</p>

      <h3>SSL or TLS encryption</h3>
      <p>This website uses SSL or TLS encryption for security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the site operator. You can recognize an encrypted connection by the fact that the address line of the browser changes from “http://” to “https://” and by the lock symbol in your browser line.</p>

      <p>When SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.</p>

      <h3>Opting Out of Promotional Emails</h3>
      <p>The use of contact data published within the framework of the imprint obligation for sending unsolicited advertising and information materials is hereby rejected. The operators of the pages expressly reserve the right to take legal action in the event of unsolicited sending of advertising information, such as spam emails.</p>

      <h2>4. Data collection on this website </h2>
      <h3>ENQUIRY BY E-MAIL, TELEPHONE OR FAX</h3>
      <p>If you contact us by e-mail, telephone or fax, your request, including all personal data (name, request) resulting from it, will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.</p>

      <p>The processing of this data is based on Art. 6 para. 1 lit. b DSGVO, insofar as your request is related to the fulfillment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6 para. 1 lit. f DSGVO) or on your consent (Art. 6 para. 1 lit. a DSGVO) if this has been requested; the consent can be revoked at any time.</p>

      <p>The data you send us via contact requests will remain with us until you request us to delete it, revoke your consent to storage, or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory legal provisions - in particular statutory retention periods - remain unaffected.</p>

      <h2 id="newsletter">5. NEWSLETTER </h2>
      <h3>NEWSLETTER DATA </h3>
      <p>If you would like to subscribe to the newsletter offered on the website, we require an e-mail address from you as well as information that allows us to verify that you are the owner of the specified e-mail address and that you agree to receive the newsletter. Further data is not collected or is only collected on a voluntary basis. We use this data exclusively for sending the requested information and do not pass it on to third parties.</p>

      <p>The processing of the data entered in the newsletter registration form is based exclusively on your consent (Art. 6 para. 1 lit. a GDPR). You can revoke your consent to the storage of data, e-mail address, and their use for sending the newsletter at any time, for example via the “Unsubscribe” link in the newsletter. The legality of the data processing operations already carried out remains unaffected by the revocation. </p>

      <p>The data you provide us for the purpose of subscribing to the newsletter will be stored by us or the newsletter service provider until you unsubscribe from the newsletter or the newsletter service provider and deleted from the newsletter distribution list after you unsubscribe from the newsletter or after the purpose has ceased to apply. We reserve the right to delete or block e-mail addresses from our newsletter distribution list at our own discretion within the scope of our legitimate interest pursuant to Art. 6 para. 1 lit. f GDPR.</p>

      <p>Data stored with us for other purposes remains unaffected by this.</p>

      <p>After you unsubscribe from the newsletter distribution list, your email address will be stored by us or the newsletter service provider in a blacklist, if this is necessary to prevent future mailings. The data from the blacklist is used only for this purpose and is not merged with other data. This serves both your interest and our interest in complying with the legal requirements when sending newsletters (legitimate interest within the meaning of Art. 6 para. 1 lit. f DSGVO). The storage in the blacklist is not limited in time. You can object to the storage if your interests outweigh our legitimate interest.</p>

      <h3>MAILCHIMP</h3>
      <p>
        We use Mailchimp as our marketing platform. By subscribing to our newsletter you acknowledge that your information will be
        transferred to Mailchimp for processing.{' '}
        <a
          href="https://mailchimp.com/legal/terms"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>{' '}
        about Mailchimp&apos;s privacy practices.
      </p>

      <h2>6. PLUGINS AND TOOLS </h2>
      <h3>GOOGLE MAPS </h3>
      <p>This site uses the Google Maps map service. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      In order to use the functions of Google Maps, it is necessary to store your IP address. This information is usually transferred to a Google server in the USA and stored there. The provider of this site has no influence on this data transmission. If Google Maps is activated, Google may use Google Fonts for the purpose of uniform display of fonts. When you call up Google Maps, your browser loads the required web fonts into your browser cache in order to display texts and fonts correctly.</p>

      <p>The use of Google Maps is in the interest of an appealing presentation of our online offers and an easy findability of the locations we have indicated on the website. This represents a legitimate interest within the meaning of Art. 6 para. 1 lit. f DSGVO. If the corresponding consent has been requested, processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information on the user’s terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time</p>

      <p>The data transfer to the USA is based on the standard contractual clauses of the European Commission. Details can be found here: https://privacy.google.com/businesses/gdprcontrollerterms/ and https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.</p>

      <p>For more information on how user data is handled, please refer to Google’s privacy policy: https://policies.google.com/privacy.</p>

      <p>The company is certified under the “EU-US Data Privacy Framework” (DPF). The DPF is an agreement between the European Union and the USA that is intended to ensure compliance with European data protection standards for data processing in the USA. Each company certified under the DPF undertakes to comply with these data protection standards. For more information, please contact the provider at the following link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active</p>

      <h3>GOOGLE RECAPTCHA </h3>
      <p>We use “Google reCAPTCHA” (hereinafter “reCAPTCHA”) on this website. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.</p>

      <p>reCAPTCHA is intended to check whether data entry on this website (e.g. in a contact form) is done by a human or by an automated program. To do this, reCAPTCHA analyzes the behavior of the website visitor based on various characteristics. This analysis starts automatically as soon as the website visitor enters the website. For the analysis, reCAPTCHA evaluates various information (e.g. IP address, length of stay of the website visitor on the website, or mouse movements made by the user). The data collected during the analysis is forwarded to Google.</p>

      <p>The reCAPTCHA analyses run completely in the background. Website visitors are not informed that an analysis is taking place.</p>

      <p>The storage and analysis of the data is based on Art. 6 para. 1 lit. f DSGVO. The website operator has a legitimate interest in protecting his web offers from abusive automated espionage and from SPAM. If the corresponding consent has been requested, processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information on the user’s terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. </p>

      <p>The consent can be revoked at any time. For more information on Google reCAPTCHA, please refer to the Google Privacy Policy and Google Terms of Service at the following links: https://policies.google.com/privacy and https://policies.google.com/terms</p>

      <p>The company is certified under the “EU-US Data Privacy Framework” (DPF). The DPF is an agreement between the European Union and the United States that aims to ensure compliance with European data protection standards for data processing in the United States. Every company certified under the DPF is required to comply with these data protection standards. For more information, please visit the provider’s website at the following link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active </p>

      <p>Source: https://www.e-recht24.de</p>

      <h2>7. Payment service provider</h2>
      <h3>PAYPAL</h3>
      <p>we are processing payments through the payment service provider PayPal (PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg). This corresponds to our legitimate interest in offering an efficient and secure payment method (Art. 6 para. 1 lit. f DSGVO). In this context, we provide the following data to PayPal to the extent necessary for the fulfillment of the contract (Art. 6 para. 1 lit. b. DSGVO):</p>

      <p>
        First name<br />
        Last name<br />
        Address<br />
        Email address<br />
        Phone number<br />
      </p>

      <p>The processing of the data provided under this section is neither legally nor contractually required. Without the transmission of your personal data, we cannot process a payment via PayPal. [You have the option to choose another payment method.]</p>

      <p>PayPal carries out a credit check for various services such as payment by direct debit to ensure your willingness and ability to pay. This corresponds to PayPal’s legitimate interest (pursuant to Art. 6 para. 1 lit. f DSGVO) and serves the performance of the contract (pursuant to Art. 6 para. 1 lit. b DSGVO). For this purpose, your data (name, address and date of birth, bank account details) will be passed on to credit agencies. We have no influence on this process and only receive the result of whether the payment has been made or rejected or whether a review is pending.</p>

      <p>For more information on how to object to or eliminate data processing by PayPal, please visit: https://www.paypal.com/de/webapps/mpp/ua/privacy-full. Your data will be stored until the payment process is completed.</p>

    </>
  );
};

export default TextEN;