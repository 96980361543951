import styled from 'styled-components'

export const StyledEventDate = styled.div`
  .event-date {
    border: 1px solid var(--secondary-color);
    min-width: 60px;
    margin-right: 20px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
  }

  .event-date.sm,
  .event-date.range {
    min-width: 55px;
    margin-right: 10px;
  }

  .event-date.sm .day,
  .event-date.range .day {
    padding: 1px 5px;
    font-size: 1.5rem;
  }

  .event-date.range .day {
    font-size: 0.75rem;
    padding: 9px 8px;
  }

  .month {
    background-color: var(--secondary-color);
    text-transform: uppercase;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 3px 0;
  }

  .day {
    color: #ccc;
    font-size: 1.75rem;
    font-weight: 600;
    padding: 3px 10px;
  }
`
