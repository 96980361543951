import { useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { AiOutlineAlert } from 'react-icons/ai'
import { Link, useParams } from 'react-router-dom'
import { FaYoutube, FaSpotify } from 'react-icons/fa'
import { BsTicketPerforated } from 'react-icons/bs'
import useLocalStorageState from 'use-local-storage-state'

import Image from '../image/Image'
import SocialShare from '../SocialShare'
import DetailedEvent from '../DetailedEvent'
import { eventsEN } from '../../lib/eventsEN'
import { eventsGR } from '../../lib/eventsGR'
import { eventsDE } from '../../lib/eventsDE'
import EventDate from './eventDate/EventDate'

import { StyledEvent } from './styledEvent'
import EventDetails from './eventDetails/EventDetails'
import ArtistDetails from './artistDetails/ArtistDetails'
import { createPortal } from 'react-dom'

export default function Event() {
  const [detailedDay, setDetailedDay] = useState(null)
  const [detailedArtist, setDetailedArtist] = useState(null)
  const [agreeOnTerms, setAgreeOnTerms] = useState(false);

  const [language, setLanguage] = useLocalStorageState('language')
  const { lang, url } = useParams()

  useEffect(() => {
    if (lang) {
      setLanguage(lang.toUpperCase())
    }
  }, [])

  function showDetailedArtist(artist) {
    if (artist.description) {
      setDetailedArtist(artist)
    }
  }

  function closeDetailedArtist() {
    setDetailedArtist(null)
  }

  const getEvent = (language) => {
    const events = { EN: eventsEN, GR: eventsGR, DE: eventsDE }[language]

    return events.find((event) => event.url === url)
  }

  const event = getEvent(language)

  const details = { EN: 'details', GR: 'πληροφοριες', DE: 'details' }[language]
  const moreInfo = { EN: 'more info', GR: 'λεπτομερειες', DE: 'mehr Infos' }[
    language
  ]

  const ticketsRef = useRef(null)
  useEffect(() => { 
    if ( window.location.href.includes('#tickets'))
      ticketsRef.current.scrollIntoView({ behavior: "smooth" });

    if ( window.location.href.includes('#workshop')) {
      const workshopDay = event.days.find(day => day.eventType.toLowerCase().includes('workshop'))
      showDay(workshopDay)
    }

    if ( window.location.href.includes('#day') ) {
      const day = window.location.href.split('#')[1].split('-')[1]
      showDay(event.days[day - 1])
    }

    if ( window.location.href.includes('#bio')) {
      const artists = !!event.days[0].artists.length
        ? event.days[0].artists
        : !!event.days[1].artists.length
          ? event.days[1].artists
          : !!event.days[2].artists.length
            ? event.days[2].artists
            : []

      const artistIDFromUrl = window.location.href.split('#')[1].split('-')[1]
      const artist = artists.find(artist => artist.image.includes(artistIDFromUrl) )
      setDetailedArtist(artist)
    }
    
  }, [language, event]);

  function showDay(day) {
    setDetailedDay(day)
  }

  function popup() {
    setDetailedDay(null)
  }

  const handlerAgreeTerms = () => setAgreeOnTerms(!agreeOnTerms)

  const headerTickets = {
    EN: 'Tickets',
    DE: 'Tickets',
    GR: 'Εισιτηρια'
  }[language]

  const subHeaderTickets = {
    EN: 'Get your  <b>Early bird</b> Tickets now!',
    DE: 'Sichern Sie sich jetzt Ihr <b>Early bird</b> Tickets!',
    GR: 'Εξασφάλισε τα <b>Early bird</b> εισιτήρια σου τώρα! <span>Πρίν πετάξουν!</span>'
  }[language]

  const textAddToCart = {
    EN: 'Go to cart',
    DE: 'Zum Warenkorb',
    GR: 'Στο καλάθι'
  }[language]

  const agreeOnTermsText = {
    EN: `I agree with the Privacy policy & Terms and conditions`,
    DE: 'Ich stimme den Datenschutzerklärung und Nutzungsbedingungen zu',
    GR: 'Συμφωνώ με τους όρους χρήσης'
  }[language]

  const termsLinkText = {
    EN: `Privacy policy & Terms and conditions`,
    DE: 'Datenschutzerklärung & Nutzungsbedingungen',
    GR: 'Όροι χρήσης'
  }[language]

  const moveToTicketsPageDisclaimer = {
    EN: `By clicking the button "${textAddToCart}", you will be redirected to our tickets page: event-tickets.provokatsia-project.com to order your tickets.`,
    DE: `Durch Klicken auf den Button "${textAddToCart}" werden Sie auf unsere Tickets-Seite weitergeleitet: event-tickets.provokatsia-project.com, um Deine Tickets zu bestellen.`,
    GR: `Κάνοντας κλικ στο κουμπί "${textAddToCart}", θα μεταφερθείς στην υπό σελίδα μας: event-tickets.provokatsia-project.com για την αγορά των εισιτηρίων σου.`
  }[language]

  const eventsWithTicket = []

  return (
    <StyledEvent>
      <div className="event wrapper">
        <div className="event-header">
          {event.days.length === 1 && (
            <EventDate startDate={event.days[0].startDate} />
          )}
          <h1 className="main-header">
            <span>
              Provokatsia project{' '}
              <span>{event.isSuggested ? 'suggests' : 'presents'}:</span>
            </span>{' '}
            {event.title}
          </h1>
          {eventsWithTicket.includes(event.url) && createPortal(
            <div className='go-to-tickets-container'>
              <div className='go-to-tickets'>
                <button onClick={() => { ticketsRef.current?.scrollIntoView({ behavior: 'smooth' }) }}>
                  <BsTicketPerforated />
                </button>
                <span>Tickets</span>
              </div>
            </div>,
            document.getElementById('root')
          )}
        </div>
        <section className="event-desc">
          <div className="event-desc__poster">
            <Image path={`posters/${event.poster}`} label="event poster" />
          </div>
          <div className="event-desc__text">
            {parse(event.description)}

            {(event.days.length === 1 || event.displayArtistList) && (
              <>
                {event.days.length === 1 && <EventDetails event={event.days[0]} />}
                {!!event.days[0].artists.length && (
                  <div className="event-desc__artists">
                    <h3>Artists</h3>
                    {event.days[0].artists.map((artist) => (
                      <p key={artist.name.replace(' ', '-')}>
                        {artist.description
                          ? <>
                            <a href="#" className="bio-link" onClick={event => {
                              event.preventDefault();
                              artist.description ? showDetailedArtist(artist) : (() => {})()
                            }}>
                              {artist.name}
                            </a> / {artist.speciality}{' '}
                          </>
                          : <>{artist.name} / {artist.speciality}{' '}</>
                        }
                        {artist.youtube && (
                          <>
                            {' / '}
                            <Link to={artist.youtube} target="_blank">
                              <FaYoutube /> Youtube
                            </Link>
                          </>
                        )}
                        {artist.spotify && (
                          <>
                            <br />
                            <Link to={artist.spotify} target="_blank">
                              <FaSpotify /> Spotify
                            </Link>
                          </>
                        )}
                      </p>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </section>
        {event.days.length > 1 && (
          <section>
            <h2>{details}</h2>
            {event.tip && (
              <div className="event-tip">
                <span>
                  <AiOutlineAlert />
                </span>
                <span>{event.tip}</span>
              </div>
            )}
            <div className="event-details">
              {event.days.map((day) => (
                <div key={day.id} className="event-day">
                  <EventDate startDate={day.startDate} type={'sm'} />

                  <div className="event-info">
                    <div className="event-info__type">{day.eventType}</div>
                    <h3>{day.title}</h3>
                    {day.subtitle && (
                      <p className="event-info__subtitle"><small>{parse(day.subtitle)}</small></p>
                    )}

                    <EventDetails event={day} />

                    {(!event.hideMoreDetails && !day.hideMoreDetails) && <button
                      className="event-info__button btn"
                      onClick={() => showDay(day)}
                    >
                      {moreInfo}
                    </button>}
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {eventsWithTicket.includes(event.url) && <section className='tickets'>
          <div className="wrapper" ref={ticketsRef}>
            <h2 className='text-align-center'>{headerTickets}</h2>
            <p className='text-align-center'>{parse(subHeaderTickets)}</p>
            <div className='tickets__infobox'>
              <div className='tickets__buy-wrapper'>
                <Link 
                  className={`btn btn-lg ${!agreeOnTerms && 'disabled'}`} 
                  style={{ pointerEvents: !agreeOnTerms ? 'none' : '' }}
                  to={`https://event-tickets.provokatsia-project.com/tc-events/${event.url}/`}>
                    {textAddToCart}
                </Link>
                <div className='tickets__terms'>
                  <input type="checkbox" id="agree" onChange={handlerAgreeTerms} />
                  <label htmlFor="agree">{agreeOnTermsText}</label>
                  <Link to={'/privacy-policy'}>{termsLinkText}</Link>
                </div>
              </div>
              <div className='tickets__disclaimer'>
                <p><span>*</span> {moveToTicketsPageDisclaimer}</p>
              </div>
            </div>
          </div>
        </section>}

      </div>

      {!!event.supplementary && (
        <section className="supplementary skew skew-left skew-top">
          <div className="wrapper">{parse(event.supplementary)}</div>
        </section>
      )}
      {/* <SocialShare
        shareUrl="http://provokatsia-project.com/events/3day-tribute-to-rempetiko"
        title="rempetika"
      /> */}
      
      {detailedDay && <DetailedEvent detailedDay={detailedDay} popup={popup} eventPath={event.url} onClickArtist={showDetailedArtist} />}
      {detailedArtist && (
        <ArtistDetails artist={detailedArtist} close={closeDetailedArtist} />
      )}
    </StyledEvent>
  )
}
