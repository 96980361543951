export const eventsEN = [
  {
    id: '112233',
    url: 'balkan-tanzfest',
    title: 'Balkan dance festival with the Anastasov Family and Ljuti Hora (Live-Music, Berlin)',
    description: `<p>Folks, dance enthusiasts, and music lovers,</p>
    <p>In almost 3 weeks, the Balkan Express will host the Anastasov family from Veles (North Macedonia). Friends of Macedonian and other Balkan music can dance and enjoy music.</p>

    <p>For years, the Balkan Express has organized a Balkan festival during the workshop weekend in spring. During this event, guests perform alongside Berlin musicians and this year, our guests from Veles will share the stage with the musicians from Ljuti Hora. There will be Macedonian music from various regions, Tsambuna sounds from the Greek islands, as well as Thracian and Epirotic melodies. Bulgarian songs will blend with Macedonian tunes, and who knows what other combinations will emerge. In any case, you can experience acoustic, groovy, and lively dance music. Drinks and snacks will be available, but no food. No separate registration is required.</p>
    `,
    poster: 'balkan-tanzfest.png',
    tip: '',
    types: ['suggested'],
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2024-04-27T19:30:00.349Z',
        endDate: '2024-04-28T03:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '20:30',
        doorsOpen: '19:30',
        entrance: '15/13 €',
        googleLocation:
          'https://www.google.com/maps/place/Saal+der+Kreuzkirche/@52.4820335,13.2888139,17z/data=!3m1!4b1!4m6!3m5!1s0x47a851c76b33c4c5:0x13b9cfdad56e81!8m2!3d52.4820303!4d13.2913942!16s%2Fg%2F11lcgpf3fd?entry=ttu',
        locationName: 'Saal der Kreuzkirche (next to Kreuzkirche)',
        locationAddress: 'Forckenbeckstraße 49, 14199 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '1111',
    url: 'darkwave-party',
    title: 'Darkwave - sheep n wolves - party',
    description: `<p>Provokatsia Project in a atmospheric electro dark wave mode!</p>
    <p>Get ready to step back in time with the electrifying beats of the '80s and '90s at Lauschangriff in Berlin! Provokatsia Project invites you to an unforgettable night where the past meets the present in a symphony of darkwave and atmospheric electro tunes. Join us for a party that promises to be more than just a throwback.</p>
    <p>DJs Pikoz Apikoz and Tsanakas will be at the helm, guiding you through a musical odyssey with their expertly curated sets. Whether you’re a die-hard fan of the era or just looking to experience something new, this is the place to be on March 16th. The beats will be deep, the melodies haunting, and the atmosphere absolutely electric.</p>
    <p>Don’t miss out on this journey through sound, mark your calendars and be part of the movement that keeps the spirit of darkwave sounds alive!</p>
    <p>Tip: Limited number of tickets, please come early to get a spot! ;)</p>
    `,
    poster: 'darkwave-party.jpg',
    tip: 'Tip: Limited number of tickets, please come early to get a spot! ;)',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-03-16T21:00:00.349Z',
        endDate: '2024-03-17T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Dj Pikoz Apikoz',
            speciality: '',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Dj Kostas',
            speciality: '',
            image: '',
            youtube: '',
            spotify: '',
          }
        ],
        description: '',
        eventType: 'Party',
        video: '',
        time: '21:00',
        doorsOpen: '21:00',
        entrance: 5,
        googleLocation:
          'https://www.google.com/maps/place/Lauschangriff/@52.5182464,13.4553066,15z/data=!4m6!3m5!1s0x47a84e6653637a9d:0xd32edb6abbf17c08!8m2!3d52.5182464!4d13.4553066!16s%2Fg%2F11bxh9tjcp?entry=ttu',
        locationName: 'Lauschangriff',
        locationAddress: 'Rigaer Str. 103, 10247 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '223',
    url: 'rebetiko-duets',
    title: 'Rebetiko duets! Hiotis-Chaskil & Tsitsanis-Ninou',
    description: `<p>Dear friends, the Provokatsia Project team continues its journey with another educational and musical three-day event, along with the Greek Music Workshop and SKB (TU Berlin). We are delighted to welcome three talented musicians visiting us from Greece, who will transport us through time, in a tribute to iconic duets from the rebetiko music tradition.</p>

    <p>
      Specifically, on Friday, February 23, at cafe TELquel (TU Berlin), along with the screenwriter Anestis Barbatsis, we kick it off with a documentary screening “Broken Sound” (Σπασμένος Ήχος). This captivating film explores the traditional instrument Bouzouki and its historical evolution. Following the screening, a teaser concert of rebetiko music will take place and later in the evening, a DJ set journey exploring sounds from around the world.
      
    </p>
    <p>The musical journey continues on Saturday, February 24, at Bavul. Our guest musicians—Anestis Barbatsis (vocals, bouzouki), Lamprini Astrid Yioti Andersson (vocals), and Iason Kalogiros (vocals, bouzouki)—will join forces with Berlin-based musicians Dinos Bouzanis (guitar), Sanne Möricke (accordion), and Thodoris (double bass) and together, they will perform live rebetiko songs from the renowned duets of Hiotis-Chaskil and Tsitsanis-Ninou, as well as other beloved rebetiko songs.</p> 
    <p>And finally, on Sunday, February 25, at cafe Karanfil, a 4-hour experiential workshop awaits. Participants will explore the singing styles of Marika Ninou and Stella Haskil, guided by the esteemed ethnomusicologist with expertise in traditional soundscapes, Lamprini Astrid Yioti Andersson.</p>
    
    <p>This workshop is open to anyone interested in rebetiko music, regardless of prior singing experience. We look forward to seeing you there!</p>
    <h3>Anestis Barbatsis</h3>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/ZPQCqJP3m4w?si=pCAQrJERUlNDHKQl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <h3>Labrini Astrid Gioti Andersson</h3>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=gCnKlfksYzHSPsp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <h3>Documentary trailer</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/4Xf8JWi2jFg?si=qSCdYlyYiFn6eN0a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    poster: 'rebetiko-duets.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-02-23T19:00:00.349Z',
        endDate: '2024-02-25T02:00:00.349Z',
        title: 'Documentary Screening + Teaser Concert + Dj set',
        subtitle:
          `Idea-Written: Anestis Barbatsis<br/>
          Directed: Foivos Kontogiannis<br/>
          <i>In Greek with english subtitles</i>`,
        poster: '',
        artists: [],
        description: `
          <p>The music documentary "Broken Sound", a co-production of Anestis Barbatsis and Phoebus Kontogiannis, produced by Foss Productions, which was co-produced with the Hellenic Film Centre</p>
          <p>Phoebus Kontogiannis, responsible for the direction and Anestis Barbatsis, responsible for the idea, research, editing, music editing, as well as the selection and interviews of the protagonists and other speakers, lead us on a journey of memory to the sound of the country's most beloved folk instrument told through the eyes of great personalities of the modern history of bouzouki</p>
          <p>From the dark tequettes in the years of illegality, to the great salons of the Greek pentagram, the bouzouki unfolds its soul in the hands of the musicians who loved and promoted it. Part of this visualized narrative and present in this demanding work are great musicians such as Dimitris Vyzas, Giorgos Dramalis, Manolis Karantinis, Vangelis Liolios, Giannis Moraitis, Christos Nikolopoulos, Giannis Papavasiliou, Kostas Papadopoulos, Manolis Pappos, Thanasis Polykandriotis, Panagiotis Stergios, Thymios Sturaitis, Nikos Tatasopoulos, Vangelis Trigas and Diamantis Chiotis, who with their taqasimos and fascinating narratives take us on a melodic journey to the past, present and future of the bouzouki.</p>
          <p>Following the musical evolution of the bouzouki, "Broken Sound" attempts a historical, musical overview, as well as a cultural and social survey of the evolution of Greek society, through interviews with great personalities of the modern history of the instrument, combined with rare archival material.</p>
        `,
        supplementary: `
          <h2>FILM CREDITS</h2>
          <p>Idea-Research: Anestis Barbatsis
            Script: Anestis Barbatsis, Foivos Kontogiannis
          </p>
          <p>Directed: Foivos Kontogiannis </p>
          <p>Editing-Music Supervision: Anestis Barbatsis</p>
          <p>
            Production: Foss Productions<br />
            Co-Production: Hellenic Film Centre, Faliro House, EKOME<br />
            Producer: Stelios Kotionis<br />
            Executive Producer: Christos B. Konstantakopoulos<br />
            Director of Photography: Manu Tilnsky<br />
            Editing: George Georgopoulos<br />
            Sound: Stavros Avramidis, Leandros Ntounis<br />
          </p>
        `,
        eventType: 'Screening & Concert',
        videoId: '4Xf8JWi2jFg',
        time: '19:00 screening | 22:00 dj set',
        doorsOpen: '18:30',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Department+of+Media+Science+Institute+of+Language+and+Communication,+Ernst-Reuter-Platz+7,+10587+Berlin/@52.5130599,13.3202022,20z/data=!4m6!3m5!1s0x47a8511c46ef3243:0x12b970f58d57050c!8m2!3d52.5130317!4d13.3200439!16s%2Fg%2F12hk180jv?entry=ttu',
        locationName: 'Cafe TELquel (TU Berlin)',
        locationAddress: 'Ernst-Reuter-Platz 7, 10587 Berlin',
      },
      {
        id: '2',
        startDate: '2024-02-24T19:00:00.349Z',
        endDate: '2024-02-24T02:00:00.349Z',
        title: 'Rebetiko concert',
        poster: '',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Guitar',
            image: 'ntinos.jpg',
            youtube: 'https://www.youtube.com/@unterrichtgitarrerebetikou1659',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Accordeon',
            image: 'sanne.jpg',
            youtube: 'https://www.youtube.com/watch?v=qvfUrYWJ7Mo',
            spotify: '',
          },
          {
            name: 'Labrini Astrid Gioti Andersson',
            speciality: 'Vocals',
            image: 'labrini.jpg',
            youtube: 'https://www.youtube.com/@LabriGiottoofficial',
            spotify: '',
            description: `<h2>Labrini Astrid Gioti Andersson </h2>
            <p>Labrini Astrid Gioti Andersson is a Greek performer, teacher,radio producer and ethnomusicologist.</p>
              <p>She has graduated from Palini Musical Highschool, holds a Diploma in Classical piano from the National Conservatoire of Athens , a BA in Music Studies and an MA in Ethnomusicology from the Irish World Academy of Music & Dance (University of Limerick) with the aid of a scholarship from the Attica Tradition Foundation.</p>
              <p>She began her involvement with Celtic music when she was 13 years old and in 2006 she completed her Master thesis on : “Sean-Nós singing style from the West Cork Gaeltacht area”, where she concentrated on the singing style of Eilís Ní Shúilleabháin, Iarla Ó Lionáird & Elisabeth Cronin.</p>
             <p>During 2007&2008, she conducted workshops with byzantine, epirotic and rebetika music at the Irish World Academy of Music and Dance, University of Limerick.</p>
              <p>She has been taught traditional music & different styles by Elaine Cormican, Eilís Ní Shúilleabháin, Deirdre Scanlon, Mícheál Ó Súilleabháin ,Muireann Nic Amhlaoibh, Bobby Mc Ferrin, Mamak Khadem,Haris Lambrakis,Nikos Mamagkakis,Spyros Goumas,Ross Daly, Åsa & Esbjörn Hogmark, Nicklas Roswall , Ditte Andersson ,Ross Daly, Kelly Thoma, Martha Mavroidi,Maria Koti, Mamak Khadem, Veronica Doubleday, John Bailey, Johanna Bölja, Vicki Swan.</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=al0fTcJR0GPX0AdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <p>She has been a professional musician since 2000 performing in music stages, pubs & concerts all over Greece, as well as teaching piano,rebetika, traditional Greek and North European music, singing style and theory in Ireland, Scotland, Sweden, Norway,Germany and Greece.</p>
              <p>She has performed at the Acropolis Museum(projects with the Irish Embassy), Cycladic Museum(Irish Embassy), ERT,Athens Celtic Music Festival, Festival,Athens Irish Festival, Irish Wings and Paxos Music Festival,Medieval Rhodes Festival, as well as in several music stages in Greece, Ireland(IWAMD, Bewley’s Cafe Theater), Sweden (Esi Tobo) and Scotland(Cowdray Hall, Blue Lamp) .</p>
              <p>She is a pioneer in promoting Irish,Scottish and Nordic traditional singing in Greece with seminars since 2010, founding and conducting Curfa Gaelic Nordic Choir with students from the seminars since 2017.</p>
              <p>Ms Gioti Andersson promotes the Irish and Celtic culture since 2006 with music projects in collaboration with the Irish Embassy, Culture Ireland and the Greek Irish Society, being an old member of the Greek Irish Society since 1996 and having served in the committee as well.</p>
              <p>She is one of the founders of the Athens Celtic Music Festival (2014-2019 ).</p>
              <p>The ITMA (Irish Traditional Music Archive) cataloged her in their archives as the first Greek who sang in the Irish (Gaelic) language, Sean-nós .</p>
              <p>She has sung Sean-nós in front of the Irish President , Michael D. Higgins, in Athens.</p>
              <p>In 2022, she performed Greek music for the awarded documentary movie “An Buachaill Gealghaireach” , the Laughing Boy with two famous Irish traditional musicians, Liam O’ Maonlai and Dave Power .</p>
              <p>She is the one of the founding members of the band Sòlastas ( Athens Celtic Music Festival, Gagarin, Trianon, Hellenic American Union, Athens Irish Festival, Ianos, Goethe Institut, Rhodes Medieval Festival and several projects with the Irish Embassy and British Embassy ).</p>
              <p>Since 2017-2021, she started the Nyckelharpa Greece project , having brought the first nyckelharpa from Sweden promoting Nordic music in Greece with her band Sòlastas . </p>
              <p>In October 2019, she was accepted at the Intercultural Orchestra of the Greek National Opera alternative stage ,as a nyckelharpa player.</p>
              <p>For a detailed bio on Nyckelharpa and Nordic music, go to the page Nyckelharpa Greece and Nordic Music in Greece.</p>`,
          },
          {
            name: 'Anestis Barbatsis',
            speciality: 'Bouzouki/Vocals',
            image: 'anestis.png',
            youtube: 'https://www.youtube.com/@anestisbarbatsis8988',
            spotify: '',
          },
          {
            name: 'Iason Kalogiros',
            speciality: 'Bouzouki/Vocals',
            image: 'iason.jpeg',
            youtube: 'https://www.youtube.com/watch?v=nRoZk_nlmH4',
            spotify: '',
          },
        ],
        description:
          '<p>The musical journey continues on Saturday, February 24, at Bavul. Our guest musicians—Anestis Barbatsis (vocals, bouzouki), Lamprini Astrid Yioti Andersson (vocals), and Iason Kalogiros (vocals, bouzouki)—will join forces with Berlin-based musicians Dinos Bouzanis (guitar), Sanne Möricke (accordion), and Thodoris (double bass) and together, they will perform live rebetiko songs from the renowned duets of Hiotis-Chaskil and Tsitsanis-Ninou, as well as other beloved rebetiko songs.</p>',
        eventType: 'Concert',
        video: '',
        time: '19:00',
        doorsOpen: '18:30',
        entrance: 12,
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'BAVUL - Kunst & Kultur Café',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '3',
        startDate: '2024-02-25T12:00:00.349Z',
        endDate: '2024-02-25T16:00:00.349Z',
        title: 'Rebetiko vocals / singing workshop',
        poster: '',
        artists: [
          {
            name: 'Labrini Astrid Gioti Andersson',
            speciality: 'Vocals',
            image: 'labrini.jpg',
            youtube: 'https://www.youtube.com/@LabriGiottoofficial',
            spotify: '',
            description: `<h2>Labrini Astrid Gioti Andersson </h2>
            <p>Labrini Astrid Gioti Andersson is a Greek performer, teacher,radio producer and ethnomusicologist.</p>
              <p>She has graduated from Palini Musical Highschool, holds a Diploma in Classical piano from the National Conservatoire of Athens , a BA in Music Studies and an MA in Ethnomusicology from the Irish World Academy of Music & Dance (University of Limerick) with the aid of a scholarship from the Attica Tradition Foundation.</p>
              <p>She began her involvement with Celtic music when she was 13 years old and in 2006 she completed her Master thesis on : “Sean-Nós singing style from the West Cork Gaeltacht area”, where she concentrated on the singing style of Eilís Ní Shúilleabháin, Iarla Ó Lionáird & Elisabeth Cronin.</p>
             <p>During 2007&2008, she conducted workshops with byzantine, epirotic and rebetika music at the Irish World Academy of Music and Dance, University of Limerick.</p>
              <p>She has been taught traditional music & different styles by Elaine Cormican, Eilís Ní Shúilleabháin, Deirdre Scanlon, Mícheál Ó Súilleabháin ,Muireann Nic Amhlaoibh, Bobby Mc Ferrin, Mamak Khadem,Haris Lambrakis,Nikos Mamagkakis,Spyros Goumas,Ross Daly, Åsa & Esbjörn Hogmark, Nicklas Roswall , Ditte Andersson ,Ross Daly, Kelly Thoma, Martha Mavroidi,Maria Koti, Mamak Khadem, Veronica Doubleday, John Bailey, Johanna Bölja, Vicki Swan.</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=al0fTcJR0GPX0AdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <p>She has been a professional musician since 2000 performing in music stages, pubs & concerts all over Greece, as well as teaching piano,rebetika, traditional Greek and North European music, singing style and theory in Ireland, Scotland, Sweden, Norway,Germany and Greece.</p>
              <p>She has performed at the Acropolis Museum(projects with the Irish Embassy), Cycladic Museum(Irish Embassy), ERT,Athens Celtic Music Festival, Festival,Athens Irish Festival, Irish Wings and Paxos Music Festival,Medieval Rhodes Festival, as well as in several music stages in Greece, Ireland(IWAMD, Bewley’s Cafe Theater), Sweden (Esi Tobo) and Scotland(Cowdray Hall, Blue Lamp) .</p>
              <p>She is a pioneer in promoting Irish,Scottish and Nordic traditional singing in Greece with seminars since 2010, founding and conducting Curfa Gaelic Nordic Choir with students from the seminars since 2017.</p>
              <p>Ms Gioti Andersson promotes the Irish and Celtic culture since 2006 with music projects in collaboration with the Irish Embassy, Culture Ireland and the Greek Irish Society, being an old member of the Greek Irish Society since 1996 and having served in the committee as well.</p>
              <p>She is one of the founders of the Athens Celtic Music Festival (2014-2019 ).</p>
              <p>The ITMA (Irish Traditional Music Archive) cataloged her in their archives as the first Greek who sang in the Irish (Gaelic) language, Sean-nós .</p>
              <p>She has sung Sean-nós in front of the Irish President , Michael D. Higgins, in Athens.</p>
              <p>In 2022, she performed Greek music for the awarded documentary movie “An Buachaill Gealghaireach” , the Laughing Boy with two famous Irish traditional musicians, Liam O’ Maonlai and Dave Power .</p>
              <p>She is the one of the founding members of the band Sòlastas ( Athens Celtic Music Festival, Gagarin, Trianon, Hellenic American Union, Athens Irish Festival, Ianos, Goethe Institut, Rhodes Medieval Festival and several projects with the Irish Embassy and British Embassy ).</p>
              <p>Since 2017-2021, she started the Nyckelharpa Greece project , having brought the first nyckelharpa from Sweden promoting Nordic music in Greece with her band Sòlastas . </p>
              <p>In October 2019, she was accepted at the Intercultural Orchestra of the Greek National Opera alternative stage ,as a nyckelharpa player.</p>
              <p>For a detailed bio on Nyckelharpa and Nordic music, go to the page Nyckelharpa Greece and Nordic Music in Greece.</p>`,
          },
        ],
        description: `    
          <h3>INTRODUCTION TO THE SINGING STYLES OF MARIKA NINOU AND STELA HASKIL</h3>
          <p>With the ethnomusicologist Labrini Astrid Gioti Andersson</p>
         
          <p>A 4-hour workshop for anyone interested in an introduction to the rebetika songs and with the focus to the voices of Marika Ninou and Stella Haskil. 
          We are about to explore the singing style (technique, phrasing etc. ) through some of  the songs from their vast repertoire.</p>

          <p>You don't need to have any  previous experience in singing or music knowledge. The language of the tuition is Εnglish ,but the songs are in Greek (Translation  with detailed pronunciation  is included). Furthermore, a certificate of attendance will be provided.</p>
          <p><b>⚠️ Important information ⚠️</b><br>
          If you’re interested in attending the workshop, please secure your spot by sending an email to <b>provokatsia.project@gmail.com</b> no later than February 23rd.
          </p>
          <p>The workshop in details:</p>
          <ul>
            <li>Vocal Warm up 20 minutes</li>
            
            <li>Short lecture 20 minutes </li>
            
            <li>10 minutes break </li>
            
            <li>Repertoire 60 minutes </li>
            
            <li>Break 10 mins</li>
            
            <li>Repertoire 60mins </li>
            
            <li>Break 10mins</li>
            
            <li>Repertoire 50mins</li>
        </ul>
        <h3>Labrini Astrid Gioti Andersson</h3>
        <p><iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=gCnKlfksYzHSPsp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>`,
        eventType: 'Workshop',
        video: '',
        time: '12:00',
        doorsOpen: '11:30',
        entrance: 50,
        googleLocation:
          'https://www.google.com/maps/place/Cafe+Karanfil/@52.4799811,13.4228092,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84fbee9fa6ebf:0xb129429743ec5b46!8m2!3d52.4799811!4d13.4228092!16s%2Fg%2F11gff6v8_m?entry=ttu',
        locationName: 'Cafe Karanfil',
        locationAddress: 'Weisestraße 3, 12049 Berlin',
      },
    ],
    supplementary: `<h2>Rempetika - The Greek Blues</h2>
      <p><i>"Similar creations to those of rempetiko can be observed in America with the Blues, in Brazil with Samba and in Jamaica with Reggae."</i></p>
      <p><i>"Rempetiko is a popular urban song with roots in Byzantine music and Greek folk music."</i></p>
      <p><i>"Rebette: Unruly, rebellious, independent."</i></p>
      <p><i>"Rempetika are songs of the heart and only those who approach them with pure feeling feel them and enjoy them."</i></p>
      <p><i>"Rempetiko manages to combine speech, music and movement in a wondrous unity."</i></p>
      <p><i>"From composition to performance, the conditions for this triple coexistence are instinctively created, which sometimes, as if reaching perfection, reminds one of ancient tragedy."</i></p>
      <p>Excerpts from the book by Elias Petropoulos</p>
      <p><i>"The rempetiko song is genuinely Greek, uniquely Greek."</i></p>
      <p>Manos Hadjidakis</p>
    `,
  },
  {
    id: '222',
    url: 'fikir-amlak-4dub-vibes-soundsystem',
    title: 'Fikir Amlak & 4 dub vibes soundsystem',
    description: `<p>We are proud and humble to support <a href="https://www.facebook.com/profile.php?id=100060822286025" target="_blank">4dub Vibes Soundsystem</a> to their massive event, inviting Fikir Amlak from Los Angeles, CA for the very first time in Athens Greece in a heavy bass session, delivered by their handcrafted soundsystem!</p>
    <p>Fikir Amlak has been professionally involved in the music industry for 20 years as a singer/songwriter, multi-instrumentalist and producer. In the past 8 years, Fikir Amlak has seen his music released by various international labels in the form of 56 vinyl records, 21 CD albums and 58 digital singles. Since 2014, he has become a regular on the European ‘sound system’ tour circuit, frequently performing as a singer alongside King Alpha Sound System from the UK. Fikir Amlak’s most popular album releases to date have been in collaboration with King Alpha and the American music label, Akashic Records Reggae, although his projects with San Diego based producer Brizion have also received popular support. Along with several vinyl records, albums and digital singles slated for release in the coming years, Fikir Amlak recently launched his own record label, Tikur Anbessa Records operating out Los Angeles, CA with an emphasis on limited, polyvinyl releases. </p>
    <p>All the dub lovers roll out for this one and let Athens city burn with some serious power vibes from Fikir Amlak in a highest quality sound of the 4dub Vibes Soundsystem! That will be certainly a night to remember!</p>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/E6LpIFf6TaY?si=NLbMKgCXW29fOL9p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>`,
    poster: 'fikir-amlak-4dub-vibes-soundsystem.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2024-02-17T21:00:00.349Z',
        endDate: '2024-02-18T04:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '23:00 GR time',
        doorsOpen: '21:00',
        entrance: 10,
        googleLocation:
          'https://www.google.com/maps/place/Red+Sea+Reggae+House/@37.9777233,23.7564017,15z/data=!4m6!3m5!1s0x14a1bd50a7a390f7:0x312db5e7485e9e9e!8m2!3d37.9777233!4d23.7564017!16s%2Fg%2F1tht93zf?entry=ttu',
        locationName: 'Red Sea Reggae House | Athens',
        locationAddress: 'Ilia Potamianou 23, Athina 115 28, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '0',
    url: 'provokatsia-on-the-road',
    title: 'Provokatsia on the road! Mini tour in Greece',
    description: `<h3>Provokatsia on the road! </h3>
    <p>Mr. Flavor Mav, & Mr. Richter Scale Madness (aka Kleissonic) have packed their vibes and they hit the road for a mini tour in Greece. Three days – three towns Agrinio | Livadia | Larissa, showcasing dj series as well as guitar solo performances. Richter Scale Madness will be performing solo with guitars playing psych heavy tunes and Flavor Mav the insane space rocker from Berlin, will be selecting tunes on those dj series.</p>
    <p>We highly recommend to not miss that one! Come along!</p>`,
    poster: 'provokatsia-on-the-road.jpg',
    tip: '',
    isSuggested: false,
    displayArtistList: true,
    hideMoreDetails: true,
    days: [
      {
        id: '1',
        startDate: '2024-01-05T19:00:00.349Z',
        endDate: '2024-01-13T04:00:00.349Z',
        title: '1st stop @ Akrovatis | Agrinio',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Karaiskaki+16,+Agrinio+301+31,+Greece/@38.6261395,21.4034472,17z/data=!3m1!4b1!4m10!1m2!2m1!1s16+Karaiskaki+Georgiou+Street,+30131+Agrinio!3m6!1s0x135e8a7438436d09:0x85d948583c0a19ed!8m2!3d38.6261396!4d21.4083235!15sCiwxNiBLYXJhaXNrYWtpIEdlb3JnaW91IFN0cmVldCwgMzAxMzEgQWdyaW5pb5IBEGdlb2NvZGVkX2FkZHJlc3PgAQA!16s%2Fg%2F11rpth7973?entry=ttu',
        locationName: 'Akrovatis',
        locationAddress: 'Karaiskaki 16, Agrinio 301 31, Greece',
      },
      {
        id: '2',
        startDate: '2024-01-12T19:00:00.349Z',
        endDate: '2024-01-13T04:00:00.349Z',
        title: '2nd stop @ TAF | Livadia',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/TAF/@38.4361695,22.8749872,15z/data=!4m6!3m5!1s0x14a08fae761d808f:0x703050862564528c!8m2!3d38.4361695!4d22.8749872!16s%2Fg%2F11nn45r4sj?entry=ttu',
        locationName: 'TAF',
        locationAddress: 'I. Lappa 9, Livadia 321 00, Greece',
      },
      {
        id: '3',
        startDate: '2024-01-13T19:00:00.349Z',
        endDate: '2024-01-14T04:00:00.349Z',
        title: '3nd stop @ Wise Monkey | Larissa',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Wise+Monkey+-+Urban+Vibes/@39.6350767,22.4156713,17z/data=!3m1!4b1!4m6!3m5!1s0x135889ad4c78baad:0x1630f15d80848839!8m2!3d39.6350767!4d22.4182516!16s%2Fg%2F11jgsxpk47?entry=ttu',
        locationName: 'Wise Monkey - Urban Vibes',
        locationAddress: 'Asklipiou 38, Larisa 412 22, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '22',
    url: 'laiko-rempetiko-glenti',
    title: 'Laiko Rempetiko Glenti',
    description: `<p>The Provokatsia Project invites you to a Laiko (folk) and rembetiko glenti (celebration) at the New York Cafe-Restaurant in Agrinio, where the talented musicians Dinos Mpouzanis on guitar, Stratos Souvatrzis and Maria Vakali on vocals, Orfeas Tsaktsiras on mpouzouki, and Andreas Kotsarinis on accordion, will take us back in time with known songs of the Laiko and rembetiko music! Don’t miss a unique evening of music and culture!</p>
    <br />
    <b>Stratos Souvatrzis vocals, Dinos Bouzanis guitar, Zigouala</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/Np4XpZYPygs?si=__RLlqbMHwS0ex5Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <br />
    <b>Maria Vakali performing on TV show "Oti agapo"</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/t_TywytZvdw?si=JHGk-IfjaXOV7wSO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <br />
    <b>Tha kano ntou vre poniri - Orfeas Tsaktsiras</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/4fUozYahKcQ?si=jCCpCLZczPBtX0ym" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    `,
    poster: 'laiko-rempetiko-glenti.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-01-07T13:00:00.349Z',
        endDate: '2024-01-07T22:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Guitar',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Stratos Souvatrzis',
            speciality: 'Vocals',
            image: 'stratos.jpg',
            youtube:
              'https://www.youtube.com/playlist?list=PLBcA1hS7Mwmd0Ct_CygblH7H7JFGy11zv',
            spotify: '',
          },

          {
            name: 'Maria Vakali',
            speciality: 'Vocals',
            image: 'maria.jpg',
            youtube: 'https://www.youtube.com/watch?v=t_TywytZvdw',
            spotify: '',
          },
          {
            name: 'Orfeas Tsaktsiras',
            speciality: 'Bouzouki, Vocals',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
          {
            name: 'Andreas Kotsarinis',
            speciality: 'Accordeon',
            image: 'andreas.jpg',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '15:00',
        doorsOpen: '14:00',
        entrance: 10,
        googleLocation:
          'https://www.google.com/maps/place/New+York+Cafe-Restaurant/@38.6722153,21.3571619,17z/data=!3m1!4b1!4m6!3m5!1s0x135e8998997bf2bd:0x754675674d893576!8m2!3d38.6722111!4d21.3597422!16s%2Fg%2F11j8x247hz?entry=ttu',
        locationName: 'New York Pallace',
        locationAddress: 'EO Agriniou Artas, Agrinio 301 31, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '0',
    url: 'epirus-unter-den-platanen',
    title: 'Three-day festival on Epirus Music',
    description: `<p>Experience the rich and vibrant sounds of Epirus music at our three-day event in Berlin</p>
      
      <p>Join us for a 6-hour workshop led by masters of their craft who will take you on a journey 
      through the history and culture of Epirus music. Immerse yourself over the following two days 
      in a traditional glenti (feast) featuring live performances by five pioneering musicians visiting from 
      Greece to lead the three-day unique celebrations.</p>
      
      <p>The festival will include Christos Tzitzimikas and his unique and soulful voice, who released his 2nd album “Patimata” featuring the legendary clarinetist Petroloukas Chalkias and performed with other legends like Christos Zotos, Antonis Kaliouris on clarinet, Giannis Poulios on violin, Panagiotis Tsakos on guitar and lute and Petros Papageorgiou on percussion. Christos Tzitzimikas and his company, will create a magical and unforgettable experience, transporting you to Epirus with their soulful and captivating performances.</p>
      
      <p>Don’t miss this unique opportunity to discover the magic of Epirus music. Book your tickets now and get ready 
      for an unforgettable experience!</p>
      <br>
      <b>Christos Tzitzimikas performing with Petroloukas Chalkias</b>
      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/X9OGj5X376U?si=C3LP0yjh8rWw_EIX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
      
      <b>Petros Papageorgiou performing with Petroloukas Chalkias</b>
      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/G-h2fOKdyLE?si=NvanRN3ZkDTq9Tld" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p><br>`,
    poster: 'epirus-unter-den-platanen.jpg',
    tip: '',
    isSuggested: false,
    displayArtistList: true,
    days: [
      {
        id: '1',
        startDate: '2024-01-26T14:00:00.349Z',
        endDate: '2024-01-28T22:59:00.349Z',
        title: 'A 6-hour workshop + 45-minute concert',
        poster: '',
        artists: [
          {
            name: 'Christos Tzitzimikas',
            speciality: 'Vocals',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Christos Tzitzimikas</h2>
            <p>Coming from a family of folk singers, Christos experienced folk music from an early age. Since 1983
            he has been engaged in the research and performance of Epirus, Macedonian, Thessalian and Central
            Greek folk songs. </p>
            
            <p>He studies Byzantine music at the Skalkota Conservatory with Lykourgos
            Angelopoulos, vocals with Lucia Fracea and is completing his studies at T.E.F.A.A. Athens. </p>
            
            <p>His first appearances were with the music group "TERIREM". Since then, he has collaborated with many old
            and new notable musicians, playing many concerts and theatrical performances all over Greece and
            abroad. </p>
            
            <p>In 1993, his first album titled "Zagorisia and Gianniotika" was released by Iambos
            publications. With the participation of Grigoris Kapsalis on the clarinet, ten notable musicians and
            "TERIREM". It was recorded by Giorgos Sygletos and the orchestra was conducted by Giorgos
            Papadakis. The release was accompanied by an insert (in Greek and English) with lots of information
            and photos. </p>
            
            <p>A song from the album “Zagorisia and Gianniotika” was used in the film "Athens --
            Constantinople" by Nikos Panagiotopoulos. </p>
            
            <p>"Patima" is the title of the second album released by
            Iambos in 2001. It includes 16 songs with reference point the Peacock of Epirus. Petroloukas Halkias
            (clarinet), Christodoulos Zoumbas (lute, music editor) and four other notable musicians are
            participating. The release is in the form of a book with extensive historical and musicological
            comments (Greek and English) as well as photographic material. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>The two records received great reviews from the press and are presented live in cities both in Greece and abroad.</p> 
            
            <p>In 2001 he participated in a release titled "Thessalia", in 2015 in the Stamatis Lallas CD "From the heart of
            Agiathymia", and in 2019 the Vassilis Triantis CD of "Portolanos". He taught traditional singing at
            the Department of Greek Traditional Music of TEI Arta, at the Department of Music Science and Art
            of the University of Macedonia, at the Music Village, Agios Lavrentios, as well as seminars and
            private lessons.</p>`,
          },
          {
            name: 'Antonis Kaliouris',
            speciality: 'Clarinet',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Antonis Kaliouris</h2>
            <p>Antonis was born in Athens, where he grew up, but he is originally from Rethymno, Crete. He is a
            graduate from the Department of Biotechnology of the Agricultural University of Athens. </p>
            
            <p>His involvement in music began at the early age of nine, learning the lute. At the age of twelve he started
            studying traditional clarinet, with his teacher V. Papanastasiou. Later, for three years, he studied
            classical clarinet at the Attica Conservatory, with professor Manousos Ploumidis. In 2011, for four
            years, he attended traditional clarinet lessons with master Alexandros Arkadopoulos. At the same time
            he took flute lessons at the National Conservatory, with teacher Danae Kiupouroglou. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>From an early age he participated in cultural events as well as festivals, weddings and dance events throughout
            Greece. </p>
            
            <p>He has participated as a musician in concerts, shows, record labels as well as radio and
            television programs- with various artists of the folk and traditional scene, such as: G. Dalaras,
            Glykeria, K. Makedonas, G. Kotsiras, G. Andreatos, S. Papazoglou, B. Tsertos, L. Mahairitsas, G.
            Lebesis, G. Nega, M. Paschalidis, N. Karagianni, K. Vetta, C. Dantes, G. Merantzas, Erofili, P.
            Lalezas, M. Koutsaggelidis, H. Tzitzimikas as well as with the Estudiantina N. Ionias orchestra.</p>`,
          },
          {
            name: 'Giannis Poulios',
            speciality: 'Violin',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Giannis Poulios</h2>
            <p>Giannis Poulios was born in Bruschal, Germany and grew up in Katerini. </p>
            
            <p>He studied at the music
            school of Katerini and later studied at the Department of Folk and of Traditional Music in Arta with
            their teachers: George Psaltis and Makis Baklatzis as well as at the Yildiz Technical University in
            Istanbul (through the Erasmus program) for two semesters with teachers Özer Özel, Nedim
            Nalbantoğlu and Murat Sakaryali. </p>
            
            <p>Today he is actively involved in the Greek music scene as well as
            abroad with collaborations on stage and at a record label level with various musical groups such as:
            "Tasos Poulios quartet", "Summation" and "Hane" by Apostolos Sideris, the Heliodromio shape, the
            Lappino project by Dimitris Lappa, and Ali Perret's DU.DU scheme in Istanbul. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>He has also
            collaborated with the music group Psyche & Body, with Mattheos and Costas Tsachouridis at the
            Athens Concert Hall, the Loxandra group and with artists such as Eleni Tsaligopoulou, Nena
            Venetsanou, Haig Yazdjan, Irini Tornesakis, Pericles Papapetropoulos, Irini Tornesaki, Areti Ketime,
            Lizeta Kalimeri, Antonis Apergis, Manos Ahalinotopoulos, Fotis Siotas, Nikos Paraoulakis,
            Kostantinos Plousios and other musicians of the Greek music scene. </p>
            
            <p>Also, for the past two years he
            has worked in the field of education as a substitute teacher for traditional violin at the experimental
            music high school of Pallini.</p>`,
          },
          {
            name: 'Panagiotis Tsakos',
            speciality: 'Guitar, Lute',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Panagiotis Tsakos</h2>
            <p>Panagiotis Tsakos was born and raised in Nafpaktos. He studied at the TEI of Folk and Traditional
            Music in Arta, specializing in folk guitar. He has been active in the field of traditional folk music and
            beyond, since 2001, participating in projects and collaborating with well-known names in the field.</p>`,
          },
          {
            name: 'Petros Papageorgiou',
            speciality: 'Percussion',
            image: 'papageorgiou.jpg',
            youtube: 'https://www.youtube.com/@Petrran',
            spotify: '',
            description: `<h2>Petros Papageorgiou</h2>
            <p>Petros Papageorgiou had his first contact with music at a young age with his admission to the public
            conservatory of Ioannina. He studied Western music theory and harmonium for 4 years. He then
            discovered and studied percussion with an emphasis on Greek traditional music. At the same time, he
            completed his postgraduate studies in the “Performance of Instrumental and Vocal Music" program, at
            the Department of Music Studies of the National Kapodistrian University of Athens, focusing on
            "Performance & Interpretation of Traditional Music" and a specialization in traditional percussion. He
            continues his personal exploration in the field of percussion alongside Vangelis Karipis, Ilias
            Dimanos and Yshai Afterman. </p>
            
            <p>Since 2003 he has been an active member of the En Hordais group. He
            participated in seminars with renowned percussion artists such as Misirli Ahmet, Bijan Cemirani, Ziya
            Tabassian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin as well as the
            International Percussion Meeting (Tunis, 2004).
            As a member of En Chordais, he has been awarded the "Prix France Musique des Musiques du
            Monde", Babel Med Music / Radio France (Marseille 2008) and was nominated for the UNESCO
            "Sharjah Prize" for Arabic Culture (2006). He has participated in more than 300 concerts in many
            countries around the world, in major festivals and famous concert venues such as: The Grace Rainey
            Rogers Auditorium of Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison
            Symphonic (Montreal) , Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theater de la Ville
            (Paris), Institut du Monde Arabe (Paris), Bibliotheca Alexandrina (Egypt), Carl-Orff Saal Gasteig
            (Munich), Silk Road Arts Festival (Hong Kong), Melbourne Recital Center (Australia), Ten Days on
            the Island (Tasmania), Fès Festival (Morocco), Cemal Resit Rey Concert Hall (Istanbul), Onassis
            Culture Center (Athens), Cite de la Music (Marseille), The International Jerusalem Oud Festival
            (Israel), Athens Concert Hall, Thessaloniki Concert Hall, Les Suds à Arles Festival, Journées
            Musicales de Carthage (Tunis), Cairo Opera House, Tropenmuseum (Amsterdam), Gibraltar World
            Music Festival, Romanian Culture Institute (Bucharest) and in many other cities around the world. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>As a guest teacher he has participated in a seminar at the famous Sibelius Music Academy in Helsinki.</p>
            
            <p>He has taken part in record productions and collaborates with many renowned Greek and foreign
            musicians and music groups such as: Hellenic National Radio Television Orchestra, Ensemble
            Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat
            Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis
            Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saragoudas, Maria Farantouri, Grigoris Kapsalis,
            Nikos Filippidis, Haig Yazdjian, and many others.</p>`,
          },
        ],
        description: `<p>
          A 6-hour workshop dedicated to the music of Epirus by experienced Greek musicians. It will be
          followed by a 45-minute concert titled "Kintoi Mane".
        </p>
        <p>
          In the workshop, the participants will come into direct contact with the traditional instruments of
          Epirus as well as the music. Its purpose is for the participants to understand the physical
          characteristics and musical possibilities of the instruments and the voice.
        </p><p>
          During the workshop, songs from Epirus will be presented. It will be possible to discuss:<br/>
          - the importance of traditional music<br/>
          - the relationships and differences with other types of music<br/>
          - the musicological identity and musical possibilities of the basic traditional instruments<br/>
          - the basic musical traditional scales<br/>
          - the ways of instrumental and vocal performance of traditional songs.
        </p>
        <p>
          Kintoi mane! A musical program filled with music and songs from Epirus, the wider area of Pindos
          and Xiromero who have as a common feature their oriental origin. Songs created during the period of
          the Ottoman Empire, but also more recently, from the time of Kafe Aman, arranged by folk
          musicians.
        </p>
        <p>
          Songs whose creation is lost over time but still have a place in parties and discography. We got to
          know some of them from the double LP "Zagorisia and Gianniotika", the first release done by
          Christos Tzitzimikas (published by Iambos 1993).
        </p>
        <p><b class="warning">Important information:</b> To ensure that the workshop takes place, we require a minimum of 20 participants. If this number is not reached, the workshop will be canceled and you will receive a full refund of your ticket price. You will also receive an email notification in the event of a cancellation.</p>
      `,
        eventType: 'Workshop & Concert',
        video: '',
        time: 'Workshop: 15:00 | Concert: 21:00',
        doorsOpen: '15:00',
        entrance: 'Workshop: 65€/55€ | Concert: Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,15z/data=!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'Bavul',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '2',
        startDate: '2024-01-27T20:00:00.349Z',
        endDate: '2024-01-28T05:00:00.349Z',
        title: 'Live concert by Christos Tzitzimikas and his company',
        poster: '',
        artists: [
          {
            name: 'Christos Tzitzimikas',
            speciality: 'Vocals',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Christos Tzitzimikas</h2>
            <p>Coming from a family of folk singers, Christos experienced folk music from an early age. Since 1983
            he has been engaged in the research and performance of Epirus, Macedonian, Thessalian and Central
            Greek folk songs. </p>
            
            <p>He studies Byzantine music at the Skalkota Conservatory with Lykourgos
            Angelopoulos, vocals with Lucia Fracea and is completing his studies at T.E.F.A.A. Athens. </p>
            
            <p>His first appearances were with the music group "TERIREM". Since then, he has collaborated with many old
            and new notable musicians, playing many concerts and theatrical performances all over Greece and
            abroad. </p>
            
            <p>In 1993, his first album titled "Zagorisia and Gianniotika" was released by Iambos
            publications. With the participation of Grigoris Kapsalis on the clarinet, ten notable musicians and
            "TERIREM". It was recorded by Giorgos Sygletos and the orchestra was conducted by Giorgos
            Papadakis. The release was accompanied by an insert (in Greek and English) with lots of information
            and photos. </p>
            
            <p>A song from the album “Zagorisia and Gianniotika” was used in the film "Athens --
            Constantinople" by Nikos Panagiotopoulos. </p>
            
            <p>"Patima" is the title of the second album released by
            Iambos in 2001. It includes 16 songs with reference point the Peacock of Epirus. Petroloukas Halkias
            (clarinet), Christodoulos Zoumbas (lute, music editor) and four other notable musicians are
            participating. The release is in the form of a book with extensive historical and musicological
            comments (Greek and English) as well as photographic material. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>The two records received great reviews from the press and are presented live in cities both in Greece and abroad.</p> 
            
            <p>In 2001 he participated in a release titled "Thessalia", in 2015 in the Stamatis Lallas CD "From the heart of
            Agiathymia", and in 2019 the Vassilis Triantis CD of "Portolanos". He taught traditional singing at
            the Department of Greek Traditional Music of TEI Arta, at the Department of Music Science and Art
            of the University of Macedonia, at the Music Village, Agios Lavrentios, as well as seminars and
            private lessons.</p>`,
          },
          {
            name: 'Antonis Kaliouris',
            speciality: 'Clarinet',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Antonis Kaliouris</h2>
            <p>Antonis was born in Athens, where he grew up, but he is originally from Rethymno, Crete. He is a
            graduate from the Department of Biotechnology of the Agricultural University of Athens. </p>
            
            <p>His involvement in music began at the early age of nine, learning the lute. At the age of twelve he started
            studying traditional clarinet, with his teacher V. Papanastasiou. Later, for three years, he studied
            classical clarinet at the Attica Conservatory, with professor Manousos Ploumidis. In 2011, for four
            years, he attended traditional clarinet lessons with master Alexandros Arkadopoulos. At the same time
            he took flute lessons at the National Conservatory, with teacher Danae Kiupouroglou. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>From an early age he participated in cultural events as well as festivals, weddings and dance events throughout
            Greece. </p>
            
            <p>He has participated as a musician in concerts, shows, record labels as well as radio and
            television programs- with various artists of the folk and traditional scene, such as: G. Dalaras,
            Glykeria, K. Makedonas, G. Kotsiras, G. Andreatos, S. Papazoglou, B. Tsertos, L. Mahairitsas, G.
            Lebesis, G. Nega, M. Paschalidis, N. Karagianni, K. Vetta, C. Dantes, G. Merantzas, Erofili, P.
            Lalezas, M. Koutsaggelidis, H. Tzitzimikas as well as with the Estudiantina N. Ionias orchestra.</p>`,
          },
          {
            name: 'Giannis Poulios',
            speciality: 'Violin',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Giannis Poulios</h2>
            <p>Giannis Poulios was born in Bruschal, Germany and grew up in Katerini. </p>
            
            <p>He studied at the music
            school of Katerini and later studied at the Department of Folk and of Traditional Music in Arta with
            their teachers: George Psaltis and Makis Baklatzis as well as at the Yildiz Technical University in
            Istanbul (through the Erasmus program) for two semesters with teachers Özer Özel, Nedim
            Nalbantoğlu and Murat Sakaryali. </p>
            
            <p>Today he is actively involved in the Greek music scene as well as
            abroad with collaborations on stage and at a record label level with various musical groups such as:
            "Tasos Poulios quartet", "Summation" and "Hane" by Apostolos Sideris, the Heliodromio shape, the
            Lappino project by Dimitris Lappa, and Ali Perret's DU.DU scheme in Istanbul. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>He has also
            collaborated with the music group Psyche & Body, with Mattheos and Costas Tsachouridis at the
            Athens Concert Hall, the Loxandra group and with artists such as Eleni Tsaligopoulou, Nena
            Venetsanou, Haig Yazdjan, Irini Tornesakis, Pericles Papapetropoulos, Irini Tornesaki, Areti Ketime,
            Lizeta Kalimeri, Antonis Apergis, Manos Ahalinotopoulos, Fotis Siotas, Nikos Paraoulakis,
            Kostantinos Plousios and other musicians of the Greek music scene. </p>
            
            <p>Also, for the past two years he
            has worked in the field of education as a substitute teacher for traditional violin at the experimental
            music high school of Pallini.</p>`,
          },
          {
            name: 'Panagiotis Tsakos',
            speciality: 'Guitar, Lute',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Panagiotis Tsakos</h2>
            <p>Panagiotis Tsakos was born and raised in Nafpaktos. He studied at the TEI of Folk and Traditional
            Music in Arta, specializing in folk guitar. He has been active in the field of traditional folk music and
            beyond, since 2001, participating in projects and collaborating with well-known names in the field.</p>`,
          },
          {
            name: 'Petros Papageorgiou',
            speciality: 'Percussion',
            image: 'papageorgiou.jpg',
            youtube: 'https://www.youtube.com/@Petrran',
            spotify: '',
            description: `<h2>Petros Papageorgiou</h2>
            <p>Petros Papageorgiou had his first contact with music at a young age with his admission to the public
            conservatory of Ioannina. He studied Western music theory and harmonium for 4 years. He then
            discovered and studied percussion with an emphasis on Greek traditional music. At the same time, he
            completed his postgraduate studies in the “Performance of Instrumental and Vocal Music" program, at
            the Department of Music Studies of the National Kapodistrian University of Athens, focusing on
            "Performance & Interpretation of Traditional Music" and a specialization in traditional percussion. He
            continues his personal exploration in the field of percussion alongside Vangelis Karipis, Ilias
            Dimanos and Yshai Afterman. </p>
            
            <p>Since 2003 he has been an active member of the En Hordais group. He
            participated in seminars with renowned percussion artists such as Misirli Ahmet, Bijan Cemirani, Ziya
            Tabassian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin as well as the
            International Percussion Meeting (Tunis, 2004).
            As a member of En Chordais, he has been awarded the "Prix France Musique des Musiques du
            Monde", Babel Med Music / Radio France (Marseille 2008) and was nominated for the UNESCO
            "Sharjah Prize" for Arabic Culture (2006). He has participated in more than 300 concerts in many
            countries around the world, in major festivals and famous concert venues such as: The Grace Rainey
            Rogers Auditorium of Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison
            Symphonic (Montreal) , Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theater de la Ville
            (Paris), Institut du Monde Arabe (Paris), Bibliotheca Alexandrina (Egypt), Carl-Orff Saal Gasteig
            (Munich), Silk Road Arts Festival (Hong Kong), Melbourne Recital Center (Australia), Ten Days on
            the Island (Tasmania), Fès Festival (Morocco), Cemal Resit Rey Concert Hall (Istanbul), Onassis
            Culture Center (Athens), Cite de la Music (Marseille), The International Jerusalem Oud Festival
            (Israel), Athens Concert Hall, Thessaloniki Concert Hall, Les Suds à Arles Festival, Journées
            Musicales de Carthage (Tunis), Cairo Opera House, Tropenmuseum (Amsterdam), Gibraltar World
            Music Festival, Romanian Culture Institute (Bucharest) and in many other cities around the world. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>As a guest teacher he has participated in a seminar at the famous Sibelius Music Academy in Helsinki.</p>
            
            <p>He has taken part in record productions and collaborates with many renowned Greek and foreign
            musicians and music groups such as: Hellenic National Radio Television Orchestra, Ensemble
            Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat
            Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis
            Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saragoudas, Maria Farantouri, Grigoris Kapsalis,
            Nikos Filippidis, Haig Yazdjian, and many others.</p>`,
          },
        ],
        description: `<p>
      During the next two days, Christos Tzitzimikas and his company invite us to two evenings that will
      unearth some of the deepest Greek traditions. The renowned singer can rouse genuine traditional
      parties (glentia); highlighting the richness of the Greek tradition. An authentic continental party
      (glenti) in Berlin!
      </p>
      `,
        eventType: 'Concert',
        video: '',
        time: '21:00',
        doorsOpen: '20:00',
        entrance: '24€/20€',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
      {
        id: '3',
        startDate: '2024-01-28T17:00:00.349Z',
        endDate: '2024-01-28T22:00:00.349Z',
        title: 'Live concert by Christos Tzitzimikas and his company',
        poster: '',
        artists: [
          {
            name: 'Christos Tzitzimikas',
            speciality: 'Vocals',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Christos Tzitzimikas</h2>
            <p>Coming from a family of folk singers, Christos experienced folk music from an early age. Since 1983
            he has been engaged in the research and performance of Epirus, Macedonian, Thessalian and Central
            Greek folk songs. </p>
            
            <p>He studies Byzantine music at the Skalkota Conservatory with Lykourgos
            Angelopoulos, vocals with Lucia Fracea and is completing his studies at T.E.F.A.A. Athens. </p>
            
            <p>His first appearances were with the music group "TERIREM". Since then, he has collaborated with many old
            and new notable musicians, playing many concerts and theatrical performances all over Greece and
            abroad. </p>
            
            <p>In 1993, his first album titled "Zagorisia and Gianniotika" was released by Iambos
            publications. With the participation of Grigoris Kapsalis on the clarinet, ten notable musicians and
            "TERIREM". It was recorded by Giorgos Sygletos and the orchestra was conducted by Giorgos
            Papadakis. The release was accompanied by an insert (in Greek and English) with lots of information
            and photos. </p>
            
            <p>A song from the album “Zagorisia and Gianniotika” was used in the film "Athens --
            Constantinople" by Nikos Panagiotopoulos. </p>
            
            <p>"Patima" is the title of the second album released by
            Iambos in 2001. It includes 16 songs with reference point the Peacock of Epirus. Petroloukas Halkias
            (clarinet), Christodoulos Zoumbas (lute, music editor) and four other notable musicians are
            participating. The release is in the form of a book with extensive historical and musicological
            comments (Greek and English) as well as photographic material. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>The two records received great reviews from the press and are presented live in cities both in Greece and abroad.</p> 
            
            <p>In 2001 he participated in a release titled "Thessalia", in 2015 in the Stamatis Lallas CD "From the heart of
            Agiathymia", and in 2019 the Vassilis Triantis CD of "Portolanos". He taught traditional singing at
            the Department of Greek Traditional Music of TEI Arta, at the Department of Music Science and Art
            of the University of Macedonia, at the Music Village, Agios Lavrentios, as well as seminars and
            private lessons.</p>`,
          },
          {
            name: 'Antonis Kaliouris',
            speciality: 'Clarinet',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Antonis Kaliouris</h2>
            <p>Antonis was born in Athens, where he grew up, but he is originally from Rethymno, Crete. He is a
            graduate from the Department of Biotechnology of the Agricultural University of Athens. </p>
            
            <p>His involvement in music began at the early age of nine, learning the lute. At the age of twelve he started
            studying traditional clarinet, with his teacher V. Papanastasiou. Later, for three years, he studied
            classical clarinet at the Attica Conservatory, with professor Manousos Ploumidis. In 2011, for four
            years, he attended traditional clarinet lessons with master Alexandros Arkadopoulos. At the same time
            he took flute lessons at the National Conservatory, with teacher Danae Kiupouroglou. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>From an early age he participated in cultural events as well as festivals, weddings and dance events throughout
            Greece. </p>
            
            <p>He has participated as a musician in concerts, shows, record labels as well as radio and
            television programs- with various artists of the folk and traditional scene, such as: G. Dalaras,
            Glykeria, K. Makedonas, G. Kotsiras, G. Andreatos, S. Papazoglou, B. Tsertos, L. Mahairitsas, G.
            Lebesis, G. Nega, M. Paschalidis, N. Karagianni, K. Vetta, C. Dantes, G. Merantzas, Erofili, P.
            Lalezas, M. Koutsaggelidis, H. Tzitzimikas as well as with the Estudiantina N. Ionias orchestra.</p>`,
          },
          {
            name: 'Giannis Poulios',
            speciality: 'Violin',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Giannis Poulios</h2>
            <p>Giannis Poulios was born in Bruschal, Germany and grew up in Katerini. </p>
            
            <p>He studied at the music
            school of Katerini and later studied at the Department of Folk and of Traditional Music in Arta with
            their teachers: George Psaltis and Makis Baklatzis as well as at the Yildiz Technical University in
            Istanbul (through the Erasmus program) for two semesters with teachers Özer Özel, Nedim
            Nalbantoğlu and Murat Sakaryali. </p>
            
            <p>Today he is actively involved in the Greek music scene as well as
            abroad with collaborations on stage and at a record label level with various musical groups such as:
            "Tasos Poulios quartet", "Summation" and "Hane" by Apostolos Sideris, the Heliodromio shape, the
            Lappino project by Dimitris Lappa, and Ali Perret's DU.DU scheme in Istanbul. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>He has also
            collaborated with the music group Psyche & Body, with Mattheos and Costas Tsachouridis at the
            Athens Concert Hall, the Loxandra group and with artists such as Eleni Tsaligopoulou, Nena
            Venetsanou, Haig Yazdjan, Irini Tornesakis, Pericles Papapetropoulos, Irini Tornesaki, Areti Ketime,
            Lizeta Kalimeri, Antonis Apergis, Manos Ahalinotopoulos, Fotis Siotas, Nikos Paraoulakis,
            Kostantinos Plousios and other musicians of the Greek music scene. </p>
            
            <p>Also, for the past two years he
            has worked in the field of education as a substitute teacher for traditional violin at the experimental
            music high school of Pallini.</p>`,
          },
          {
            name: 'Panagiotis Tsakos',
            speciality: 'Guitar, Lute',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Panagiotis Tsakos</h2>
            <p>Panagiotis Tsakos was born and raised in Nafpaktos. He studied at the TEI of Folk and Traditional
            Music in Arta, specializing in folk guitar. He has been active in the field of traditional folk music and
            beyond, since 2001, participating in projects and collaborating with well-known names in the field.</p>`,
          },
          {
            name: 'Petros Papageorgiou',
            speciality: 'Percussion',
            image: 'papageorgiou.jpg',
            youtube: 'https://www.youtube.com/@Petrran',
            spotify: '',
            description: `<h2>Petros Papageorgiou</h2>
            <p>Petros Papageorgiou had his first contact with music at a young age with his admission to the public
            conservatory of Ioannina. He studied Western music theory and harmonium for 4 years. He then
            discovered and studied percussion with an emphasis on Greek traditional music. At the same time, he
            completed his postgraduate studies in the “Performance of Instrumental and Vocal Music" program, at
            the Department of Music Studies of the National Kapodistrian University of Athens, focusing on
            "Performance & Interpretation of Traditional Music" and a specialization in traditional percussion. He
            continues his personal exploration in the field of percussion alongside Vangelis Karipis, Ilias
            Dimanos and Yshai Afterman. </p>
            
            <p>Since 2003 he has been an active member of the En Hordais group. He
            participated in seminars with renowned percussion artists such as Misirli Ahmet, Bijan Cemirani, Ziya
            Tabassian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin as well as the
            International Percussion Meeting (Tunis, 2004).
            As a member of En Chordais, he has been awarded the "Prix France Musique des Musiques du
            Monde", Babel Med Music / Radio France (Marseille 2008) and was nominated for the UNESCO
            "Sharjah Prize" for Arabic Culture (2006). He has participated in more than 300 concerts in many
            countries around the world, in major festivals and famous concert venues such as: The Grace Rainey
            Rogers Auditorium of Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison
            Symphonic (Montreal) , Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theater de la Ville
            (Paris), Institut du Monde Arabe (Paris), Bibliotheca Alexandrina (Egypt), Carl-Orff Saal Gasteig
            (Munich), Silk Road Arts Festival (Hong Kong), Melbourne Recital Center (Australia), Ten Days on
            the Island (Tasmania), Fès Festival (Morocco), Cemal Resit Rey Concert Hall (Istanbul), Onassis
            Culture Center (Athens), Cite de la Music (Marseille), The International Jerusalem Oud Festival
            (Israel), Athens Concert Hall, Thessaloniki Concert Hall, Les Suds à Arles Festival, Journées
            Musicales de Carthage (Tunis), Cairo Opera House, Tropenmuseum (Amsterdam), Gibraltar World
            Music Festival, Romanian Culture Institute (Bucharest) and in many other cities around the world. </p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>As a guest teacher he has participated in a seminar at the famous Sibelius Music Academy in Helsinki.</p>
            
            <p>He has taken part in record productions and collaborates with many renowned Greek and foreign
            musicians and music groups such as: Hellenic National Radio Television Orchestra, Ensemble
            Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat
            Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis
            Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saragoudas, Maria Farantouri, Grigoris Kapsalis,
            Nikos Filippidis, Haig Yazdjian, and many others.</p>`,
          },
        ],
        description: `<p>
      During the next two days, Christos Tzitzimikas and his company invite us to two evenings that will
      unearth some of the deepest Greek traditions. The renowned singer can rouse genuine traditional
      parties (glentia); highlighting the richness of the Greek tradition. An authentic continental party
      (glenti) in Berlin!
      </p>
      `,
        eventType: 'Concert',
        video: '',
        time: '18:00',
        doorsOpen: '17:00',
        entrance: '24€/20€',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
    ],
    supplementary: `<h2>"Death, Immigration, Bitterness and Orphanhood". This is the Epirus song.</h2>
    <p>
      Epirus is the geographical area located at the northwestern tip of Greece. The name comes from the
      adjective “aperios”, Doric pronunciation of the adjective “epiros”. It is a mountainous region and the
      lowlands are mainly around the mouths of its rivers.
    </p>
    <p>
      The musical tradition of Epirus is one of the greatest cultural treasures of Greece. In the isolated
      villages of Epirus, a distinct sound, a unique rhythm has been cultivated and preserved. The local
      music and songs in Epirus have a primal purity, a healing power. The musical culture in Epirus has
      remained unchanged and unadulterated by foreign influences for thousands of years.
    </p>
    <p>
      In Epirus, dance and song are a collective act of remembrance, confession, levity and popular
      wisdom. A dive into the different stages of life, and various emotions that are deeply rooted in the
      historical consciousness of the people of Epirus, that stretches from Aetoloakarnania to Southern
      Albania.
    </p>
    <p>
      Epirus music is distinguished all over Greece by the harmonic and melodic color that surrounds it:
      the melodic lines are short, the sound is sad, even the songs with a cheerful purpose or with satirical
      content sound "heavy". The wild austerity of the mountain landscape could only be reflected in the
      lack of all unnecessary extravagance.
    </p>
    <p>
      The content of the songs varies. References are made to historical and heroic events, some are
      sentimental, some are about weddings, traveling and often times some are about immigration and
      being a foreigner. Only war songs were not widespread throughout Epirus- mainly because fights did
      not take place across the whole region. Thus, we find war songs mainly in Thesprotia, and specifically
      in the area of Souli.
    </p>
    <p>
      The clarinet is the leading musical instrument. Other instruments of continental music are the violin,
      the land lute, the tambourine, the flute, the jamara, and the sanduri.
    </p>
    <p>
      In traditional Epirotian parties there is a ritual: The first song is always the doomsayers. Either for the
      dead, or for the immigrants- as the concept of immigration in Epirus was characterized as death. That
      is usually followed by “Skaros” and then music with various sounds, purpose and lyrics. A mosaic of
      joys, woes, nostalgia, daily suffering and anger. The feast always ends with songs of farewell.
      Folk songs were oral. The music wasn't written, the lyrics weren't written. Just word of mouth stories.
    </p>
    `,
  },
  {
    id: '11',
    url: 'bando-and-friends2',
    title: 'Bando & Friends 2',
    description: `<p>The second issue of BANDO & Friends and, at the same time, BANDO's 25th birthday party!!</p>
    <p>The whole thing will be celebrated accordingly in the former BANDO cellar (nowadays part of Jugendhaus Königstadt) in Prenzlauer Berg.</p>
    <p>Come & bring your friends, we wanna dance with you!!</p>
    `,
    poster: 'bando-friends.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2023-12-01T19:00:00.349Z',
        endDate: '2023-12-02T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Local support',
            speciality: 'metal/punk/hardcore',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'BANDO',
            speciality: 'hardcore percussion',
            image: '',
            youtube: 'https://www.youtube.com/watch?v=6a0B_g5oGt0',
            spotify: '',
          },
          {
            name: 'DJ Sez',
            speciality: '',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '20:00',
        doorsOpen: '19:00',
        entrance: 5,
        googleLocation:
          'https://www.google.com/maps/place/BANDO+Berlin/@52.5417735,13.3859352,14z/data=!3m1!5s0x47a851e24f3df225:0x9c79e48257493088!4m10!1m2!2m1!1sBANDO+Berlin!3m6!1s0x47a84e1d481a733f:0xc5c1ccb3d36c4565!8m2!3d52.5306014!4d13.4134281!15sCgxCQU5ETyBCZXJsaW6SAQRiYW5k4AEA!16s%2Fg%2F11fkf7x9l7?entry=ttu',
        locationName: 'Jugendhaus Königstadt',
        locationAddress: 'Saarbrücker Straße 23 (U-Bahn Senefelder Platz)',
      },
    ],
    supplementary: '',
  },
  {
    id: '1',
    url: 'glenti-advent-dance-party-ljuti-hora',
    title: 'Glenti – Advent dance party with Ljuti Hora (Berlin)',
    description: `<p>There will be lots of Greek and other Balkan music. The musicians are in good shape and much looking forward to get you & your feet going!!</p>
    `,
    poster: 'LjutiHora-2-12-23.jpg',
    tip: '',
    types: ['suggested', 'participating'],
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2023-12-02T19:00:00.349Z',
        endDate: '2023-12-03T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Mate Gaal',
            speciality: 'Clarinet, flutes, vocals',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Accordeon',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Martin Petrov',
            speciality: 'Gajda, percussion, vocals',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Oliver Goers',
            speciality: 'Percussion, vocals',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Tina Hess',
            speciality: 'Dancer',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Doris Schneider',
            speciality: 'Dancer',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '20:00',
        doorsOpen: '19:30',
        entrance: '10/8 €',
        googleLocation:
          'https://www.google.com/maps/place/Haus+der+Sinne/@52.5491318,13.3993861,17z/data=!3m1!4b1!4m6!3m5!1s0x47a8521b504a4e1d:0x8b7e06018e6373de!8m2!3d52.5491319!4d13.4042516!16s%2Fg%2F1tdck_b3?entry=ttu',
        locationName: 'Haus der Sinne',
        locationAddress: 'Ystader Straße 10, 10437 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '2',
    url: 'kickoff-night',
    title: 'Kickoff night to spread the provokatsia vibes!',
    description: `<p><h3>And here we are lovely people!</h3>
    This is the kickoff night of many more to come!<br />
    A warm-up session to get you ready for what is coming!<br />
    A get-together night to remember!<br />
    A sneak peek to the provokatsia world!</p>
    <p>And all that in the rhythms of</p>
    <p>
    ---------------<br />
    Roots reggae<br />
    Dub &<br />
    Afro music<br />
    --------------</p>
    <p>Lets meet! Lets dance! Lets spread some love!<br />
    And embrace together the provokatsia vibes!</p>
    `,
    poster: 'kickoff-night.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-04T21:00:00.349Z',
        endDate: '2023-12-05T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Ital-b toaster',
            speciality: 'Greece',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Pikoz Apikoz',
            speciality: 'Berlin',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Joolee Rankin',
            speciality: 'France',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '20:00',
        doorsOpen: '20:00',
        entrance: '5 €',
        googleLocation:
          'https://www.google.com/maps/place/Lauschangriff/@52.5182464,13.4553066,15z/data=!4m6!3m5!1s0x47a84e6653637a9d:0xd32edb6abbf17c08!8m2!3d52.5182464!4d13.4553066!16s%2Fg%2F11bxh9tjcp?entry=ttu',
        locationName: 'Lauschangriff',
        locationAddress: 'Rigaer Str. 103, 10247 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '3',
    url: '3day-tribute-to-rempetiko',
    title: 'A 3-day tribute to rempetiko',
    description: `<h3>A musical journey exploring rempetiko</h3>
    <p>Τhree-day tribute to the rempetiko style of music. </p>
    <p>Screening of “Broken Sound", a documentary about bouzouki- a key instrument of the rempetiko orchestra</p>
    <p>Book presentation about Dimitris Gogos (Bayanteras) and a concert dedicated to songs by the biggest rempetiko artists, from both pre-war and post-war times. </p>
    `,
    poster: '3day-tribute-to-rempetiko.jpg',
    tip: 'Tip: Limited number of tickets, please come early to get a spot! ;)',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-08T20:30:00.349Z',
        endDate: '2023-12-10T01:00:00.349Z',
        title: 'Rempetiko Concert - Famous Songs & Deep Cuts',
        poster: '',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Guitar',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Accordeon',
            image: 'sanne.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Maria Vakali',
            speciality: 'Vocals',
            image: 'maria.jpg',
            youtube: 'https://www.youtube.com/watch?v=t_TywytZvdw',
            spotify: '',
          },
          {
            name: 'Anestis Barbatsis',
            speciality: 'Bouzouki, Vocals',
            image: 'anestis.png',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Orfeas Tsaktsiras',
            speciality: 'Bouzouki, Vocals',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '21:30',
        doorsOpen: '20:30',
        entrance: 22,
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
      {
        id: '2',
        startDate: '2023-12-09T18:00:00.349Z',
        endDate: '2023-12-09T22:00:00.349Z',
        title: '"Broken Sound" - Documentary about Bouzouki',
        subtitle:
          'Idea-Written: Anestis Barbatsis | Directed: Foivos Kontogiannis',
        poster: '',
        artists: [],
        description: `
          <p>The music documentary "Broken Sound", a co-production of Anestis Barbatsis and Phoebus Kontogiannis, produced by Foss Productions, which was co-produced with the Hellenic Film Centre</p>
          <p>Phoebus Kontogiannis, responsible for the direction and Anestis Barbatsis, responsible for the idea, research, editing, music editing, as well as the selection and interviews of the protagonists and other speakers, lead us on a journey of memory to the sound of the country's most beloved folk instrument told through the eyes of great personalities of the modern history of bouzouki</p>
          <p>From the dark tequettes in the years of illegality, to the great salons of the Greek pentagram, the bouzouki unfolds its soul in the hands of the musicians who loved and promoted it. Part of this visualized narrative and present in this demanding work are great musicians such as Dimitris Vyzas, Giorgos Dramalis, Manolis Karantinis, Vangelis Liolios, Giannis Moraitis, Christos Nikolopoulos, Giannis Papavasiliou, Kostas Papadopoulos, Manolis Pappos, Thanasis Polykandriotis, Panagiotis Stergios, Thymios Sturaitis, Nikos Tatasopoulos, Vangelis Trigas and Diamantis Chiotis, who with their taqasimos and fascinating narratives take us on a melodic journey to the past, present and future of the bouzouki.</p>
          <p>Following the musical evolution of the bouzouki, "Broken Sound" attempts a historical, musical overview, as well as a cultural and social survey of the evolution of Greek society, through interviews with great personalities of the modern history of the instrument, combined with rare archival material.</p>
        `,
        supplementary: `
          <h2>FILM CREDITS</h2>
          <p>Idea-Research: Anestis Barbatsis
            Script: Anestis Barbatsis, Foivos Kontogiannis
          </p>
          <p>Directed: Foivos Kontogiannis </p>
          <p>Editing-Music Supervision: Anestis Barbatsis</p>
          <p>
            Production: Foss Productions<br />
            Co-Production: Hellenic Film Centre, Faliro House, EKOME<br />
            Producer: Stelios Kotionis<br />
            Executive Producer: Christos B. Konstantakopoulos<br />
            Director of Photography: Manu Tilnsky<br />
            Editing: George Georgopoulos<br />
            Sound: Stavros Avramidis, Leandros Ntounis<br />
          </p>
        `,
        eventType: 'Documentary screening',
        time: "19:00 | Duration: 80'",
        doorsOpen: '18:00',
        videoId: '4Xf8JWi2jFg',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,15z/data=!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'Bavul',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '3',
        startDate: '2023-12-10T17:00:00.349Z',
        endDate: '2023-12-10T22:00:00.349Z',
        title:
          '"Bayianteras - Dimitris Gogos, The Pre-War Period" + Tribute Concert to Bayianteras',
        poster: 'mpagianteras.jpg',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Guitar',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Accordeon',
            image: 'sanne.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Maria Vakali',
            speciality: 'Vocals',
            image: 'maria.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Anestis Barbatsis',
            speciality: 'Bouzouki, Vocals',
            image: 'anestis.png',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Giorgos Evaggelou',
            speciality: 'Bouzouki, Vocals',
            image: 'giorgos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Orfeas Tsaktsiras',
            speciality: 'Bouzouki, Vocals',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
        ],
        description: `
          <h3>Book Presentation</h3>
          <p>Barbatsis Anestis, Bayanteras-Dimitris Gogos, Pre-war period, Metronomos 2022.</p>
          <p>Dimitris Gogos or Bayanteras was born in Piraeus in the year 1903.</p>
          <p>Growing up in Chatzikyriakio, he experienced the early period of the course and evolution of 'Piraeus' rempetiko based on bouzouki, before its first recording. The context in which he was nurtured and his musical career make him one of its most important representatives.</p>
          <p>Anestis Barbatsis's book focuses on the presentation and analysis of the composer's pre-war creation, while quoting important documents about his life and work which document his career and are published for the first time.</p>
        `,
        eventType: 'Book presentation + Concert',
        time: 'Presentation: 18:00 | Concert: 19:00',
        doorsOpen: '17:00',
        video: '',
        entrance: 'Presentation: Free | Concert: 17 €',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
    ],
    supplementary: `<h2>Rempetika - The Greek Blues</h2>
      <p><i>"Similar creations to those of rempetiko can be observed in America with the Blues, in Brazil with Samba and in Jamaica with Reggae."</i></p>
      <p><i>"Rempetiko is a popular urban song with roots in Byzantine music and Greek folk music."</i></p>
      <p><i>"Rebette: Unruly, rebellious, independent."</i></p>
      <p><i>"Rempetika are songs of the heart and only those who approach them with pure feeling feel them and enjoy them."</i></p>
      <p><i>"Rempetiko manages to combine speech, music and movement in a wondrous unity."</i></p>
      <p><i>"From composition to performance, the conditions for this triple coexistence are instinctively created, which sometimes, as if reaching perfection, reminds one of ancient tragedy."</i></p>
      <p>Excerpts from the book by Elias Petropoulos</p>
      <p><i>"The rempetiko song is genuinely Greek, uniquely Greek."</i></p>
      <p>Manos Hadjidakis</p>
    `,
  },
  {
    id: '4',
    url: 'saligari-meets-provokatsia',
    title: 'Saligari meets provokatsia',
    description: `<p>While 2023 is just about to end, new beginnings are happening in Berlin city. 
      Our beloved Saligari is moving to a new location with a different concept and this Saturday 
      is celebrating along with Provokatia Project team our new journeys to unknown! We are looking 
      forward to meet you all @Saligari bar, to get to know each other, to have a talk, exchange ideas, 
      listen to great music and drink unique cocktails.</p>
    `,
    poster: 'saligari-meets-provokatsia.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-16T18:00:00.349Z',
        endDate: '2023-12-17T06:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '19:00',
        doorsOpen: '19:00',
        entrance: 'Free Entrance',
        googleLocation:
          'https://www.google.com/maps/place/Simplonstra%C3%9Fe+23,+10245+Berlin/@52.5084283,13.4592078,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84e58dd616c81:0xd36c8ab82bfa3077!8m2!3d52.5084283!4d13.4592078!16s%2Fg%2F11c1z9gkkd?entry=ttu',
        locationName: 'Saligari bar',
        locationAddress: 'Simplonstrasse 23, 10245 Berlin',
      },
    ],
    supplementary: '',
  },
]
