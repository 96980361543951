export const eventsGR = [
  {
    id: '112233',
    url: 'balkan-tanzfest',
    title: 'Βαλκανικο φεστιβαλ χορου με την οικογενεια Anastasov και τους Ljuti Hora',
    description: `<p>Φίλοι λάτρεις του χορού και της μουσικής,</p>
    <p>Σε περίπου 3 εβδομάδες, το Balkan Express θα φιλοξενήσει την οικογένεια Anastasov από το Veles (Βόρεια Μακεδονία). Φίλοι της μακεδονικής και άλλης βαλκανικής μουσικής μπορούν να χορέψουν και να απολαύσουν τη μουσική.</p>

    <p>Εδώ και χρόνια, το Balkan Express διοργανώνει ένα βαλκανικό φεστιβάλ κατά τη διάρκεια του σεμιναρίου το Σαββατοκύριακο της άνοιξης. Κατά τη διάρκεια αυτής της εκδήλωσης, οι επισκέπτες εμφανίζονται μαζί με μουσικούς από το Βερολίνο. Φέτος, οι επισκέπτες μας από το Veles θα μοιραστούν τη σκηνή με τους μουσικούς των Ljuti Hora. Θα υπάρχει μακεδονική μουσική από διάφορες περιοχές, ήχοι Τσαμπούνας από τα ελληνικά νησιά, καθώς και θρακικοί και επιρρητικοί ήχοι. Βουλγάρικα τραγούδια θα συνδυαστούν με μακεδονικά και ποιος ξέρει ποιες άλλες συνδυασμοί θα προκύψουν. Σε κάθε περίπτωση, μπορείτε να απολαύσετε ακουστική, ρυθμική μουσική και πολύ χορό. Θα υπάρχουν ποτά και σνακ, αλλά όχι φαγητό. Επίσης δεν απαιτείται ξεχωριστή εγγραφή.</p>
    `,
    poster: 'balkan-tanzfest.png',
    tip: '',
    types: ['suggested'],
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2024-04-27T19:30:00.349Z',
        endDate: '2024-04-28T03:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'Συναυλία',
        video: '',
        time: '20:30',
        doorsOpen: '19:30',
        entrance: '15/13 €',
        googleLocation:
          'https://www.google.com/maps/place/Saal+der+Kreuzkirche/@52.4820335,13.2888139,17z/data=!3m1!4b1!4m6!3m5!1s0x47a851c76b33c4c5:0x13b9cfdad56e81!8m2!3d52.4820303!4d13.2913942!16s%2Fg%2F11lcgpf3fd?entry=ttu',
        locationName: 'Saal der Kreuzkirche (δίπλα απο Kreuzkirche)',
        locationAddress: 'Forckenbeckstraße 49, 14199 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '1111',
    url: 'darkwave-party',
    title: 'Darkwave - sheep n wolves - party',
    description: `<p>Provokatsia Project σε ατμοσφαιρικό ελκέκτρο dark wave mode!</p>
    <p>Ετοιμαστείτε να γυρίσουμε πίσω στο χρόνο με τους ηλεκτρισμένους ρυθμούς των 80s και 90s στο Lauschangriff στο Βερολίνο! Το Provokatsia Project Team σε προσκαλεί σε ενα αξέχαστο βράδυ όπου το παρελθόν συναντά το παρόν σε μια συμφωνία darkwave και ατμοσφαιρικών ηλεκτρονικών μελωδιών. Ενωθείτε μαζί μας για ένα πάρτι που υπόσχεται να είναι περισσότερο από μια απλή επιστροφή στο χρόνο.</p>
    <p>Οι DJs Pikoz Apikoz και Dj Kostas θα είναι στο τιμόνι, καθοδηγώντας μας μέσα από μια μουσική οδύσσεια με τα εξαιρετικά dj σετ τους. Είτε είστε φανατικός οπαδός της εποχής είτε απλά θέλετε να βιώσετε κάτι καινούργιο, that's the place to be στις 16 Μαρτίου.</p>
    <p>Μην χάσετε αυτό το ταξίδι μέσα στους dark wave ήχους των 80s 90s σε μια ατμόσφαιρα απόλυτα ηλεκτρική!</p>
    <p>Συμβουλή: Περιορισμένος αριθμός εισιτηρίων, ελάτε νωρίς για να λάβετε θέση! ;)</p>
    `,
    poster: 'darkwave-party.jpg',
    tip: 'Συμβουλή: Περιορισμένος αριθμός εισιτηρίων, ελάτε νωρίς για να λάβετε θέση! ;)',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-03-16T21:00:00.349Z',
        endDate: '2024-03-17T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Dj Pikoz Apikoz',
            speciality: '',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Dj Kostas',
            speciality: '',
            image: '',
            youtube: '',
            spotify: '',
          }
        ],
        description: '',
        eventType: 'Party',
        video: '',
        time: '21:00',
        doorsOpen: '21:00',
        entrance: 5,
        googleLocation:
          'https://www.google.com/maps/place/Lauschangriff/@52.5182464,13.4553066,15z/data=!4m6!3m5!1s0x47a84e6653637a9d:0xd32edb6abbf17c08!8m2!3d52.5182464!4d13.4553066!16s%2Fg%2F11bxh9tjcp?entry=ttu',
        locationName: 'Lauschangriff',
        locationAddress: 'Rigaer Str. 103, 10247 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '223',
    url: 'rebetiko-duets',
    title: 'Ρεμπέτικο ντουέτα! Χιώτης-Χασκίλ & Τσιτσάνης-Νίνου',
    description: `<p>Αγαπητοί φίλοι, οι Provokatsia Project team συνεχίζει τη διαδρομή της με άλλο ένα εκπαιδευτικό και μουσικό τριήμερο, σε συνεργασία με Greek Music Workshop και SKB (TU Berlin). Με χαρά υποδεχόμαστε τρεις υπέροχους μουσικούς που μας επισκέπτονται από την Ελλάδα, για να μας ταξιδέψουν στο χρόνο, σε ένα αφιέρωμα σε εμβληματικά ντουέτα της ρεμπέτικης μουσικής.
    <p>
    Πιο συγκεκριμένα, την Παρασκευή 23 Φεβ. στο καφέ TELquel (TU Berlin), μαζί με τον σεναριογράφο Ανέστη Μπαρμπάτση, θα γίνει προβολή του ντοκιμαντέρ Broken Sound/ Σπασμένος Ήχος, βασισμένο στο παραδοσιακό όργανο Μπουζούκι και στις επιρροές που δέχτηκε στο χρόνο. Θα ακολουθήσει ένα σύντομο live ρεμπέτικης μουσικής και για το υπόλοιπο βράδυ ενα DJ set με θέμα ¨μουσικές του κόσμου¨. 
    </p>
    <p>Το μουσικό ταξίδι θα συνεχιστεί το Σαββατο 24 Φεβ. στο Bavul, όπου οι επισκέπτες μουσικοί, Ανέστης Μπαρμπάτσης (φωνή, μπουζούκι), Λαμπρινή Astrid Γιώτη Andersson (φωνή) και Ιάσων Καλόγηρος (φωνή, μπουζούκι), μαζί με τους Βερολινέζους μουσικούς μας Ντίνο Μπουζάνη (κιθάρα), Sanne Möricke (ακορντεόν) και Θοδωρή (κοντραμπάσο) θα παίξουν ζωντανά τραγούδια από τα φημισμένα ντουέτα του Χιώτη-Χασκήλ και Τσιτσάνη-Νίνου αλλά και άλλα γνωστά ρεμπέτικα τραγούδια.</p> 
    <p>Και κλείνοντας, την Κυριακή 25 Φεβ. στο καφέ Karanfil θα πραγματοποιηθεί βιωματικό εργαστήριο διάρκειας 4ωρών, όπου οι συμμετέχοντες θα έχουν την ευκαιρία να ανακαλύψουν τους τρόπους τραγουδιού των Μαρίκα Νίνου και Στέλλα Χασκίλ, καθοδηγούμενοι από την καταξιωμένη εθνομουσικολόγο με εμπειρία στα παραδοσιακά ηχοχρώματα, Λαμπρινή Astrid Γιώτη Andersson. 
    Ένα εργαστήριο που απευθύνεται σε οποιονδήποτε τρέφει ενδιαφέρον για την ρεμπέτικη μουσική, χωρίς να είναι απαραίτητη η προϋπάρχουσα εμπειρία στο τραγούδι.</p>
    <p>Σας περιμένουμε!</p>
    <h3>Ανέστης Μπαρμπάτσης</h3>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/ZPQCqJP3m4w?si=pCAQrJERUlNDHKQl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <h3>Λαμπρινή Astrid Γιώτη Andersson</h3>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=gCnKlfksYzHSPsp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <h3>Το trailer του ντοκιμαντέρ</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/4Xf8JWi2jFg?si=qSCdYlyYiFn6eN0a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    poster: 'rebetiko-duets.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-02-23T19:00:00.349Z',
        endDate: '2024-02-25T02:00:00.349Z',
        title: 'Προβολη Ντοκιμαντερ + Μινι Συναυλια + Dj set',
        subtitle:
          `Ιδέα-Δημιουργία: Ανέστης Μπαρμπάτσης<br/> 
          Σκηνοθεσία: Φοίβος Κοντογιάννης<br/>
          <i>Ελληνικά με αγγλικούς υπότιτλους</i>`,
        poster: '',
        artists: [],
        description: `
            <p>Το μουσικό ντοκιμαντέρ «Σπασμένος Ήχος», μια συνδημιουργία των Ανέστη Μπαρμπάτση και Φοίβου Κοντογιάννη σε παραγωγή της Foss Productions, το οποίο πραγματοποιήθηκε σε συμπαραγωγή με το Ελληνικό Κέντρο Κινηματογράφου.</p>
            <p>Ο Φοίβος Κοντογιάννης υπεύθυνος για τη σκηνοθεσία και ο Ανέστης Μπαρμπάτσης, υπεύθυνος για την ιδέα, την έρευνα, αρχισυνταξία, τη μουσική επιμέλεια, καθώς και την επιλογή και τις συνεντεύξεις των πρωταγωνιστών και των λοιπών ομιλητών μας οδηγούν σε ένα ταξίδι μνήμης στον ήχο του πιο αγαπητού λαϊκού οργάνου της χώρας ειπωμένο μέσα από τα μάτια μεγάλων προσωπικοτήτων της σύγχρονης ιστορίας του μπουζουκιού. </p>
            <p>Από τους σκοτεινούς τεκέδες στα χρόνια της παρανομίας, στα μεγάλα σαλόνια του ελληνικού πενταγράμμου, το μπουζούκι ξεδιπλώνει την ψυχή του στα χέρια των μουσικών που το αγάπησαν και το ανέδειξαν. Κομμάτι της οπτικοποιημένης αυτής αφήγησης και παρόντες στο απαιτητικό αυτό πόνημα, αποτελούν σπουδαίοι μουσικοί όπως οι Δημήτρης Βύζας, Γιώργος Δράμαλης, Μανώλης Καραντίνης, Βαγγέλης Λιόλιος, Γιάννης Μωραΐτης, Χρίστος Νικολόπουλος, Γιάννης Παπαβασιλείου, Κώστας Παπαδόπουλος, Μανώλης Πάππος, Θανάσης Πολυκανδριώτης, Παναγιώτης Στεργίου, Θύμιος Στουραΐτης, Νίκος Τατασόπουλος, Βαγγέλης Τρίγκας και Διαμαντής Χιώτης, οι οποίοι με τα ταξίμια και τις συναρπαστικές αφηγήσεις τους μας ταξιδεύουν μελωδικά στο παρελθόν, το παρόν, αλλά και το μέλλον του μπουζουκιού. </p>
            <p>Παρακολουθώντας τη μουσική εξέλιξη του μπουζουκιού, o “Σπασμένος Ήχος” επιχειρεί μια ιστορική, μουσική επισκόπηση, καθώς και μια πολιτιστική και κοινωνική αποτύπωση της εξέλιξης της ελληνικής κοινωνίας, μέσα από συνεντεύξεις μεγάλων προσωπικοτήτων της σύγχρονης ιστορίας του οργάνου, σε συνδυασμό με σπάνιο αρχειακό υλικό. </p>
          `,
        supplementary: `
          <h2>Συντελεστες</h2>
          <h3>Ταυτοτητα Ταινιας</h3>
          <p>Ιδέα-Έρευνα : Ανέστης Μπαρμπάτσης<br />
          Σενάριο: Ανέστης ΜπαρμπάτσηςΦοίβος Κοντογιάννης.
          </p>
          <p>Σκηνοθεσία: Φοίβος Κοντογιάννης</p>
          <p>Αρχισυνταξία-Μουσική επιμέλεια: Ανέστης Μπαρμπάτσης</p>
          <p>
            Παραγωγή: Foss Productions<br />
            Συμπαραγωγή: Ελληνικό Κέντρο Κινηματογράφου, Faliro House, EKOME<br />
            Παραγωγός: Στέλιος Κοτιώνης<br />
            Executive Producer: Χρήστος Β. Κωνσταντακόπουλος<br />
            Διεύθυνση φωτογραφίας: Μανού Τιλίνσκι<br />
            Μοντάζ: Γιώργος Γεωργόπουλος<br />
            Ήχος: Σταύρος Αβραμίδης, Λέανδρος Ντούνης<br />           
          </p>
        `,
        eventType: 'Προβολή Ντοκιμαντέρ & Συναυλία',
        videoId: '4Xf8JWi2jFg',
        time: '19:00 Προβολή | 22:00 dj set',
        doorsOpen: '18:30',
        entrance: 'Δωρεάν',
        googleLocation:
          'https://www.google.com/maps/place/Department+of+Media+Science+Institute+of+Language+and+Communication,+Ernst-Reuter-Platz+7,+10587+Berlin/@52.5130599,13.3202022,20z/data=!4m6!3m5!1s0x47a8511c46ef3243:0x12b970f58d57050c!8m2!3d52.5130317!4d13.3200439!16s%2Fg%2F12hk180jv?entry=ttu',
        locationName: 'Cafe TELquel (TU Berlin)',
        locationAddress: 'Ernst-Reuter-Platz 7, 10587 Berlin',
      },
      {
        id: '2',
        startDate: '2024-02-24T19:00:00.349Z',
        endDate: '2024-02-24T02:00:00.349Z',
        title: 'Ρεμπετικη συναυλια',
        poster: '',
        artists: [
          {
            name: 'Ντίνος Μπουζάνης',
            speciality: 'Kιθάρα',
            image: 'ntinos.jpg',
            youtube: 'https://www.youtube.com/@unterrichtgitarrerebetikou1659',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Ακορντεόν',
            image: 'sanne.jpg',
            youtube: 'https://www.youtube.com/watch?v=qvfUrYWJ7Mo',
            spotify: '',
          },
          {
            name: 'Λαμπρινή Astrid Γιώτη Andersson',
            speciality: 'Φωνή',
            image: 'labrini.jpg',
            youtube: 'https://www.youtube.com/@LabriGiottoofficial',
            spotify: '',
            description: `<h2>Λαμπρινή Astrid Γιώτη Andersson </h2>
              <p>Η Λαμπρινή Astrid Γιώτη Andersson είναι Ελληνίδα ερμηνεύτρια, δασκάλα, ραδιοφωνική παραγωγός και εθνομουσικολόγος.</p>
              <p>Έχει αποφοιτήσει από το Μουσικό Γυμνάσιο Παλλήνης, κατέχει δίπλωμα κλασικού πιάνου από το Εθνικό Ωδείο Αθηνών , πτυχίο μουσικών σπουδών και μεταπτυχιακό τίτλο σπουδών στην εθνομουσικολογία από την Irish World Academy of Music & Dance (University of Limerick) με  υποτροφία από το Attica Tradition Foundation. </p> 
              <p>Ξεκίνησε την ενασχόλησή της με την Κέλτικη μουσική όταν ήταν 13 ετών και το 2006 έκανε την  μεταπτυχιακή της διατριβή με θέμα: "Sean-Nós singing style from the West Cork Gaeltacht area", όπου επικεντρώθηκε στο τραγουδιστικό στυλ των Eilís Ní Shúilleabháin, Iarla Ó Lionáird & Elisabeth Cronin.</p>
              <p>Κατά την περίοδο  2007& 2008,δίδαξε βυζαντινή,πολυφωνικό Ηπειρώτικο τραγούδι και ρεμπέτικο σε μορφή σεμιναρίων στο Irish World Academy of Music and Dance,Πανεπιστήμιο του Limerick.</p>
              <p>Έχει διδαχθεί παραδοσιακή μουσική & διαφορετικά στυλ από τους Elaine Cormican, Eilís Ní Shúilleabháin, Deirdre Scanlon, Mícheál Ó Súilleabháin ,Muireann Nic Amhlaoibh, Bobby Mc Ferrin, Mamak Khadem,Ross Daly, Åsa & Esbjörn Hogmark, Νίκος Μαμαγκάκης,Ross Daly, Κέλυ Θωμά,Μαρία Κώτη,Mάρθα Μαυροειδή,Χάρης Λαμπράκης ,Σπύρος Γκούμας,Mamak Khadem, Veronica Doubleday, John Bailey, Johanna Bölja, Vicki Swan.</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=al0fTcJR0GPX0AdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <p>Είναι επαγγελματίας μουσικός από το 2000 με εμφανίσεις σε μουσικές σκηνές, παμπ & συναυλίες καθώς  διδάσκει πιάνο, ρεμπέτικα ,παραδοσιακή Ελληνική και Β.Ευρωπαϊκή μουσική ,τραγουδιστικό στυλ και θεωρητικά στην Ιρλανδία, Σκωτία, Σουηδία, Νορβηγία,Γερμανία και Ελλάδα .</p>
              <p>Έχει εμφανιστεί στο Μουσείο της Ακρόπολης (projects με την Ιρλανδική Πρεσβεία), στο Μουσείο Κυκλάδων (Ιρλανδική Πρεσβεία), στην ΕΡΤ, στο Φεστιβάλ Κέλτικης Μουσικής Αθηνών, στο Φεστιβάλ, στο Athens Irish Festival, στο Irish Wings και στο Φεστιβάλ Παξών,Μεσαιωνικό Φεστιβάλ Ρόδου , καθώς και σε διάφορες μουσικές σκηνές στην Ελλάδα, στην Ιρλανδία (IWAMD, Bewley's Cafe Theater), στη Σουηδία (Esi Tobo) και στη Σκωτία (Cowdray Hall, Blue Lamp) .</p>
              <p>Είναι πρωτοπόρος στην προώθηση του ιρλανδικού,σκωτσέζικου, και σκανδιναβικού παραδοσιακού τραγουδιού στην Ελλάδα με σεμινάρια από το 2010, ιδρύοντας και διευθύνοντας την Curfa Gaelic Nordic Choir με μαθητές από τα σεμινάρια από το 2017. </p>
              <p>Η Gioti Andersson προωθεί τον ιρλανδικό και κέλτικο πολιτισμό από το 2006 με συναυλίες και δρώμενα σε συνεργασία με την Ιρλανδική Πρεσβεία, τον οργανισμό Culture Ireland και το Greek Irish Society  . </p>
              <p>Είναι ένας από τους ιδρυτές του Φεστιβάλ Κέλτικης Μουσικής της Αθήνας (2014-2019 ).

              </p>
              <p>Το ITMA (Irish Traditional Music Archive) την κατέγραψε στα αρχεία του ως την πρώτη Ελληνίδα που τραγούδησε στην ιρλανδική (γαελική) γλώσσα, Sean-nós .</p>
              <p>Έχει τραγουδήσει Sean-nós μπροστά στον Ιρλανδό Πρόεδρο , Michael D. Higgins, στην Αθήνα. 

              </p>
              <p>Το 2022 ερμήνευσε ελληνική μουσική για το βραβευμένο ντοκιμαντέρ "An Buachaill Gealghaireach" , το Γελαστό Παιδί, με δύο διάσημους Ιρλανδούς παραδοσιακούς μουσικούς, τον Liam O' Maonlai και τον Dave Power .</p>
              <p>Είναι ένα από τα ιδρυτικά μέλη του συγκροτήματος Sòlastas Athens Celtic Music Festival, Gagarin, Τριανόν,Ιανός ,Athens Irish Festival,Μεσαιωνικό Φεστιβάλ Ρόδου ,Ινστιτούτο Γκέτε ,Ελληνοαμερικανική Ένωση και διάφορα projects με την Ιρλανδική Πρεσβεία ).</p>
              <p>Από το 2017-2021 ξεκίνησε το project Nyckelharpa Greece , έχοντας φέρει την πρώτη nyckelharpa από τη Σουηδία προωθώντας τη σκανδιναβική μουσική στην Ελλάδα με το συγκρότημά της Sòlastas . </p>
              <p>Τον Οκτώβριο του 2019, έγινε δεκτή στη Διαπολιτισμική Ορχήστρα της Εναλλακτικής Σκηνής της Εθνικής Λυρικής Σκηνής.</p>`,
          },
          {
            name: 'Ανέστης Μπαρμπάτσης',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'anestis.png',
            youtube: 'https://www.youtube.com/@anestisbarbatsis8988',
            spotify: '',
          },
          {
            name: 'Ιάσονας Καλόγηρος',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'iason.jpeg',
            youtube: 'https://www.youtube.com/watch?v=nRoZk_nlmH4',
            spotify: '',
          },
        ],
        description: `
        <p>Το μουσικό ταξίδι θα συνεχιστεί το Σαββατο 24 Φεβ. στο Bavul, όπου οι επισκέπτες μουσικοί, Ανέστης Μπαρμπάτσης (φωνή, μπουζούκι), Λαμπρινή Astrid Γιώτη Andersson (φωνή) και Ιάσων Καλόγηρος (φωνή, μπουζούκι), μαζί με τους Βερολινέζους μουσικούς μας Ντίνο Μπουζάνη (κιθάρα), Sanne Möricke (ακορντεόν) και Θοδωρή (κοντραμπάσο) θα παίξουν ζωντανά τραγούδια από τα φημισμένα ντουέτα του Χιώτη-Χασκήλ και Τσιτσάνη-Νίνου αλλά και άλλα γνωστά ρεμπέτικα τραγούδια.
        `,
        eventType: 'Συναυλία',
        video: '',
        time: '19:00',
        doorsOpen: '18:30',
        entrance: 12,
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'BAVUL - Kunst & Kultur Café',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '3',
        startDate: '2024-02-25T12:00:00.349Z',
        endDate: '2024-02-25T16:00:00.349Z',
        title: 'Workshop στη ρεμπετικη φωνη / τραγουδι',
        poster: '',
        artists: [
          {
            name: 'Λαμπρινή Astrid Γιώτη Andersson',
            speciality: 'Φωνή',
            image: 'labrini.jpg',
            youtube: 'https://www.youtube.com/@LabriGiottoofficial',
            spotify: '',
            description: `<h2>Λαμπρινή Astrid Γιώτη Andersson </h2>
              <p>Η Λαμπρινή Astrid Γιώτη Andersson είναι Ελληνίδα ερμηνεύτρια, δασκάλα, ραδιοφωνική παραγωγός και εθνομουσικολόγος.</p>
              <p>Έχει αποφοιτήσει από το Μουσικό Γυμνάσιο Παλλήνης, κατέχει δίπλωμα κλασικού πιάνου από το Εθνικό Ωδείο Αθηνών , πτυχίο μουσικών σπουδών και μεταπτυχιακό τίτλο σπουδών στην εθνομουσικολογία από την Irish World Academy of Music & Dance (University of Limerick) με  υποτροφία από το Attica Tradition Foundation. </p> 
              <p>Ξεκίνησε την ενασχόλησή της με την Κέλτικη μουσική όταν ήταν 13 ετών και το 2006 έκανε την  μεταπτυχιακή της διατριβή με θέμα: "Sean-Nós singing style from the West Cork Gaeltacht area", όπου επικεντρώθηκε στο τραγουδιστικό στυλ των Eilís Ní Shúilleabháin, Iarla Ó Lionáird & Elisabeth Cronin.</p>
              <p>Κατά την περίοδο  2007& 2008,δίδαξε βυζαντινή,πολυφωνικό Ηπειρώτικο τραγούδι και ρεμπέτικο σε μορφή σεμιναρίων στο Irish World Academy of Music and Dance,Πανεπιστήμιο του Limerick.</p>
              <p>Έχει διδαχθεί παραδοσιακή μουσική & διαφορετικά στυλ από τους Elaine Cormican, Eilís Ní Shúilleabháin, Deirdre Scanlon, Mícheál Ó Súilleabháin ,Muireann Nic Amhlaoibh, Bobby Mc Ferrin, Mamak Khadem,Ross Daly, Åsa & Esbjörn Hogmark, Νίκος Μαμαγκάκης,Ross Daly, Κέλυ Θωμά,Μαρία Κώτη,Mάρθα Μαυροειδή,Χάρης Λαμπράκης ,Σπύρος Γκούμας,Mamak Khadem, Veronica Doubleday, John Bailey, Johanna Bölja, Vicki Swan.</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=al0fTcJR0GPX0AdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <p>Είναι επαγγελματίας μουσικός από το 2000 με εμφανίσεις σε μουσικές σκηνές, παμπ & συναυλίες καθώς  διδάσκει πιάνο, ρεμπέτικα ,παραδοσιακή Ελληνική και Β.Ευρωπαϊκή μουσική ,τραγουδιστικό στυλ και θεωρητικά στην Ιρλανδία, Σκωτία, Σουηδία, Νορβηγία,Γερμανία και Ελλάδα .</p>
              <p>Έχει εμφανιστεί στο Μουσείο της Ακρόπολης (projects με την Ιρλανδική Πρεσβεία), στο Μουσείο Κυκλάδων (Ιρλανδική Πρεσβεία), στην ΕΡΤ, στο Φεστιβάλ Κέλτικης Μουσικής Αθηνών, στο Φεστιβάλ, στο Athens Irish Festival, στο Irish Wings και στο Φεστιβάλ Παξών,Μεσαιωνικό Φεστιβάλ Ρόδου , καθώς και σε διάφορες μουσικές σκηνές στην Ελλάδα, στην Ιρλανδία (IWAMD, Bewley's Cafe Theater), στη Σουηδία (Esi Tobo) και στη Σκωτία (Cowdray Hall, Blue Lamp) .</p>
              <p>Είναι πρωτοπόρος στην προώθηση του ιρλανδικού,σκωτσέζικου, και σκανδιναβικού παραδοσιακού τραγουδιού στην Ελλάδα με σεμινάρια από το 2010, ιδρύοντας και διευθύνοντας την Curfa Gaelic Nordic Choir με μαθητές από τα σεμινάρια από το 2017. </p>
              <p>Η Gioti Andersson προωθεί τον ιρλανδικό και κέλτικο πολιτισμό από το 2006 με συναυλίες και δρώμενα σε συνεργασία με την Ιρλανδική Πρεσβεία, τον οργανισμό Culture Ireland και το Greek Irish Society  . </p>
              <p>Είναι ένας από τους ιδρυτές του Φεστιβάλ Κέλτικης Μουσικής της Αθήνας (2014-2019 ).

              </p>
              <p>Το ITMA (Irish Traditional Music Archive) την κατέγραψε στα αρχεία του ως την πρώτη Ελληνίδα που τραγούδησε στην ιρλανδική (γαελική) γλώσσα, Sean-nós .</p>
              <p>Έχει τραγουδήσει Sean-nós μπροστά στον Ιρλανδό Πρόεδρο , Michael D. Higgins, στην Αθήνα. 

              </p>
              <p>Το 2022 ερμήνευσε ελληνική μουσική για το βραβευμένο ντοκιμαντέρ "An Buachaill Gealghaireach" , το Γελαστό Παιδί, με δύο διάσημους Ιρλανδούς παραδοσιακούς μουσικούς, τον Liam O' Maonlai και τον Dave Power .</p>
              <p>Είναι ένα από τα ιδρυτικά μέλη του συγκροτήματος Sòlastas Athens Celtic Music Festival, Gagarin, Τριανόν,Ιανός ,Athens Irish Festival,Μεσαιωνικό Φεστιβάλ Ρόδου ,Ινστιτούτο Γκέτε ,Ελληνοαμερικανική Ένωση και διάφορα projects με την Ιρλανδική Πρεσβεία ).</p>
              <p>Από το 2017-2021 ξεκίνησε το project Nyckelharpa Greece , έχοντας φέρει την πρώτη nyckelharpa από τη Σουηδία προωθώντας τη σκανδιναβική μουσική στην Ελλάδα με το συγκρότημά της Sòlastas . </p>
              <p>Τον Οκτώβριο του 2019, έγινε δεκτή στη Διαπολιτισμική Ορχήστρα της Εναλλακτικής Σκηνής της Εθνικής Λυρικής Σκηνής.</p>`,
          },
        ],
        description: `    
          <h3>ΕΙΣΑΓΩΓΗ ΣΤΟ ΤΡΑΓΟΥΔΙΣΤΙΚΟ ΣΤΥΛ ΤΗΣ ΜΑΡΙΚΑΣ ΝΙΝΟΥ ΚΑΙ ΤΗΣ ΣΤΕΛΑ ΧΑΣΚΙΛ</h3>
          <p>Με την εθνομουσικολογο Λαμπρινή Astrid Γιώτη Andersson</p>
          
          <p>Ένα 4ωρο βιωματικό εργαστήριο που απευθύνεται σε όσους ενδιαφέρονται για μια εισαγωγή στα ρεμπέτικα τραγούδια, με επίκεντρο τις φωνές της Μαρίκας Νίνου και της Στέλλας Χάσκιλ. Θα εξερευνήσουμε το στυλ τραγουδιού (τεχνική, φρασεολογία κλπ.) μέσα από μερικά τραγούδια από το τεράστιο ρεπερτόριο τους.</p>
          <p>Δεν χρειάζεται να έχεις προηγούμενη εμπειρία στο τραγούδι ή γνώσεις μουσικής. Η γλώσσα της διδασκαλίας είναι η αγγλική, αλλά τα τραγούδια είναι στα ελληνικά (Συμπεριλαμβάνεται μετάφραση με λεπτομερή προφορά). Επίσης, παρέχεται βεβαίωση παρακολούθησης. </p>
          <p><b>⚠️ Σημαντική πληροφορία ⚠️</b><br>
          Για την συμμετοχή στο εργαστήριο, είναι <b>απαραίτητη</b> η δήλωση συμμετοχής με ένα e-mail στο <b>provokatsia.project@gmail.com το αργότερο μέχρι 23 Φεβρουαρίου</b>
          </p>
          <p>Το εργαστήριο με λεπτομέρειες:</p>
          <ul>
          <li>Φωνητική προθέρμανση 20 λεπτά</li>
          
          <li>Μικρή διάλεξη 20 λεπτά </li>
          
          <li>Διάλειμμα 10 λεπτών </li>
          
          <li>Ρεπερτόριο 60 λεπτά </li>
          
          <li>Διάλειμμα 10 λεπτά</li>
          
          <li>Ρεπερτόριο 60 λεπτά </li>
          
          <li>Διάλειμμα 10 λεπτά</li>
          
          <li>Ρεπερτόριο 50 λεπτά</li>
          </ul>
          <br />
          <h3>Λαμπρινή Astrid Γιώτη Andersson</h3>
          <p><iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=gCnKlfksYzHSPsp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>`,
        eventType: 'Workshop',
        video: '',
        time: '12:00',
        doorsOpen: '11:30',
        entrance: 50,
        googleLocation:
          'https://www.google.com/maps/place/Cafe+Karanfil/@52.4799811,13.4228092,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84fbee9fa6ebf:0xb129429743ec5b46!8m2!3d52.4799811!4d13.4228092!16s%2Fg%2F11gff6v8_m?entry=ttu',
        locationName: 'Cafe Karanfil',
        locationAddress: 'Weisestraße 3, 12049 Berlin',
      },
    ],
    supplementary: `<h2>Ρεμπετικα - τα Ελληνικα Blues</h2>
        <p><i>"Ανάλογες δημιουργίες με αυτές του ρεμπέτικου παρατηρούνται στην Αμερική με το Βlues στην Βραζιλία με την Samba και στην Τζαμάικα με την Reggae."</i></p>
        <p><i>"Το ρεμπέτικο είναι λαικό αστικό τραγούδι που οι ρίζες του ανάγονται στην βυζαντινή μουσική και στο Ελληνικό δημοτικό τραγούδι."</i></p>
        <p><i>"Ρεμπέτης: Ατίθασος, εξεγερμένος, ανεξάρτητος."</i></p>
        <p><i>"Τα ρεμπέτικα είναι τραγούδια της καρδιάς και μόνον όποιος τα πλησιάσει με αγνό αίσθημα τα νοιώθει και τα χαίρεται."</i></p>
        <p><i>"Το ρεμπέτικο κατορθώνει με θαυμαστή ενότητα να συνδυάζει τον λόγο, την μουσική και την κίνηση."</i></p>
        <p><i>"Απο την σύνθεση μέχρι την εκτέλεση, με ένστικτο δημιουργούνται οι προυποθέσεις για την τριπλή αυτή συνύπαρξη, που ορισμένες φορές σαν φτάνει την τελειότητα, θυμίζει αρχαία τραγωδία."</i></p>
        <p>Απόσπασμα απο το βιβλίο του Ηλία Πετρόπουλου</p>
        <p><i>"Το ρεμπέτικο τραγούδι είναι γνήσια ελληνικό, μοναδικό ελληνικό."</i></p>
        <p>Μάνος Χατζιδάκις</p>
      `,
  },
  {
    id: '222',
    url: 'fikir-amlak-4dub-vibes-soundsystem',
    title: 'Fikir Amlak & 4 dub vibes soundsystem',
    description: `<p>Με μεγάλη χαρά υποστηρίζουμε τους <a href="https://www.facebook.com/profile.php?id=100060822286025" target="_blank">4dub Vibes Soundsystem</a> στο ερχόμενο event τους, όπου προσκαλούν από το Los Angeles, CA to  Fikir Amlak για πρώτη φορά στην Αθήνα, Ελλάδα σε ένα μοναδικό heavy bass session, με το χειροποίητο soundsystem των 4dub Vibes!</p>
    <p>Ο Fikir Amlak ενεργός εδώ και 20 χρόνια στην reggae σκηνή ως mc (τραγουδιστής) / συνθέτης, πολυοργανίστας και παραγωγός, ο οποίος τα τελευταία 8 χρόνια έχει κυκλοφορίσει τη μουσική του σε διάφορες διεθνείς εταιρείες. Από το 2014 έχει εμφανιστεί αρκετές φορές στην ευρωπαΐκή σκηνή του “sound system” ως mc, με αρκετές συνεργασίες όπως αυτή με το King Alpha Sound System από UK με την κυκλοφορία ενός άλμπουμ μέσω του label Akashic Records Reggae, όπως επίσης και με τον παραγωγό Brizion από το San Diego. Μαζί με αρκετά βινύλια, άλμπουμ και singles που θα κυκλοφορήσουν τα επόμενα χρόνια, ο Fikir Amlak ξεκίνησε πρόσφατα τπ δικό του music label Tikur Anbessa Records, με έδρα το Los Angeles, CA με έμφαση σε περιορισμένες κυκλοφορίες βινυλίου.</p>
    <p>Σε όλους τους dub lovers, ετοιμαστείτε για ένα event όπου θα βάλει φωτιά την πόλη της Αθήνας με τα serious power vibes του Fikir Amlak και τον υψηλής ποιότητας ήχο των 4dub Vibes Soundsystem!</p>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/E6LpIFf6TaY?si=NLbMKgCXW29fOL9p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>`,
    poster: 'fikir-amlak-4dub-vibes-soundsystem.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2024-02-17T21:00:00.349Z',
        endDate: '2024-02-18T04:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '23:00 GR time',
        doorsOpen: '21:00',
        entrance: 10,
        googleLocation:
          'https://www.google.com/maps/place/Red+Sea+Reggae+House/@37.9777233,23.7564017,15z/data=!4m6!3m5!1s0x14a1bd50a7a390f7:0x312db5e7485e9e9e!8m2!3d37.9777233!4d23.7564017!16s%2Fg%2F1tht93zf?entry=ttu',
        locationName: 'Red Sea Reggae House | Athens',
        locationAddress: 'Ilia Potamianou 23, Athina 115 28, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '0',
    url: 'provokatsia-on-the-road',
    title: 'Provokatsia on the road! Mini tour in Greece',
    description: `<h3>Provokatsia on the road!</h3>
    <p>Οι Mr. Flavor Mav & Mr. Richter Scale Madness (aka Kleissonic) έχουν πακετάρει τα vibes τους και πιάσανε τους δρόμους για ένα mini tour. Τρεις μέρες τρεις πόλεις Αγρίνιο | Λειβαδιά | Λάρισα, παρουσιάζοντας dj series & guitar solo performances. Πιο συγκεκριμένα ο Richter Scale Madness σε ένα σόλο περφόρμανς με κιθάρα με psych heavy tunes και ο space rocker απο Βερολίνο Flavor Mav σε ενα dj series session.</p>
    <p>We highly recommend to not miss that one!</p>`,
    poster: 'provokatsia-on-the-road.jpg',
    tip: '',
    isSuggested: false,
    displayArtistList: true,
    hideMoreDetails: true,
    days: [
      {
        id: '1',
        startDate: '2024-01-05T19:00:00.349Z',
        endDate: '2024-01-13T04:00:00.349Z',
        title: '1st stop @ Akrovatis | Agrinio',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Karaiskaki+16,+Agrinio+301+31,+Greece/@38.6261395,21.4034472,17z/data=!3m1!4b1!4m10!1m2!2m1!1s16+Karaiskaki+Georgiou+Street,+30131+Agrinio!3m6!1s0x135e8a7438436d09:0x85d948583c0a19ed!8m2!3d38.6261396!4d21.4083235!15sCiwxNiBLYXJhaXNrYWtpIEdlb3JnaW91IFN0cmVldCwgMzAxMzEgQWdyaW5pb5IBEGdlb2NvZGVkX2FkZHJlc3PgAQA!16s%2Fg%2F11rpth7973?entry=ttu',
        locationName: 'Akrovatis',
        locationAddress: 'Karaiskaki 16, Agrinio 301 31, Greece',
      },
      {
        id: '2',
        startDate: '2024-01-12T19:00:00.349Z',
        endDate: '2024-01-13T04:00:00.349Z',
        title: '2nd stop @ TAF | Livadia',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/TAF/@38.4361695,22.8749872,15z/data=!4m6!3m5!1s0x14a08fae761d808f:0x703050862564528c!8m2!3d38.4361695!4d22.8749872!16s%2Fg%2F11nn45r4sj?entry=ttu',
        locationName: 'TAF',
        locationAddress: 'I. Lappa 9, Livadia 321 00, Greece',
      },
      {
        id: '3',
        startDate: '2024-01-13T19:00:00.349Z',
        endDate: '2024-01-14T04:00:00.349Z',
        title: '3nd stop @ Wise Monkey | Larissa',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Wise+Monkey+-+Urban+Vibes/@39.6350767,22.4156713,17z/data=!3m1!4b1!4m6!3m5!1s0x135889ad4c78baad:0x1630f15d80848839!8m2!3d39.6350767!4d22.4182516!16s%2Fg%2F11jgsxpk47?entry=ttu',
        locationName: 'Wise Monkey - Urban Vibes',
        locationAddress: 'Asklipiou 38, Larisa 412 22, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '22',
    url: 'laiko-rempetiko-glenti',
    title: 'Λαϊκό Ρεμπετικο Γλεντι | Αγρινιο',
    description: `<p>Οι Provokatsia Project σας προσκαλούν σε ενα λαϊκό και ρεμπέτικο γλέντι στο New York Pallace στο Αγρίνιο, όπου οι ταλαντούχοι μουσικοί Ντίνος Μπουζάνης στην κιθάρα, Στράτος Σουβατζής και Μαρία Βακάλη φωνή, Ορφέας Τσακτσίρας στο μπουζούκι και Ανδρέας Κοτσαρίνης στο ακορντεόν, θα μας οδηγήσουν πίσω στο χρόνο με γνωστά και άγνωστα τραγούδια του Λαϊκού και ρεμπέτικου τραγουδιού! Μην χάσετε ένα μοναδικό βράδυ μουσικής και πολιτισμού!</p>
    <br />
    <b>Η Μαρία Βακάλη στην τηλεοπτική εκπομπή "Ό,τι αγαπώ</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/t_TywytZvdw?si=JHGk-IfjaXOV7wSO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <br />
    <b>Στράτος Σουβατζής φωνή, Ντίνος Μπουζάνης κιθάρα, Ζιγκουάλα</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/Np4XpZYPygs?si=__RLlqbMHwS0ex5Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <br />
    <b>Θα κάνω ντου βρε πονηρή - Ορφέας Τσακτσίρας</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/4fUozYahKcQ?si=jCCpCLZczPBtX0ym" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    `,
    poster: 'laiko-rempetiko-glenti.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-01-07T13:00:00.349Z',
        endDate: '2024-01-07T22:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Ντίνος Μπουζάνης',
            speciality: 'Kιθάρα',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Στράτος Σουβατζής',
            speciality: 'Φωνή',
            image: 'stratos.jpg',
            youtube:
              'https://www.youtube.com/playlist?list=PLBcA1hS7Mwmd0Ct_CygblH7H7JFGy11zv',
            spotify: '',
          },
          {
            name: 'Μαρία Βακάλη',
            speciality: 'Φωνή',
            image: 'maria.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Ορφέας Τσακτσίρας',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
          {
            name: 'Aτρέας Κοτσαρίνης',
            speciality: 'Ακορντεόν',
            image: 'andreas.jpg',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '15:00',
        doorsOpen: '14:00',
        entrance: 10,
        googleLocation:
          'https://www.google.com/maps/place/New+York+Cafe-Restaurant/@38.6722153,21.3571619,17z/data=!3m1!4b1!4m6!3m5!1s0x135e8998997bf2bd:0x754675674d893576!8m2!3d38.6722111!4d21.3597422!16s%2Fg%2F11j8x247hz?entry=ttu',
        locationName: 'New York Pallace',
        locationAddress: 'EO Agriniou Artas, Agrinio 301 31, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '1',
    url: 'epirus-unter-den-platanen',
    title: 'Τριημερο φεστιβαλ Ηπειρωτικης μουσικης',
    description: `<p>Σας προσκαλούμε να ζήσετε τους πλούσιους και μοναδικός ήχους της Ηπειρώτικης μουσικής σε ένα τριήμερο 
    φεστιβάλ στο Βερολίνο!</p>
    
    <p>Με ένα εργαστήριο 6 ωρών, ένα ταξίδι μέσα από την ιστορία και τον πολιτισμό της Ηπειρώτικης μουσικής, 
    το οποίο θα ακολουθήσουν δύο ημέρες με ζωντανή μουσική από πέντε πρωτοπόρους μουσικούς που μας επισκέπτονται 
    από την Ελλάδα για να μας οδηγήσουν σε ένα παραδοσιακό ηπειρωτικό γλέντι!</p>
    
    <p>Στο φεστιβάλ ο Χρήστο Τζιτζιμίκας με τη μοναδική του φωνή, 
    ο οποίος κυκλοφόρησε το δεύτερο άλμπουμ του “Πατήματα” με τον θρυλικό Πετρολούκα Χαλκιά 
    στο κλαρίνο και έχει εμφανιστεί με άλλους θρύλους όπως ο Χρήστος Ζώτος, μαζί με την υπόλοιπη παρέα του, τον Αντώνη Καλιούρη στο κλαρίνο, τον Γιάννη Πούλιο στο βιολί, 
    τον Παναγιώτη Τσάκο στην κιθάρα και το λαούτο, και τον Πέτρο Παπαγεωργίου στα κρουστά, θα δημιουργήσουν μια μαγευτική ατμόσφαιρα, 
    μεταφέροντάς μας στην Ηπειρο, σε ένα ξεχωριστό ταξίδι, χαρίζοντας μας μία αξέχαστη εμπειρία!</p>
    
    <p>Μην χάσετε αυτήν τη μοναδική ευκαιρία να ανακαλύψετε τη μαγεία της Ηπειρώτικης μουσικής!</p>
    <br>
      <b>Χρήστος Τζιτζιμίκας και Πετρολούκας Χαλκιάς</b>
      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/X9OGj5X376U?si=C3LP0yjh8rWw_EIX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
      
      <b>Πέτρος Παπαγεωργίου και Πετρολούκας Χαλκιάς</b>
      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/G-h2fOKdyLE?si=NvanRN3ZkDTq9Tld" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p><br>`,
    poster: 'epirus-unter-den-platanen.jpg',
    tip: '',
    isSuggested: false,
    displayArtistList: true,
    days: [
      {
        id: '1',
        startDate: '2024-01-26T14:00:00.349Z',
        endDate: '2024-01-28T22:59:00.349Z',
        title: 'Ένα 6ώρο βιωματικό εργαστήρι + συναυλία διάρκειας 45 λεπτών',
        poster: '',
        artists: [
          {
            name: 'Χρήστος Τζιτζιμίκας',
            speciality: 'Τραγούδι',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Χρήστος Τζιτζιμίκας</h2>
              <p>Προερχόμενος από οικογένεια τραγουδιστών της λαϊκής παράδοσης, βίωσε από μικρός το δημοτικό τραγούδι. Από το 1983 ασχολείται με την έρευνα και απόδοση του δημοτικού τραγουδιού της Ηπείρου, Μακεδονίας, Θεσσαλίας και Στερεάς Ελλάδας. 
              <p>Σπουδάζει βυζαντινή μουσική στο Ωδείο Σκαλκώτα με το Λυκούργο Αγγελόπουλο, φωνητική με τη Lucia Fracea και ολοκληρώνει τις σπουδές του στο Τ.Ε.Φ.Α.Α. Αθηνών.</p> 

              <p>Οι πρώτες του εμφανίσεις ήταν με το μουσικό συγκρότημα «ΤΕΡΙΡΕΜ». Από τότε, έχει συνεργαστεί με πολλούς παλιούς και νέους αξιόλογους μουσικούς, κάνοντας εμφανίσεις σε όλη την Ελλάδα και το εξωτερικό σε συναυλίες και θεατρικές παραστάσεις. </p>

              <p>Το 1993 κυκλοφορεί από τις εκδόσεις ίαμβος η πρώτη του δισκογραφική δουλειά με τίτλο «Ζαγορίσια και Γιαννιώτικα». Με τη συμμετοχή του Γρηγόρη Καψάλη στο κλαρίνο, δέκα αξιόλογων μουσικών και τη φιλική συμμετοχή του «ΤΕΡΙΡΕΜ». Την ηχοληψία κάνει ο Γιώργος Συγλέτος και την διεύθυνση ορχήστρας έχει ο Γιώργος Παπαδάκης. Συνοδεύεται από ένθετο (ελληνικά και αγγλικά) με πολλές πληροφορίες και φωτογραφίες. </p>

              <p>Τραγούδι του δίσκου Ζαγορίσια και Γιαννιώτικα χρησιμοποιήθηκε στην ταινία «Αθήνα -- Κωνσταντινούπολη» του Νίκου Παναγιωτόπουλου. «Πατήματα» είναι ο τίτλος της δεύτερης δισκογραφικής δουλειάς που κυκλοφόρησε από τις εκδόσεις ίαμβος το 2001. Περιλαμβάνει 16 τραγούδια με σημείο αναφοράς το Πωγώνι της Ηπείρου. Συμμετέχουν ο Πετρολούκας Χαλκιάς (κλαρίνο), ο Χριστόδουλος Ζούμπας (λαούτο, μουσική επιμέλεια) και τέσσερις ακόμα αξιόλογοι μουσικοί. Η έκδοση έχει τη μορφή βιβλίου με εκτεταμένα ιστορικά και μουσικολογικά σχόλια (ελληνικά και αγγλικά) και φωτογραφικό υλικό. </p>

              <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
              <p>Οι δυο δίσκοι αποσπούν ενθουσιώδεις κριτικές από το σύνολο του μουσικού και όχι μόνο τύπου και παρουσιάζονται ζωντανά σε πόλεις της Ελλάδας και του εξωτερικού. </p>
              <p>Το 2001 συμμετέχει στη δισκογραφική έκδοση με τίτλο «Θεσσαλία», το 2015 στο cd του Σταμάτη Λαλλά “Από καρδιάς Αγιαθυμιά”, το 2019 στο cd του Βασίλη Τριάντη “Πορτολάνος”. </p>
              <p>Δίδαξε το παραδοσιακό τραγούδι στο τμήμα Ελληνικής Παραδοσιακής Μουσικής του ΤΕΙ Άρτας, στο τμήμα Μουσικής Επιστήμης και Τέχνης του Πανεπιστημίου Μακεδονίας, στο Μουσικό Χωριό, Άγιος Λαυρέντιος, σε σεμινάρια και σε κατ’ ιδίαν μαθήματα.</p>`,
          },
          {
            name: 'Αντώνης Καλιούρης',
            speciality: 'Κλαρίνο',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Αντώνης Καλιούρης</h2>
            <p>Γεννήθηκε στην Αθήνα, όπου και μεγάλωσε, με καταγωγή από το Ρέθυμνο Κρήτης. Είναι τελειόφοιτος του τμήματος Βιοτεχνολογίας του Γεωπονικού Πανεπιστημίου Αθηνών.</p>
            <p>Η ενασχόληση του με τη μουσική ξεκίνησε στην ηλικία των εννέα ετών, μαθαίνοντας λαούτο. Στην ηλικία των δώδεκα ετών ξεκίνησε να σπουδάζει παραδοσιακό κλαρίνο, με δάσκαλο τον Βαγγέλη Παπαναστασίου. Αργότερα, για τρία χρόνια σπούδασε κλασικό κλαρινέτο στο Αττικό Ωδείο, με καθηγητή τον Μανούσο Πλουμίδη. Το 2011 και για τέσσερα χρόνια παρακολούθησε μαθήματα παραδοσιακού κλαρίνου δίπλα στον δεξιοτέχνη Αλέξανδρο Αρκαδόπουλο. Παράλληλα παρακολουθεί μαθήματα φλάουτου στο Εθνικό Ωδείο, με καθηγήτρια τη Δανάη Κιουπούρογλου.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Από μικρή ηλικία συμμετέχει σε πολιτιστικές εκδηλώσεις καθώς και σε πανηγύρια, γάμους και χορευτικές εκδηλώσεις σε όλη την Ελλάδα.</p>
            <p>Έχει συμμετάσχει ως μουσικός σε συναυλίες, παραστάσεις, δισκογραφικά καθώς και σε ραδιοφωνικά και τηλεοπτικά προγράμματα με διάφορους καλλιτέχνες του λαϊκού και παραδοσιακού χώρου, όπως οι: Γιώργος Νταλάρας, Γλυκερία, Κώστας Μακεδόνας, Γιάννης Κότσιρας, Γεράσιμος Ανδρεάτος, Σοφία Παπάζογλου, Μπάμπης Τσέρτος, Λαυρέντης Μαχαιρίτσας, Γιάννης Λεμπέσης, Γιώτα Νέγκα, Μίλτος Πασχαλίδης, Νάντια Καραγιάννη, Καλλιόπη Βέττα, Χρήστος Δάντης, Γιώργος Μεράντζας, Ερωφίλη, Παναγιώτης Λάλεζας, Μάνος Κουτσαγγελίδης, Χρήστος Τζιτζιμίκας καθώς και με την ορχήστρα Εστουδιαντίνα Νέας Ιωνίας.</p>`,
          },
          {
            name: 'Γιάννης Πούλιος',
            speciality: 'Βιολί',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Γιάννης Πούλιος</h2>
            <p>Ο Γιάννης Πούλιος γεννήθηκε στο Bruchsal Γερμανίας και μεγάλωσε στην Κατερίνη.</p>
            <p>Μαθήτευσε στο μουσικό σχολείο Κατερίνης και μετέπειτα φοίτησε στο Τμήμα Λαϊκής και Παραδοσιακής Μουσικής στην Άρτα με δασκάλους τους: Γιώργο Ψάλτη και Μάκη Μπακλατζή καθώς επίσης και στo Yildiz Technical University στην Κωνσταντινούπολη (στα πλαίσια του προγράμματος Erasmus) για δύο εξάμηνα με δασκάλους τους Özer Özel, Nedim Nalbantoğlu και Murat Sakaryalı. </p>
            <p>Σήμερα έχει ενεργή συμμετοχή στον ελλαδικό μουσικό χώρο καθώς και στο εξωτερικό με συνεργασίες επί σκηνής αλλά και σε δισκογραφικό επίπεδο με διάφορα μουσικά σχήματα όπως: το «Tasos Poulios quartet», το «Σummation» και το “Hane” του Αποστόλου Σιδέρη, το σχήμα Ηλιοδρόμιο, το Lappino project του Δημήτρη Λάππα, και το σχήμα Ali Perret’s DU.DU στην Κωνσταντινούπολη. </p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Επίσης έχει συνεργαστεί με το σχήμα Ψυχή & Σώμα με τον Ματθαίο και Κώστα Τσαχουρίδη στο Μέγαρο Μουσικής Αθηνών, το σχήμα Λωξάντρα και με καλλιτέχνες όπως την Ελένη Τσαλιγοπούλου, την Νένα Βενετσάνου, τον Haig Yazdjan, τον Περικλή Παπαπετρόπουλο, την Ειρήνη Τορνεσάκη, την Αρετή Κετιμέ ,την Λιζέτα Καλημέρη, τον Αντώνη Απέργη, τον Μάνο Αχαλινωτόπουλο, τον Φώτη Σιώτα, τον Νίκο Παραουλάκη, τον ο Κωσταντίνο Πλούσιο κι άλλους ακόμη μουσικούς της ελληνικής μουσικής σκηνής. </p>
            <p>Επίσης, τα δύο τελευταία χρόνια εργάζεται στον χώρο της εκπαίδευσης ως αναπληρωτής καθηγητής παραδοσιακού βιολιού στο πειραματικό μουσικό γυμνάσιο Παλλήνης. </p>`,
          },
          {
            name: 'Παναγιώτης Τσάκος',
            speciality: 'Κιθάρα, Λαούτο',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Παναγιώτης Τσάκος</h2>
            <p>Ο Παναγιώτης Τσάκος γεννήθηκε και μεγάλωσε στην Ναύπακτο. Φοίτησε στο ΤΕΙ Λαϊκής και Παραδοσιακής Μουσικής στην Άρτα, με ειδικότητα στην λαϊκή κιθάρα. Δραστηριοποιείται στο χώρο της της παραδοσιακής δημοτικής μουσικης και όχι μόνο, από το 2001, με συνεργασίες και συμμετοχές με γνωστά ονόματα του χώρου.</p>`,
          },
          {
            name: 'Πέτρος Παπαγεωργίου',
            speciality: 'Κρουστά',
            image: 'papageorgiou.jpg',
            youtube: 'https://www.youtube.com/@Petrran',
            spotify: '',
            description: `<h2>Πέτρος Παπαγεωργίου</h2>
            <p>Ο Πέτρος Παπαγεωργίου είχε την πρώτη του επαφή με την μουσική σε νεαρή ηλικία με την εισαγωγή του στο δημόσιο ωδείο Ιωαννίνων. Μελέτησε τη θεωρία της δυτικής μουσικής και το αρμόνιο για 4 χρόνια. Έπειτα ανακαλύπτει και μελετά τα κρουστά με έμφαση στην Ελληνική παραδοσιακή μουσική. Παράλληλα ολοκληρώνει τις μεταπτυχιακές του σπουδές στο Τμήμα Μουσικών Σπουδών του Εθνικού Καποδιστριακού Πανεπιστημίου Αθηνών στο πρόγραμμα «Ερμηνεία Ενόργανης και Φωνητικής Μουσικής», κατεύθυνση «Εκτέλεση/ερμηνεία της Παραδοσιακής Μουσικής» με ειδίκευση στα παραδοσιακά κρουστά και συνεχίζει την προσωπική του εξερεύνηση στον χώρο των κρουστών δίπλα στον Βαγγέλη Καρίπη, Ηλία Διμάνο και Yshai Afterman.</p>
            <p>Από το 2003 είναι ενεργό μέλος του σχήματος Εν Χορδαίς. Συμμετέχει σε σεμινάρια με καταξιωμένους καλλιτέχνες των κρουστών όπως οι Misirli Ahmet, Bijan Cemirani, Ziya Tabassian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin καθώς και στο International Percussion Meeting (Tunis, 2004). Ως μέλος του σχήματος Εν Χορδαίς έχει βραβευτεί με το βραβείο "Prix France Musique des Musiques du Monde", Babel Med Music / Radio France (Marseille 2008) και ήταν υποψήφιος για το βραβείο της UNESCO “Sharjah Prize” for Arabic Culture (2006). Έχει συμμετάσχει σε περισσότερες από 300 συναυλίες σε πολλές χώρες ανά τον κόσμο, σε μεγάλα φεστιβάλ και διάσημους συναυλιακούς χώρους όπως: The Grace Rainey Rogers Auditorium of Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison Symphonic (Montreal), Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theatre de la Ville (Paris), Institut du Monde Arabe (Paris), Bibliotheca Alexandrina (Egypt), Carl-Orff Saal Gasteig (Munich), Silk Road Arts Festival (Hong Kong), Melbourne Recital Centre (Australia), Ten Days on the Island (Tasmania), Fès Festival (Morocco), Cemal Resit Rey Concert Hall (Istanbul), Onassis Culture Center (Athens), Cite de la Music (Marseille), The International Jerusalem Oud Festival (Israel), Athens Concert Hall, Thessaloniki Concert Hall, Les Suds à Arles Festival, Journées Musicales de Carthage (Tunis), Cairo Opera House, Tropenmuseum (Amsterdam), Gibraltar World Music Festival, Romanian Culture Institute (Bucharest) και σε πολλές άλλες πόλεις σε όλο τον κόσμο. </p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Ως δάσκαλος – επισκέπτης έχει συμμετάσχει σε σεμινάριο στην διάσημη μουσική ακαδημία Sibelius στο Ελσίνκι. </p>
            <p>Έχει λάβει σε δισκογραφικές παραγωγές και συνεργάζεται με πολλούς καταξιωμένους Έλληνες και ξένους μουσικούς και μουσικά συγκροτήματα όπως: Hellenic National Radio Television Orchestra, Ensemble Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saragoudas, Maria Farantouri, Grigoris Kapsalis, Nikos Filippidis, Haig Yazdjian, και πολλούς άλλους.</p>`,
          },
        ],
        description: `<p>
        Ένα 5ώρο βιωματικό εργαστήρι αφιέρωμα στην ηπειρώτικη μουσική από έμπειρους Έλληνες μουσικούς. Θα ακολουθήσει συναυλία διάρκειας 45 λεπτών με τίτλο “Κιντόι Μανέ”.   </p>
          <p>
          Στο βιωματικό εργαστήρι, οι ενδιαφερόμενοι θα έρθουν σε άμεση επαφή με τα παραδοσιακά όργανα της Ηπείρου αλλά και το τραγούδι. Σκοπός του είναι η κατανόηση από τους συμμετέχοντες των φυσικών χαρακτηριστικών και των μουσικών δυνατοτήτων των οργάνων και της φωνής.   </p><p>
          Στη διάρκεια του θα παρουσιαστούν τραγούδια από την Ήπειρο. Θα δοθεί η δυνατότητα να συζητηθούν: <br>
            - η σημασία της παραδοσιακής μουσικής<br>
            - οι σχέσεις και οι διαφορές με άλλα είδη μουσικής<br>
            - η μουσικολογική ταυτότητα και οι μουσικές δυνατότητες των βασικών παραδοσιακών οργάνων<br>
            - οι βασικές μουσικές παραδοσιακές κλίμακες<br>
            - οι τρόποι οργανικής και φωνητικής εκτέλεσης των παραδοσιακών τραγουδιών.
          </p>
          <p>
          Κιντόι μανέ! Ένα μουσικό πρόγραμμα διανθισμένο με μουσικές και τραγούδια από Ήπειρο, την ευρύτερη περιοχή της Πίνδου και το Ξηρόμερο με κοινό χαρακτηριστικό την ανατολίτικη καταγωγή τους. Τραγούδια που δημιουργήθηκαν κατά την περίοδο της Οθωμανικής Αυτοκρατορίας, αλλά και νεώτερα, από την εποχή των Καφέ Αμάν που διασκευάστηκαν από δημοτικούς μουσικούς.
          Τραγούδια που η δημιουργία τους χάνεται στο πέρασμα του χρόνου αλλά έχουν θέση μέχρι σήμερα στα γλέντια και στη δισκογραφία. Κάποια από αυτά τα γνωρίσαμε από το διπλό LP “Ζαγορίσια και Γιαννιώτικα”, τη πρώτη δισκογραφική δουλειά του Χρήστου Τζιτζιμίκα (εκδ. Ίαμβος 1993).
          </p>
          <p><b class="warning">Σημαντική πληροφορία:</b> Απαραίτητη προϋπόθεση για την διεξαγωγή του work shop είναι η κάλυψη του ελάχιστου αριθμού συμμετοχόντων που είναι 20 άτομα. Στην περίπτωση που η προϋπόθεση αυτή δεν επιτευχθεί, το work shop θα ακυρωθεί και η τιμή του εισιτηρίου θα σας επιστραφεί στο απόλυτο. Στην περίπτωση αυτή θα λάβετε επίσης ένα ενημερωτικό e-mail για την ακύρωση του work shop.</p>
        `,
        eventType: 'Workshop & Συναυλία',
        video: '',
        time: 'Workshop: 15:00 | Συναυλία: 21:00',
        doorsOpen: '15:00',
        entrance: 'Workshop: 65€/55€ | Συναυλία: Ελεύθερη είσοδος',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,15z/data=!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'Bavul',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '2',
        startDate: '2024-01-27T20:00:00.349Z',
        endDate: '2024-01-28T05:00:00.349Z',
        title: 'Συναυλία με τον Χρήστο Τζιτζιμίκα και την παρέα του',
        poster: '',
        artists: [
          {
            name: 'Χρήστος Τζιτζιμίκας',
            speciality: 'Τραγούδι',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Χρήστος Τζιτζιμίκας</h2>
              <p>Προερχόμενος από οικογένεια τραγουδιστών της λαϊκής παράδοσης, βίωσε από μικρός το δημοτικό τραγούδι. Από το 1983 ασχολείται με την έρευνα και απόδοση του δημοτικού τραγουδιού της Ηπείρου, Μακεδονίας, Θεσσαλίας και Στερεάς Ελλάδας. 
              <p>Σπουδάζει βυζαντινή μουσική στο Ωδείο Σκαλκώτα με το Λυκούργο Αγγελόπουλο, φωνητική με τη Lucia Fracea και ολοκληρώνει τις σπουδές του στο Τ.Ε.Φ.Α.Α. Αθηνών.</p> 

              <p>Οι πρώτες του εμφανίσεις ήταν με το μουσικό συγκρότημα «ΤΕΡΙΡΕΜ». Από τότε, έχει συνεργαστεί με πολλούς παλιούς και νέους αξιόλογους μουσικούς, κάνοντας εμφανίσεις σε όλη την Ελλάδα και το εξωτερικό σε συναυλίες και θεατρικές παραστάσεις. </p>

              <p>Το 1993 κυκλοφορεί από τις εκδόσεις ίαμβος η πρώτη του δισκογραφική δουλειά με τίτλο «Ζαγορίσια και Γιαννιώτικα». Με τη συμμετοχή του Γρηγόρη Καψάλη στο κλαρίνο, δέκα αξιόλογων μουσικών και τη φιλική συμμετοχή του «ΤΕΡΙΡΕΜ». Την ηχοληψία κάνει ο Γιώργος Συγλέτος και την διεύθυνση ορχήστρας έχει ο Γιώργος Παπαδάκης. Συνοδεύεται από ένθετο (ελληνικά και αγγλικά) με πολλές πληροφορίες και φωτογραφίες. </p>

              <p>Τραγούδι του δίσκου Ζαγορίσια και Γιαννιώτικα χρησιμοποιήθηκε στην ταινία «Αθήνα -- Κωνσταντινούπολη» του Νίκου Παναγιωτόπουλου. «Πατήματα» είναι ο τίτλος της δεύτερης δισκογραφικής δουλειάς που κυκλοφόρησε από τις εκδόσεις ίαμβος το 2001. Περιλαμβάνει 16 τραγούδια με σημείο αναφοράς το Πωγώνι της Ηπείρου. Συμμετέχουν ο Πετρολούκας Χαλκιάς (κλαρίνο), ο Χριστόδουλος Ζούμπας (λαούτο, μουσική επιμέλεια) και τέσσερις ακόμα αξιόλογοι μουσικοί. Η έκδοση έχει τη μορφή βιβλίου με εκτεταμένα ιστορικά και μουσικολογικά σχόλια (ελληνικά και αγγλικά) και φωτογραφικό υλικό. </p>

              <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
              <p>Οι δυο δίσκοι αποσπούν ενθουσιώδεις κριτικές από το σύνολο του μουσικού και όχι μόνο τύπου και παρουσιάζονται ζωντανά σε πόλεις της Ελλάδας και του εξωτερικού. </p>
              <p>Το 2001 συμμετέχει στη δισκογραφική έκδοση με τίτλο «Θεσσαλία», το 2015 στο cd του Σταμάτη Λαλλά “Από καρδιάς Αγιαθυμιά”, το 2019 στο cd του Βασίλη Τριάντη “Πορτολάνος”. </p>
              <p>Δίδαξε το παραδοσιακό τραγούδι στο τμήμα Ελληνικής Παραδοσιακής Μουσικής του ΤΕΙ Άρτας, στο τμήμα Μουσικής Επιστήμης και Τέχνης του Πανεπιστημίου Μακεδονίας, στο Μουσικό Χωριό, Άγιος Λαυρέντιος, σε σεμινάρια και σε κατ’ ιδίαν μαθήματα.</p>`,
          },
          {
            name: 'Αντώνης Καλιούρης',
            speciality: 'Κλαρίνο',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Αντώνης Καλιούρης</h2>
            <p>Γεννήθηκε στην Αθήνα, όπου και μεγάλωσε, με καταγωγή από το Ρέθυμνο Κρήτης. Είναι τελειόφοιτος του τμήματος Βιοτεχνολογίας του Γεωπονικού Πανεπιστημίου Αθηνών.</p>
            <p>Η ενασχόληση του με τη μουσική ξεκίνησε στην ηλικία των εννέα ετών, μαθαίνοντας λαούτο. Στην ηλικία των δώδεκα ετών ξεκίνησε να σπουδάζει παραδοσιακό κλαρίνο, με δάσκαλο τον Βαγγέλη Παπαναστασίου. Αργότερα, για τρία χρόνια σπούδασε κλασικό κλαρινέτο στο Αττικό Ωδείο, με καθηγητή τον Μανούσο Πλουμίδη. Το 2011 και για τέσσερα χρόνια παρακολούθησε μαθήματα παραδοσιακού κλαρίνου δίπλα στον δεξιοτέχνη Αλέξανδρο Αρκαδόπουλο. Παράλληλα παρακολουθεί μαθήματα φλάουτου στο Εθνικό Ωδείο, με καθηγήτρια τη Δανάη Κιουπούρογλου.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Από μικρή ηλικία συμμετέχει σε πολιτιστικές εκδηλώσεις καθώς και σε πανηγύρια, γάμους και χορευτικές εκδηλώσεις σε όλη την Ελλάδα.</p>
            <p>Έχει συμμετάσχει ως μουσικός σε συναυλίες, παραστάσεις, δισκογραφικά καθώς και σε ραδιοφωνικά και τηλεοπτικά προγράμματα με διάφορους καλλιτέχνες του λαϊκού και παραδοσιακού χώρου, όπως οι: Γιώργος Νταλάρας, Γλυκερία, Κώστας Μακεδόνας, Γιάννης Κότσιρας, Γεράσιμος Ανδρεάτος, Σοφία Παπάζογλου, Μπάμπης Τσέρτος, Λαυρέντης Μαχαιρίτσας, Γιάννης Λεμπέσης, Γιώτα Νέγκα, Μίλτος Πασχαλίδης, Νάντια Καραγιάννη, Καλλιόπη Βέττα, Χρήστος Δάντης, Γιώργος Μεράντζας, Ερωφίλη, Παναγιώτης Λάλεζας, Μάνος Κουτσαγγελίδης, Χρήστος Τζιτζιμίκας καθώς και με την ορχήστρα Εστουδιαντίνα Νέας Ιωνίας.</p>`,
          },
          {
            name: 'Γιάννης Πούλιος',
            speciality: 'Βιολί',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Γιάννης Πούλιος</h2>
            <p>Ο Γιάννης Πούλιος γεννήθηκε στο Bruchsal Γερμανίας και μεγάλωσε στην Κατερίνη.</p>
            <p>Μαθήτευσε στο μουσικό σχολείο Κατερίνης και μετέπειτα φοίτησε στο Τμήμα Λαϊκής και Παραδοσιακής Μουσικής στην Άρτα με δασκάλους τους: Γιώργο Ψάλτη και Μάκη Μπακλατζή καθώς επίσης και στo Yildiz Technical University στην Κωνσταντινούπολη (στα πλαίσια του προγράμματος Erasmus) για δύο εξάμηνα με δασκάλους τους Özer Özel, Nedim Nalbantoğlu και Murat Sakaryalı. </p>
            <p>Σήμερα έχει ενεργή συμμετοχή στον ελλαδικό μουσικό χώρο καθώς και στο εξωτερικό με συνεργασίες επί σκηνής αλλά και σε δισκογραφικό επίπεδο με διάφορα μουσικά σχήματα όπως: το «Tasos Poulios quartet», το «Σummation» και το “Hane” του Αποστόλου Σιδέρη, το σχήμα Ηλιοδρόμιο, το Lappino project του Δημήτρη Λάππα, και το σχήμα Ali Perret’s DU.DU στην Κωνσταντινούπολη. </p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Επίσης έχει συνεργαστεί με το σχήμα Ψυχή & Σώμα με τον Ματθαίο και Κώστα Τσαχουρίδη στο Μέγαρο Μουσικής Αθηνών, το σχήμα Λωξάντρα και με καλλιτέχνες όπως την Ελένη Τσαλιγοπούλου, την Νένα Βενετσάνου, τον Haig Yazdjan, τον Περικλή Παπαπετρόπουλο, την Ειρήνη Τορνεσάκη, την Αρετή Κετιμέ ,την Λιζέτα Καλημέρη, τον Αντώνη Απέργη, τον Μάνο Αχαλινωτόπουλο, τον Φώτη Σιώτα, τον Νίκο Παραουλάκη, τον ο Κωσταντίνο Πλούσιο κι άλλους ακόμη μουσικούς της ελληνικής μουσικής σκηνής. </p>
            <p>Επίσης, τα δύο τελευταία χρόνια εργάζεται στον χώρο της εκπαίδευσης ως αναπληρωτής καθηγητής παραδοσιακού βιολιού στο πειραματικό μουσικό γυμνάσιο Παλλήνης. </p>`,
          },
          {
            name: 'Παναγιώτης Τσάκος',
            speciality: 'Κιθάρα, Λαούτο',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Παναγιώτης Τσάκος</h2>
            <p>Ο Παναγιώτης Τσάκος γεννήθηκε και μεγάλωσε στην Ναύπακτο. Φοίτησε στο ΤΕΙ Λαϊκής και Παραδοσιακής Μουσικής στην Άρτα, με ειδικότητα στην λαϊκή κιθάρα. Δραστηριοποιείται στο χώρο της της παραδοσιακής δημοτικής μουσικης και όχι μόνο, από το 2001, με συνεργασίες και συμμετοχές με γνωστά ονόματα του χώρου.</p>`,
          },
          {
            name: 'Πέτρος Παπαγεωργίου',
            speciality: 'Κρουστά',
            image: 'papageorgiou.jpg',
            youtube: 'https://www.youtube.com/@Petrran',
            spotify: '',
            description: `<h2>Πέτρος Παπαγεωργίου</h2>
            <p>Ο Πέτρος Παπαγεωργίου είχε την πρώτη του επαφή με την μουσική σε νεαρή ηλικία με την εισαγωγή του στο δημόσιο ωδείο Ιωαννίνων. Μελέτησε τη θεωρία της δυτικής μουσικής και το αρμόνιο για 4 χρόνια. Έπειτα ανακαλύπτει και μελετά τα κρουστά με έμφαση στην Ελληνική παραδοσιακή μουσική. Παράλληλα ολοκληρώνει τις μεταπτυχιακές του σπουδές στο Τμήμα Μουσικών Σπουδών του Εθνικού Καποδιστριακού Πανεπιστημίου Αθηνών στο πρόγραμμα «Ερμηνεία Ενόργανης και Φωνητικής Μουσικής», κατεύθυνση «Εκτέλεση/ερμηνεία της Παραδοσιακής Μουσικής» με ειδίκευση στα παραδοσιακά κρουστά και συνεχίζει την προσωπική του εξερεύνηση στον χώρο των κρουστών δίπλα στον Βαγγέλη Καρίπη, Ηλία Διμάνο και Yshai Afterman.</p>
            <p>Από το 2003 είναι ενεργό μέλος του σχήματος Εν Χορδαίς. Συμμετέχει σε σεμινάρια με καταξιωμένους καλλιτέχνες των κρουστών όπως οι Misirli Ahmet, Bijan Cemirani, Ziya Tabassian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin καθώς και στο International Percussion Meeting (Tunis, 2004). Ως μέλος του σχήματος Εν Χορδαίς έχει βραβευτεί με το βραβείο "Prix France Musique des Musiques du Monde", Babel Med Music / Radio France (Marseille 2008) και ήταν υποψήφιος για το βραβείο της UNESCO “Sharjah Prize” for Arabic Culture (2006). Έχει συμμετάσχει σε περισσότερες από 300 συναυλίες σε πολλές χώρες ανά τον κόσμο, σε μεγάλα φεστιβάλ και διάσημους συναυλιακούς χώρους όπως: The Grace Rainey Rogers Auditorium of Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison Symphonic (Montreal), Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theatre de la Ville (Paris), Institut du Monde Arabe (Paris), Bibliotheca Alexandrina (Egypt), Carl-Orff Saal Gasteig (Munich), Silk Road Arts Festival (Hong Kong), Melbourne Recital Centre (Australia), Ten Days on the Island (Tasmania), Fès Festival (Morocco), Cemal Resit Rey Concert Hall (Istanbul), Onassis Culture Center (Athens), Cite de la Music (Marseille), The International Jerusalem Oud Festival (Israel), Athens Concert Hall, Thessaloniki Concert Hall, Les Suds à Arles Festival, Journées Musicales de Carthage (Tunis), Cairo Opera House, Tropenmuseum (Amsterdam), Gibraltar World Music Festival, Romanian Culture Institute (Bucharest) και σε πολλές άλλες πόλεις σε όλο τον κόσμο. </p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Ως δάσκαλος – επισκέπτης έχει συμμετάσχει σε σεμινάριο στην διάσημη μουσική ακαδημία Sibelius στο Ελσίνκι. </p>
            <p>Έχει λάβει σε δισκογραφικές παραγωγές και συνεργάζεται με πολλούς καταξιωμένους Έλληνες και ξένους μουσικούς και μουσικά συγκροτήματα όπως: Hellenic National Radio Television Orchestra, Ensemble Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saragoudas, Maria Farantouri, Grigoris Kapsalis, Nikos Filippidis, Haig Yazdjian, και πολλούς άλλους.</p>`,
          },
        ],
        description: `<p>Ο Χρήστος Τζιτζιμίκας και η παρέα του μας προσκαλούν σε δυο βραδιές σύγκλισης με τις βαθύτερες παραδόσεις του τόπου μας. Ο καταξιωμένος παραδοσιακός τραγουδιστής, στήνει γνήσιο παραδοσιακό γλέντι, αναδεικνύοντας τον πλούτο της ελληνικής παράδοσης. Ένα αυθεντικό ηπειρώτικο γλέντι στο Βερολίνο!   </p>`,
        eventType: 'Συναυλία',
        video: '',
        time: '21:00',
        doorsOpen: '20:00',
        entrance: '24€/20€',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
      {
        id: '3',
        startDate: '2024-01-28T17:00:00.349Z',
        endDate: '2024-01-28T22:00:00.349Z',
        title: 'Συναυλία με τον Χρήστο Τζιτζιμίκα και την παρέα του',
        poster: '',
        artists: [
          {
            name: 'Χρήστος Τζιτζιμίκας',
            speciality: 'Τραγούδι',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Χρήστος Τζιτζιμίκας</h2>
              <p>Προερχόμενος από οικογένεια τραγουδιστών της λαϊκής παράδοσης, βίωσε από μικρός το δημοτικό τραγούδι. Από το 1983 ασχολείται με την έρευνα και απόδοση του δημοτικού τραγουδιού της Ηπείρου, Μακεδονίας, Θεσσαλίας και Στερεάς Ελλάδας. 
              <p>Σπουδάζει βυζαντινή μουσική στο Ωδείο Σκαλκώτα με το Λυκούργο Αγγελόπουλο, φωνητική με τη Lucia Fracea και ολοκληρώνει τις σπουδές του στο Τ.Ε.Φ.Α.Α. Αθηνών.</p> 

              <p>Οι πρώτες του εμφανίσεις ήταν με το μουσικό συγκρότημα «ΤΕΡΙΡΕΜ». Από τότε, έχει συνεργαστεί με πολλούς παλιούς και νέους αξιόλογους μουσικούς, κάνοντας εμφανίσεις σε όλη την Ελλάδα και το εξωτερικό σε συναυλίες και θεατρικές παραστάσεις. </p>

              <p>Το 1993 κυκλοφορεί από τις εκδόσεις ίαμβος η πρώτη του δισκογραφική δουλειά με τίτλο «Ζαγορίσια και Γιαννιώτικα». Με τη συμμετοχή του Γρηγόρη Καψάλη στο κλαρίνο, δέκα αξιόλογων μουσικών και τη φιλική συμμετοχή του «ΤΕΡΙΡΕΜ». Την ηχοληψία κάνει ο Γιώργος Συγλέτος και την διεύθυνση ορχήστρας έχει ο Γιώργος Παπαδάκης. Συνοδεύεται από ένθετο (ελληνικά και αγγλικά) με πολλές πληροφορίες και φωτογραφίες. </p>

              <p>Τραγούδι του δίσκου Ζαγορίσια και Γιαννιώτικα χρησιμοποιήθηκε στην ταινία «Αθήνα -- Κωνσταντινούπολη» του Νίκου Παναγιωτόπουλου. «Πατήματα» είναι ο τίτλος της δεύτερης δισκογραφικής δουλειάς που κυκλοφόρησε από τις εκδόσεις ίαμβος το 2001. Περιλαμβάνει 16 τραγούδια με σημείο αναφοράς το Πωγώνι της Ηπείρου. Συμμετέχουν ο Πετρολούκας Χαλκιάς (κλαρίνο), ο Χριστόδουλος Ζούμπας (λαούτο, μουσική επιμέλεια) και τέσσερις ακόμα αξιόλογοι μουσικοί. Η έκδοση έχει τη μορφή βιβλίου με εκτεταμένα ιστορικά και μουσικολογικά σχόλια (ελληνικά και αγγλικά) και φωτογραφικό υλικό. </p>

              <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
              <p>Οι δυο δίσκοι αποσπούν ενθουσιώδεις κριτικές από το σύνολο του μουσικού και όχι μόνο τύπου και παρουσιάζονται ζωντανά σε πόλεις της Ελλάδας και του εξωτερικού. </p>
              <p>Το 2001 συμμετέχει στη δισκογραφική έκδοση με τίτλο «Θεσσαλία», το 2015 στο cd του Σταμάτη Λαλλά “Από καρδιάς Αγιαθυμιά”, το 2019 στο cd του Βασίλη Τριάντη “Πορτολάνος”. </p>
              <p>Δίδαξε το παραδοσιακό τραγούδι στο τμήμα Ελληνικής Παραδοσιακής Μουσικής του ΤΕΙ Άρτας, στο τμήμα Μουσικής Επιστήμης και Τέχνης του Πανεπιστημίου Μακεδονίας, στο Μουσικό Χωριό, Άγιος Λαυρέντιος, σε σεμινάρια και σε κατ’ ιδίαν μαθήματα.</p>`,
          },
          {
            name: 'Αντώνης Καλιούρης',
            speciality: 'Κλαρίνο',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Αντώνης Καλιούρης</h2>
            <p>Γεννήθηκε στην Αθήνα, όπου και μεγάλωσε, με καταγωγή από το Ρέθυμνο Κρήτης. Είναι τελειόφοιτος του τμήματος Βιοτεχνολογίας του Γεωπονικού Πανεπιστημίου Αθηνών.</p>
            <p>Η ενασχόληση του με τη μουσική ξεκίνησε στην ηλικία των εννέα ετών, μαθαίνοντας λαούτο. Στην ηλικία των δώδεκα ετών ξεκίνησε να σπουδάζει παραδοσιακό κλαρίνο, με δάσκαλο τον Βαγγέλη Παπαναστασίου. Αργότερα, για τρία χρόνια σπούδασε κλασικό κλαρινέτο στο Αττικό Ωδείο, με καθηγητή τον Μανούσο Πλουμίδη. Το 2011 και για τέσσερα χρόνια παρακολούθησε μαθήματα παραδοσιακού κλαρίνου δίπλα στον δεξιοτέχνη Αλέξανδρο Αρκαδόπουλο. Παράλληλα παρακολουθεί μαθήματα φλάουτου στο Εθνικό Ωδείο, με καθηγήτρια τη Δανάη Κιουπούρογλου.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Από μικρή ηλικία συμμετέχει σε πολιτιστικές εκδηλώσεις καθώς και σε πανηγύρια, γάμους και χορευτικές εκδηλώσεις σε όλη την Ελλάδα.</p>
            <p>Έχει συμμετάσχει ως μουσικός σε συναυλίες, παραστάσεις, δισκογραφικά καθώς και σε ραδιοφωνικά και τηλεοπτικά προγράμματα με διάφορους καλλιτέχνες του λαϊκού και παραδοσιακού χώρου, όπως οι: Γιώργος Νταλάρας, Γλυκερία, Κώστας Μακεδόνας, Γιάννης Κότσιρας, Γεράσιμος Ανδρεάτος, Σοφία Παπάζογλου, Μπάμπης Τσέρτος, Λαυρέντης Μαχαιρίτσας, Γιάννης Λεμπέσης, Γιώτα Νέγκα, Μίλτος Πασχαλίδης, Νάντια Καραγιάννη, Καλλιόπη Βέττα, Χρήστος Δάντης, Γιώργος Μεράντζας, Ερωφίλη, Παναγιώτης Λάλεζας, Μάνος Κουτσαγγελίδης, Χρήστος Τζιτζιμίκας καθώς και με την ορχήστρα Εστουδιαντίνα Νέας Ιωνίας.</p>`,
          },
          {
            name: 'Γιάννης Πούλιος',
            speciality: 'Βιολί',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Γιάννης Πούλιος</h2>
            <p>Ο Γιάννης Πούλιος γεννήθηκε στο Bruchsal Γερμανίας και μεγάλωσε στην Κατερίνη.</p>
            <p>Μαθήτευσε στο μουσικό σχολείο Κατερίνης και μετέπειτα φοίτησε στο Τμήμα Λαϊκής και Παραδοσιακής Μουσικής στην Άρτα με δασκάλους τους: Γιώργο Ψάλτη και Μάκη Μπακλατζή καθώς επίσης και στo Yildiz Technical University στην Κωνσταντινούπολη (στα πλαίσια του προγράμματος Erasmus) για δύο εξάμηνα με δασκάλους τους Özer Özel, Nedim Nalbantoğlu και Murat Sakaryalı. </p>
            <p>Σήμερα έχει ενεργή συμμετοχή στον ελλαδικό μουσικό χώρο καθώς και στο εξωτερικό με συνεργασίες επί σκηνής αλλά και σε δισκογραφικό επίπεδο με διάφορα μουσικά σχήματα όπως: το «Tasos Poulios quartet», το «Σummation» και το “Hane” του Αποστόλου Σιδέρη, το σχήμα Ηλιοδρόμιο, το Lappino project του Δημήτρη Λάππα, και το σχήμα Ali Perret’s DU.DU στην Κωνσταντινούπολη. </p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Επίσης έχει συνεργαστεί με το σχήμα Ψυχή & Σώμα με τον Ματθαίο και Κώστα Τσαχουρίδη στο Μέγαρο Μουσικής Αθηνών, το σχήμα Λωξάντρα και με καλλιτέχνες όπως την Ελένη Τσαλιγοπούλου, την Νένα Βενετσάνου, τον Haig Yazdjan, τον Περικλή Παπαπετρόπουλο, την Ειρήνη Τορνεσάκη, την Αρετή Κετιμέ ,την Λιζέτα Καλημέρη, τον Αντώνη Απέργη, τον Μάνο Αχαλινωτόπουλο, τον Φώτη Σιώτα, τον Νίκο Παραουλάκη, τον ο Κωσταντίνο Πλούσιο κι άλλους ακόμη μουσικούς της ελληνικής μουσικής σκηνής. </p>
            <p>Επίσης, τα δύο τελευταία χρόνια εργάζεται στον χώρο της εκπαίδευσης ως αναπληρωτής καθηγητής παραδοσιακού βιολιού στο πειραματικό μουσικό γυμνάσιο Παλλήνης. </p>`,
          },
          {
            name: 'Παναγιώτης Τσάκος',
            speciality: 'Κιθάρα, Λαούτο',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Παναγιώτης Τσάκος</h2>
            <p>Ο Παναγιώτης Τσάκος γεννήθηκε και μεγάλωσε στην Ναύπακτο. Φοίτησε στο ΤΕΙ Λαϊκής και Παραδοσιακής Μουσικής στην Άρτα, με ειδικότητα στην λαϊκή κιθάρα. Δραστηριοποιείται στο χώρο της της παραδοσιακής δημοτικής μουσικης και όχι μόνο, από το 2001, με συνεργασίες και συμμετοχές με γνωστά ονόματα του χώρου.</p>`,
          },
          {
            name: 'Πέτρος Παπαγεωργίου',
            speciality: 'Κρουστά',
            image: 'papageorgiou.jpg',
            youtube: 'https://www.youtube.com/@Petrran',
            spotify: '',
            description: `<h2>Πέτρος Παπαγεωργίου</h2>
            <p>Ο Πέτρος Παπαγεωργίου είχε την πρώτη του επαφή με την μουσική σε νεαρή ηλικία με την εισαγωγή του στο δημόσιο ωδείο Ιωαννίνων. Μελέτησε τη θεωρία της δυτικής μουσικής και το αρμόνιο για 4 χρόνια. Έπειτα ανακαλύπτει και μελετά τα κρουστά με έμφαση στην Ελληνική παραδοσιακή μουσική. Παράλληλα ολοκληρώνει τις μεταπτυχιακές του σπουδές στο Τμήμα Μουσικών Σπουδών του Εθνικού Καποδιστριακού Πανεπιστημίου Αθηνών στο πρόγραμμα «Ερμηνεία Ενόργανης και Φωνητικής Μουσικής», κατεύθυνση «Εκτέλεση/ερμηνεία της Παραδοσιακής Μουσικής» με ειδίκευση στα παραδοσιακά κρουστά και συνεχίζει την προσωπική του εξερεύνηση στον χώρο των κρουστών δίπλα στον Βαγγέλη Καρίπη, Ηλία Διμάνο και Yshai Afterman.</p>
            <p>Από το 2003 είναι ενεργό μέλος του σχήματος Εν Χορδαίς. Συμμετέχει σε σεμινάρια με καταξιωμένους καλλιτέχνες των κρουστών όπως οι Misirli Ahmet, Bijan Cemirani, Ziya Tabassian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin καθώς και στο International Percussion Meeting (Tunis, 2004). Ως μέλος του σχήματος Εν Χορδαίς έχει βραβευτεί με το βραβείο "Prix France Musique des Musiques du Monde", Babel Med Music / Radio France (Marseille 2008) και ήταν υποψήφιος για το βραβείο της UNESCO “Sharjah Prize” for Arabic Culture (2006). Έχει συμμετάσχει σε περισσότερες από 300 συναυλίες σε πολλές χώρες ανά τον κόσμο, σε μεγάλα φεστιβάλ και διάσημους συναυλιακούς χώρους όπως: The Grace Rainey Rogers Auditorium of Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison Symphonic (Montreal), Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theatre de la Ville (Paris), Institut du Monde Arabe (Paris), Bibliotheca Alexandrina (Egypt), Carl-Orff Saal Gasteig (Munich), Silk Road Arts Festival (Hong Kong), Melbourne Recital Centre (Australia), Ten Days on the Island (Tasmania), Fès Festival (Morocco), Cemal Resit Rey Concert Hall (Istanbul), Onassis Culture Center (Athens), Cite de la Music (Marseille), The International Jerusalem Oud Festival (Israel), Athens Concert Hall, Thessaloniki Concert Hall, Les Suds à Arles Festival, Journées Musicales de Carthage (Tunis), Cairo Opera House, Tropenmuseum (Amsterdam), Gibraltar World Music Festival, Romanian Culture Institute (Bucharest) και σε πολλές άλλες πόλεις σε όλο τον κόσμο. </p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Ως δάσκαλος – επισκέπτης έχει συμμετάσχει σε σεμινάριο στην διάσημη μουσική ακαδημία Sibelius στο Ελσίνκι. </p>
            <p>Έχει λάβει σε δισκογραφικές παραγωγές και συνεργάζεται με πολλούς καταξιωμένους Έλληνες και ξένους μουσικούς και μουσικά συγκροτήματα όπως: Hellenic National Radio Television Orchestra, Ensemble Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saragoudas, Maria Farantouri, Grigoris Kapsalis, Nikos Filippidis, Haig Yazdjian, και πολλούς άλλους.</p>`,
          },
        ],
        description: `<p>O Χρήστος Τζιτζιμίκας και η παρέα του μας προσκαλούν σε δυο βραδιές σύγκλισης με τις βαθύτερες παραδόσεις του τόπου μας. Ο καταξιωμένος παραδοσιακός τραγουδιστής, στήνει γνήσιο παραδοσιακό γλέντι, αναδεικνύοντας τον πλούτο της ελληνικής παράδοσης. Ένα αυθεντικό ηπειρώτικο γλέντι στο Βερολίνο!</p>`,
        eventType: 'Συναυλία',
        video: '',
        time: '18:00',
        doorsOpen: '17:00',
        entrance: '24€/20€',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
    ],
    supplementary: `<h2>”Ο θάνατος, η ξενιτιά, η πίκρα και η ορφάνια”. Αυτό είναι το ηπειρώτικο τραγούδι. </h2>
    <p>
    Ήπειρος είναι το γεωγραφικό διαμέρισμα που βρίσκεται στο βορειοδυτικό άκρο της Ελλάδος. Η ονομασία προέρχεται από το επίθετο άπειρος, δωρική προφορά του επιθέτου ήπειρος. Είναι περιοχή κυρίως ορεινή και οι πεδινές περιοχές βρίσκονται κυρίως γύρω από τις εκβολές των ποταμών της.</p>
    <p>
    Η μουσική παράδοση της Ηπείρου αποτελεί έναν από τους μεγαλύτερους πολιτιστικούς θησαυρούς της Ελλάδας. Στα απομονωμένα χωριά της Ηπείρου έχει καλλιεργηθεί και διατηρηθεί ένας ξεχωριστός ήχος, ένας μοναδικός ρυθμός. Η τοπική μουσική και τα τραγούδια στην Ήπειρο έχουν μια αρχέγονη καθαρότητα, μια θεραπευτική δύναμη. Η μουσική κουλτούρα στην Ήπειρο παραμένει αναλλοίωτη και ανόθευτη από τις ξένες επιρροές εδώ και χιλιάδες χρόνια. </p>
    <p>
    Στην Ήπειρο ο χορός και το τραγούδι είναι μια συλλογική πράξη θύμησης, εξομολόγησης, λεβεντιάς και λαϊκής σοφίας. Μια ανασκαφή στην αφετηρία, την καταγωγή και την ωρίμανση της ζωής και των συναισθημάτων που είναι βαθιά ριζωμένα στην ιστορική συνείδηση του λαού της Ηπείρου και που εκτείνεται από την Αιτωλοακαρνανία μέχρι τη Νότια Αλβανία.</p>
    <p>
    Η ηπειρώτικη μουσική διακρίνεται πανελλήνια από το αρμονικό και μελωδικό χρώμα που την περιβάλλει: οι μελωδικές γραμμές είναι σύντομες, ο ήχος είναι λυπητερός, ακόμα και τα τραγούδια με εύθυμο σκοπό ή με σατυρικό περιεχόμενο ηχούν “βαριά”. Η άγρια λιτότητα του ορεινού τοπίου δεν μπορούσε παρά ν' αντανακλάται στην αποφυγή κάθε περιττής πληθωρικότητας, ακόμα κι όταν ο καλλιτέχνης καταγίνεται στα τόσο χαρακτηριστικά στολίδια και τσακίσματα. </p>
    <p>
    Το περιεχόμενο των τραγουδιών ποικίλει. Γίνονται αναφορές για ιστορικά και ηρωικά γεγονότα, αισθηματικά, γαμήλια, ποιμενικά, οδοιπορικά και πολλά για την ξενιτιά. Μόνο το πολεμικό τραγούδι δεν ήταν διαδεδομένο σε όλη την Ήπειρο. Κυρίως, επειδή δεν πολεμούσαν εξίσου συχνά σε όλη την Ήπειρο. Έτσι, πολεμικό τραγούδι βρίσκουμε κυρίως στην Θεσπρωτία και συγκεκριμένα στην περιοχή του Σουλίου. </p>
    <p>
    Το κλαρίνο είναι το πρωταγωνιστικό μουσικό όργανο. Άλλα όργανα της ηπειρώτικης μουσικής είναι το βιολί, το στεριανό λαούτο, το ντέφι, η φλογέρα, η τζαμάρα, το σαντούρι. </p>
    <p>
    Στα παραδοσιακά ηπειρώτικα γλέντια, υπάρχει ένα τελετουργικό: Το πρώτο τραγούδι είναι πάντοτε μοιρολόι. Είτε για νεκρό, είτε για ξενιτεμένο. Και η έννοια της ξενιτιάς στην Ήπειρο είχε χαρακτηριστικά θανάτου. Μετά συνήθως ακολουθεί ο σκάρος και μετά μουσική με ποικίλους ήχους, σκοπούς και στίχους, ένα μωσαϊκό από χαρές, καημούς, νοσταλγία, καθημερινά βάσανα και εκτόνωση. Το γλέντι τελειώνει πάντα με τραγούδια του αποχωρισμού. 
    Τα δημοτικά τραγούδια ήταν προφορικά. Δεν γράφτηκε η μουσική, δεν γράφτηκαν οι στίχοι. Ιστορίες από στόμα σε στόμα. </p>
    `,
  },
  {
    id: '2',
    url: 'bando-and-friends2',
    title: 'Bando & Friends 2',
    description: `<p>Ο δεύτερος γύρος του BANDO & friends και ταυτόχρονα το πάρτι για τα 25α γενέθλια του BANDO!!</p>
    <p>Το event θα γίνει στο πρώην BANDO-Keller και σήμερα κομμάτι του Jugendhauses Königstadt στο Prenzlauer Berg.</p>
    <p>Ελάτε και φέρτε και τους φίλους σας, να παρτάρουμε όλοι μαζί!!</p>
    `,
    startDate: '2023-12-01T20:00:00.349Z',
    endDate: '2023-12-02T06:00:00.349Z',
    poster: 'bando-friends.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2023-12-01T19:00:00.349Z',
        endDate: '2023-12-02T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Local support',
            speciality: 'metal/punk/hardcore',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'BANDO',
            speciality: 'hardcore percussion',
            image: '',
            youtube: 'https://www.youtube.com/watch?v=6a0B_g5oGt0',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Συναυλία',
        video: '',
        time: '20:00',
        doorsOpen: '19:00',
        entrance: 5,
        googleLocation:
          'https://www.google.com/maps/place/BANDO+Berlin/@52.5417735,13.3859352,14z/data=!3m1!5s0x47a851e24f3df225:0x9c79e48257493088!4m10!1m2!2m1!1sBANDO+Berlin!3m6!1s0x47a84e1d481a733f:0xc5c1ccb3d36c4565!8m2!3d52.5306014!4d13.4134281!15sCgxCQU5ETyBCZXJsaW6SAQRiYW5k4AEA!16s%2Fg%2F11fkf7x9l7?entry=ttu',
        locationName: 'Jugendhaus Königstadt',
        locationAddress: 'Saarbrücker Straße 23 (U-Bahn Senefelder Platz)',
      },
    ],
    supplementary: '',
  },
  {
    id: '3',
    url: 'glenti-advent-dance-party-ljuti-hora',
    title: 'Γλεντι - Γιορτινοι χοροι με τους Ljuti-Hora (Berlin)',
    description: `<p>Ενα βράδυ με πολύ ελληνική και βαλκανική μουσική. Οι μουσικοί είναι στην καλύτερή τους φόρμα και ανυπομονούν να σε κάνουν να χορεύεις ως το πρωί!!</p>
    `,
    poster: 'LjutiHora-2-12-23.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2023-12-02T19:00:00.349Z',
        endDate: '2023-12-03T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Mate Gaal',
            speciality: 'Κλαρίνο, φλάουτο, φωνή',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Ακορντεόν',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Martin Petrov',
            speciality: 'Γκάιντα, κρουστά, φωνή',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Oliver Goers',
            speciality: 'Κρουστά, φωνή',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Tina Hess',
            speciality: 'Χορεύτρια',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Doris Schneider',
            speciality: 'Χορεύτρια',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '20:00',
        doorsOpen: '19:30',
        entrance: '10/8 €',
        googleLocation:
          'https://www.google.com/maps/place/Haus+der+Sinne/@52.5491318,13.3993861,17z/data=!3m1!4b1!4m6!3m5!1s0x47a8521b504a4e1d:0x8b7e06018e6373de!8m2!3d52.5491319!4d13.4042516!16s%2Fg%2F1tdck_b3?entry=ttu',
        locationName: 'Haus der Sinne',
        locationAddress: 'Ystader Straße 10, 10437 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '4',
    url: 'kickoff-night',
    title: 'Το πρωτο βραδυ απο τα πολλα που επονται!',
    description: `<p><h3>Να 'μαστε λοιπον!</h3>
    Ένα session-προθέρμανση, να σε ετοιμάσουμε για όσα έπονται!<br />
    Ένα βράδυ που θα έχουμε να θυμόμαστε!<br />
    Μία ματιά στον κόσμο του Provokatsia!</p>
    <p>Και όλα αυτά με ρυθμούς</p>
    <p>
    ---------------<br />
    Roots reggae<br />
    Dub &<br />
    Afro<br />
    --------------</p>
    <p>Ήρθε λοιπόν η ώρα να συναντηθούμε, να χορέψουμε και να διαδώσουμε λίγη αγάπη!<br />
    And embrace together the provokatsia vibes!</p>
    `,
    poster: 'kickoff-night.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-04T21:00:00.349Z',
        endDate: '2023-12-05T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Ital-b toaster',
            speciality: 'Greece',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Pikoz Apikoz',
            speciality: 'Berlin',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Joolee Rankin',
            speciality: 'France',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '20:00',
        doorsOpen: '20:00',
        entrance: '5 €',
        googleLocation:
          'https://www.google.com/maps/place/Lauschangriff/@52.5182464,13.4553066,15z/data=!4m6!3m5!1s0x47a84e6653637a9d:0xd32edb6abbf17c08!8m2!3d52.5182464!4d13.4553066!16s%2Fg%2F11bxh9tjcp?entry=ttu',
        locationName: 'Lauschangriff',
        locationAddress: 'Rigaer Str. 103, 10247 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '5',
    url: '3day-tribute-to-rempetiko',
    title: 'Τριημερο αφιερωμα στο ρεμπετικο τραγουδι',
    description: `<h3>Σεργιάνι στους δρόμους του ρεμπέτικου</h3>
      <p>Τρεις μέρες αφιερωμένες στο ρεμπέτικο τραγούδι. </p>
      <p>Προβολή του ντοκιμαντέρ σπασμένος ήχος για το μπουζούκι το βασικό όργανο της ρεμπέτικης ορχήστρας. </p>
      <p>Παρουσίαση βιβλίου για τον Δημήτρη Γκόγκο (Μπαγιαντέρας) ενός από τους σημαντικότερους εκπροσώπους του είδους. </p>
      <p>Συναυλία αφιέρωμα στον Δημήτρη Γκόγκο (Μπαγιαντέρα), συναυλία με τραγούδια των μεγαλύτερων δημιουργών του ρεμπέτικου από την προπολεμική και μεταπολεμική εποχή. </p>
      `,
    poster: '3day-tribute-to-rempetiko.jpg',
    tip: 'Συμβουλή: Περιορισμένος αριθμός εισιτηρίων, ελάτε νωρίς για να λάβετε θέση! ;)',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-08T20:30:00.349Z',
        endDate: '2023-12-10T01:00:00.349Z',
        title: 'Συναυλια με γνωστα και αγνωστα ρεμπετικα τραγουδια. ',
        poster: '',
        artists: [
          {
            name: 'Ντίνος Μπουζάνης',
            speciality: 'Kιθάρα',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Ακορντεόν',
            image: 'sanne.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Μαρία Βακάλη',
            speciality: 'Φωνή',
            image: 'maria.jpg',
            youtube: 'https://www.youtube.com/watch?v=t_TywytZvdw',
            spotify: '',
          },
          {
            name: 'Ορφέας Τσακτσίρας',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Συναυλία',
        video: '',
        time: '21:30',
        doorsOpen: '20:30',
        entrance: 22,
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
      {
        id: '2',
        startDate: '2023-12-09T18:00:00.349Z',
        endDate: '2023-12-09T22:00:00.349Z',
        title: 'Προβολη ντοκιμαντερ για το μπουζουκι "Σπασμενος ηχος."',
        subtitle:
          "Ιδέα-Δημιουργία: Ανέστης Μπαρμπάτσης, Σκηνοθεσία: Φοίβος Κοντογιάννης  '",
        poster: '',
        artists: [],
        description: `
            <p>Το μουσικό ντοκιμαντέρ «Σπασμένος Ήχος», μια συνδημιουργία των Ανέστη Μπαρμπάτση και Φοίβου Κοντογιάννη σε παραγωγή της Foss Productions, το οποίο πραγματοποιήθηκε σε συμπαραγωγή με το Ελληνικό Κέντρο Κινηματογράφου.</p>
            <p>Ο Φοίβος Κοντογιάννης υπεύθυνος για τη σκηνοθεσία και ο Ανέστης Μπαρμπάτσης, υπεύθυνος για την ιδέα, την έρευνα, αρχισυνταξία, τη μουσική επιμέλεια, καθώς και την επιλογή και τις συνεντεύξεις των πρωταγωνιστών και των λοιπών ομιλητών μας οδηγούν σε ένα ταξίδι μνήμης στον ήχο του πιο αγαπητού λαϊκού οργάνου της χώρας ειπωμένο μέσα από τα μάτια μεγάλων προσωπικοτήτων της σύγχρονης ιστορίας του μπουζουκιού. </p>
            <p>Από τους σκοτεινούς τεκέδες στα χρόνια της παρανομίας, στα μεγάλα σαλόνια του ελληνικού πενταγράμμου, το μπουζούκι ξεδιπλώνει την ψυχή του στα χέρια των μουσικών που το αγάπησαν και το ανέδειξαν. Κομμάτι της οπτικοποιημένης αυτής αφήγησης και παρόντες στο απαιτητικό αυτό πόνημα, αποτελούν σπουδαίοι μουσικοί όπως οι Δημήτρης Βύζας, Γιώργος Δράμαλης, Μανώλης Καραντίνης, Βαγγέλης Λιόλιος, Γιάννης Μωραΐτης, Χρίστος Νικολόπουλος, Γιάννης Παπαβασιλείου, Κώστας Παπαδόπουλος, Μανώλης Πάππος, Θανάσης Πολυκανδριώτης, Παναγιώτης Στεργίου, Θύμιος Στουραΐτης, Νίκος Τατασόπουλος, Βαγγέλης Τρίγκας και Διαμαντής Χιώτης, οι οποίοι με τα ταξίμια και τις συναρπαστικές αφηγήσεις τους μας ταξιδεύουν μελωδικά στο παρελθόν, το παρόν, αλλά και το μέλλον του μπουζουκιού. </p>
            <p>Παρακολουθώντας τη μουσική εξέλιξη του μπουζουκιού, o “Σπασμένος Ήχος” επιχειρεί μια ιστορική, μουσική επισκόπηση, καθώς και μια πολιτιστική και κοινωνική αποτύπωση της εξέλιξης της ελληνικής κοινωνίας, μέσα από συνεντεύξεις μεγάλων προσωπικοτήτων της σύγχρονης ιστορίας του οργάνου, σε συνδυασμό με σπάνιο αρχειακό υλικό. </p>
          `,
        supplementary: `
          <h2>Συντελεστες</h2>
          <h3>Ταυτοτητα Ταινιας</h3>
          <p>Ιδέα-Έρευνα : Ανέστης Μπαρμπάτσης<br />
          Σενάριο: Ανέστης ΜπαρμπάτσηςΦοίβος Κοντογιάννης.
          </p>
          <p>Σκηνοθεσία: Φοίβος Κοντογιάννης</p>
          <p>Αρχισυνταξία-Μουσική επιμέλεια: Ανέστης Μπαρμπάτσης</p>
          <p>
            Παραγωγή: Foss Productions<br />
            Συμπαραγωγή: Ελληνικό Κέντρο Κινηματογράφου, Faliro House, EKOME<br />
            Παραγωγός: Στέλιος Κοτιώνης<br />
            Executive Producer: Χρήστος Β. Κωνσταντακόπουλος<br />
            Διεύθυνση φωτογραφίας: Μανού Τιλίνσκι<br />
            Μοντάζ: Γιώργος Γεωργόπουλος<br />
            Ήχος: Σταύρος Αβραμίδης, Λέανδρος Ντούνης<br />           
          </p>
        `,
        eventType: 'Προβολή Ντοκιμαντέρ',
        time: "19:00 | Διάρκεια: 80'",
        doorsOpen: '18:00',
        videoId: '4Xf8JWi2jFg',
        entrance: 'Δωρεάν',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,15z/data=!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'Bavul',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '3',
        startDate: '2023-12-10T17:00:00.349Z',
        endDate: '2023-12-10T22:00:00.349Z',
        title:
          'Παρουσιαση βιβλιου Μπαγιαντερας - Δημητρης Γκογκος, προπολεμικη περιοδος + Συναυλια αφιερωμα στο εργο του Μπαγιαντερα ',
        poster: 'mpagianteras.jpg',
        artists: [
          {
            name: 'Ντίνος Μπουζάνης',
            speciality: 'Kιθάρα',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Ακορντεόν',
            image: 'sanne.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Μαρία Βακάλη',
            speciality: 'Φωνή',
            image: 'maria.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Ανέστης Μπαρμπάτσης',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'anestis.png',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Γιώργος Ευάγγελου',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'giorgos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Ορφέας Τσακτσίρας',
            speciality: 'Μπουζούκι, Φωνή',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
        ],
        description: `
            <h3>Παρουσίαση βιβλίου</h3>
            <p>Μπαρμπάτσης Ανέστης, Μπαγιαντέρας-Δημήτρης Γκόγκος, Προπολεμική περίοδος, εκδόσεις Μετρονόμος 2022. </p>
            <p>Ο Δημήτρης Γκόγκος ή Μπαγιαντέρας γεννήθηκε στον Πειραιά το 1903.</p>
            <p>Μεγαλώνοντας στο Χατζηκυριάκειο, βίωσε την πρώιμη περίοδο της πορείας και της εξέλιξης του «πειραιώτικου» ρεμπέτικου με βάση το

            μπουζούκι, πριν την πρώτη του καταγραφή σε δίσκο. To πλαίσιο μέσα
            
            στο οποίο γαλουχήθηκε και η μουσική του πορεία τον καθιστούν έναν
            
            από τους σημαντικότερους εκπροσώπους του. </p>
            <p>Το βιβλίο του Ανέστη Μπαρμπάτση εστιάζει στην παρουσίαση και

            ανάλυση της προπολεμικής δημιουργίας του συνθέτη, ενώ παραθέτει
            
            σημαντικά τεκμήρια σχετικά με τη ζωή και το έργο του τα οποία
            
            στοιχειοθετούν την πορεία του και δημοσιεύονται για πρώτη φορά. </p>
          `,
        eventType: 'Παρουσίαση Βιβλίου + Συναυλία',
        time: 'Παρουσίαση: 18:00 | Συναυλία: 19:00',
        doorsOpen: '17:00',
        video: '',
        entrance: 'Παρουσίαση: Δωρεάν | Συναυλία: 17 €',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
    ],
    supplementary: `<h2>Ρεμπετικα - τα Ελληνικα Blues</h2>
        <p><i>"Ανάλογες δημιουργίες με αυτές του ρεμπέτικου παρατηρούνται στην Αμερική με το Βlues στην Βραζιλία με την Samba και στην Τζαμάικα με την Reggae."</i></p>
        <p><i>"Το ρεμπέτικο είναι λαικό αστικό τραγούδι που οι ρίζες του ανάγονται στην βυζαντινή μουσική και στο Ελληνικό δημοτικό τραγούδι."</i></p>
        <p><i>"Ρεμπέτης: Ατίθασος, εξεγερμένος, ανεξάρτητος."</i></p>
        <p><i>"Τα ρεμπέτικα είναι τραγούδια της καρδιάς και μόνον όποιος τα πλησιάσει με αγνό αίσθημα τα νοιώθει και τα χαίρεται."</i></p>
        <p><i>"Το ρεμπέτικο κατορθώνει με θαυμαστή ενότητα να συνδυάζει τον λόγο, την μουσική και την κίνηση."</i></p>
        <p><i>"Απο την σύνθεση μέχρι την εκτέλεση, με ένστικτο δημιουργούνται οι προυποθέσεις για την τριπλή αυτή συνύπαρξη, που ορισμένες φορές σαν φτάνει την τελειότητα, θυμίζει αρχαία τραγωδία."</i></p>
        <p>Απόσπασμα απο το βιβλίο του Ηλία Πετρόπουλου</p>
        <p><i>"Το ρεμπέτικο τραγούδι είναι γνήσια ελληνικό, μοναδικό ελληνικό."</i></p>
        <p>Μάνος Χατζιδάκις</p>
      `,
  },
  {
    id: '6',
    url: 'saligari-meets-provokatsia',
    title: 'Saligari meets provokatsia',
    description: `<p>Λίγο πριν το τέλος του 2023, νέα ξεκινήματα συμβαίνουν στο Βερολίνο. Το αγαπημένο μας Σαλιγκάρι ξεκιναει 
      σιγά σιγά την λειτουργία του σε καινούριο χώρο και διαφορετικό κονσεπτ. Ειδικότερα αυτό το Σάββατο συναντιέται 
      με τo Provokatsia Project με σκοπό να γνωριστούμε όλοι λίγο καλύτερα. Από τις 7 το απόγευμα σας περιμένουμε να 
      τα πούμε, να ανταλλάξουμε ιδέες, να ακούσουμε επιλεγμένες μουσικές και να πιούμε τα καλύτερα κοκτέιλ</p>
    `,
    poster: 'saligari-meets-provokatsia.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-16T18:00:00.349Z',
        endDate: '2023-12-17T04:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '19:00',
        doorsOpen: '19:00',
        entrance: 'Ελεύθερη είσοδος',
        googleLocation:
          'https://www.google.com/maps/place/Simplonstra%C3%9Fe+23,+10245+Berlin/@52.5084283,13.4592078,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84e58dd616c81:0xd36c8ab82bfa3077!8m2!3d52.5084283!4d13.4592078!16s%2Fg%2F11c1z9gkkd?entry=ttu',
        locationName: 'Saligari bar',
        locationAddress: 'Simplonstrasse 23, 10245 Berlin',
      },
    ],
    supplementary: '',
  },
]
