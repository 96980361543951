import styled from 'styled-components'

export const StyledEvent = styled.div`
  & .wrapper > section {
    margin-bottom: 100px;
  }

  @media (max-width: 576px) {
    & .wrapper > section {
      margin-bottom: 80px;
    }
  }

  @media (max-width: 425px) {
    & .wrapper > section {
      margin-bottom: 50px;
    }
  }

  section h2,
  h1 {
    text-transform: uppercase;
  }

  .event-header {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .main-header span span {
    display: inline-block;
    color: #fff;
  }

  @media (max-width: 576px) {
    .event-header {
      display: flex;
      flex-direction: column;
    }
    .event-header > div {
      margin-bottom: 20px;
    }
  }

  .event-desc {
    display: flex;
  }

  .event-desc__poster {
    width: 35%;
    margin-right: 25px;
  }

  .event-desc__text {
    flex: 1;
  }

  .event-desc__artists {
    margin-top: 20px;
  }

  .event-desc__artists a.bio-link {
    color: var(--secondary-color);
  }

  @media (max-width: 768px) {
    .event-desc__poster {
      width: 40%;
    }
  }

  @media (max-width: 576px) {
    .event-desc {
      display: block;
    }

    .event-desc__poster {
      width: 100%;
      margin: 0 0 30px 0;
    }
  }

  .book-n-desc {
    display: flex;
  }

  .book-container {
    width: 300px;
    margin-right: 30px;
  }

  .event-tip {
    margin: 0px 0 20px 0;
    display: flex;
    align-items: center;
  }
  .event-tip svg {
    margin-right: 5px;
    height: 1.25rem;
    width: 1.25rem;
    fill: #f1b94c;
  }

  .event-details {
    display: flex;
    gap: 30px;
  }

  @media (max-width: 1023px) {
    .event-details {
      flex-direction: column;
      gap: 80px;
    }
    .event-info {
      flex: 1;
    }
  }

  .event-day {
    display: flex;
    align-items: flex-start;
    flex: 1;
    margin-top: 25px;
  }

  .event-info__type {
    display: block;
    color: var(--secondary-color);
    margin: -22px 0 2px;
  }

  .event-info__button {
    margin-top: 20px;
  }

  .event-info h3 {
    font-size: 1rem;
  }

  .supplementary {
    background-color: #fff;
    color: #222;
    padding: 40px 0;
  }

  .social-share button {
    margin-right: 10px;
  }

  @media (max-width: 576px) {
    .social-share .wrapper {
      padding: 30px;
    }
  }

  .tickets .wrapper {
    padding: 110px 45px 0;
    margin-top: -100px;
  }

  @media (max-width: 768px) {
    .tickets .wrapper {
      padding: 80px 15px 0;
      margin-top: -80px;
    }
  }

  @media (max-width: 576px) {
    .tickets .wrapper {
      padding: 60px 15px 0;
      margin-top: -60px;
    }
  }

  

  .tickets .wrapper > h2 {
    margin-bottom: 0;
  }

  .tickets .wrapper > p {
    font-size: 1.35rem;
    margin: 5px 0 30px;
    color: var(--secondary-color);
  }

  .tickets .wrapper > p span {
    color: #fff;
  }

  .tickets iframe {
    border: none;
    max-width: 100%;
    height: 285px;
  }

  .tickets__terms {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px 15px;
    border-radius: 3px 0 0 3px;
    font-size: .875rem;
    flex: 1;
  }

  .tickets__terms a {
    display: block;
    font-size: .75rem;
    margin: 5px 0 0 20px;
  }

  .tickets__infobox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 650px;
    margin: 0 auto;
  }

  .tickets__buy-wrapper {
    max-width: 650px;
    width: 100%;
  }

  .tickets__buy-wrapper .btn {
    border-radius: 3px 3px 0 0;
    display: block;
    width: 100%;
    text-align: center;
  }

  .tickets__disclaimer p {
    font-size: .75rem;
  }

  .tickets__disclaimer p span {
    color: red;
  }
`
