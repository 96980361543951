import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { StyledEventDate } from './styledEventDate'

const EventDate = ({ startDate, endDate, type }) => {
  return (
    <StyledEventDate>
      <div className={`event-date${type ? ' ' + type : ''}`}>
        <div className="month">{dayjs(startDate).format('MMM')}</div>
        <div className="day">{`${dayjs(startDate).format('DD')}${
          endDate ? ' - ' + dayjs(endDate).format('DD') : ''
        }`}</div>
      </div>
    </StyledEventDate>
  )
}

EventDate.propTypes = {
  type: PropTypes.oneOf(['sm', 'range', undefined]),
}

export default EventDate
