import SelectLanguage from "../SelectLanguage";
import { Link } from 'react-router-dom'
import Image from "../image/Image";

import { StyledHeader } from "./styledHeader";

export default function Header({ language, handleLanguageChange }) {
  return (
    <StyledHeader>
      <div className="container skew skew-right skew-bottom">
        <SelectLanguage
          language={language}
          handleLanguageChange={handleLanguageChange}
        />
        <Link to={'/'}><Image path={"logo.png"} alt="provokatsia project logo" /></Link>
        <div className="slogan">
          events <span>*</span> workshops <span>*</span> and more...
        </div>
      </div>
    </StyledHeader>
  );
}
