import { useState } from 'react'
import { useFormFields, useMailChimpForm } from 'use-mailchimp-form'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { StyledNewsletterForm } from './styledNewsletterForm'

export default function NewsletterForm() {
  const url =
    'https://provokatsia-project.us21.list-manage.com/subscribe/post?u=1445b3722b31c2e9fde32d086&amp;id=3ce4fffbdf&amp;v_id=171&amp;f_id=0015dde6f0'
  const { loading, error, success, message, handleSubmit } =
    useMailChimpForm(url)
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: '',
    gdprEmail: 'Y',
  })
  const [hide, setHide] = useState(true)
  const toggleHide = () => setHide(!hide)

  return (
    <StyledNewsletterForm>
      <div className="newsletter">
        <div>
          <h2>Don&apos;t miss a thing!</h2>
          <h3>Sign up now</h3>
          <span className="subheader">
            and be the first to learn about our news, events and more!
          </span>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              handleSubmit(fields)
            }}
          >
            <input
              id="EMAIL"
              type="email"
              value={fields.EMAIL}
              onChange={handleFieldChange}
              placeholder="Your e-mail address"
            />
            <label className="checkbox subfield hideit" htmlFor="gdpr97420">
              <input
                type="checkbox"
                id="gdpr_97420"
                name="gdpr[97420]"
                className="gdpr"
                defaultValue={fields.gdprEmail}
                defaultChecked="checked"
              />
              <span>Email</span>
            </label>
            <div aria-hidden="true" className="hideit">
              {/* real people should not fill this in and expect good things - do not
              remove this or risk form bot signups */}
              <input
                type="text"
                name="b_1445b3722b31c2e9fde32d086_3ce4fffbdf"
                tabIndex="-1"
                defaultValue=""
              />
            </div>
            <p className="newsletter__disclaimer">
              By clicking below to subscribe you agree to our newsletter policy.{' '}
              <HashLink to={'/privacy-policy#newsletter'}>Read more</HashLink>
            </p>

            <div className={`newsletter__permissions${hide ? ' hide' : ''}`}>
              
            </div>
            <input
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              defaultValue="Subscribe"
            />
          </form>
          <p
            className={`message ${error ? 'error' : ''}${
              success ? 'success' : ''
            }`}
          >
            {loading && 'Submitting'}
            {error && message}
            {success && message}
          </p>
        </div>
      </div>
    </StyledNewsletterForm>
  )
}
