import * as React from 'react'
import { GrMail } from 'react-icons/gr'

const ContactUsHref = React.lazy(() => import('./ContactUsHref'))

// Make user click a button to show email adderss via lazy loading
export const ContactUs = () => {
  const [showingEmail, setShowingEmail] = React.useState(false)

  const toggleEmail = (event) => {
    event.preventDefault()
    setShowingEmail(true)

    setTimeout(() => setShowingEmail(false), 5000)
  }

  let email = showingEmail ? (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <GrMail /> <ContactUsHref />
    </div>
  ) : (
    <a href="#toggle" onClick={toggleEmail}>
      <GrMail />
    </a>
  )
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <div>{email}</div>
    </React.Suspense>
  )
}

export default ContactUs
