import styled from 'styled-components'

export const StyledDetailedEvent = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;

  .card {
    color: black;
    background-color: #fff;
    border: 1px solid #ccc;
    position: relative;
    height: 90%;
    width: 60%;
    padding: 20px;
    border-radius: 5px;
  }

  @media (max-width: 1280px) {
    .card {
      width: 80%;
      height: 90%;
    }
  }

  @media (max-width: 1023px) {
    .card {
      width: 94%;
      height: 94%;
    }
  }

  .card h2 {
    margin-top: 1.125rem;
  }

  .card__content {
    height: 100%;
    overflow: auto;
  }

  .description {
    display: flex;
  }

  .description__poster {
    flex: 0 0 35%;
    padding-right: 1.125rem;
  }

  @media (max-width: 768px) {
    .description__poster {
      flex: 0 0 40%;
    }
  }

  @media (max-width: 576px) {
    .description {
      display: block;
    }

    .description__poster {
      flex: 1;
      margin: 0 0 30px 0;
    }
  }

  .description__text {
    flex: 1;
  }

  .description__text p {
    margin-top: 0;
  }

  .card__artists h3 {
    margin-top: 1.5rem;
  }

  .card__artists .frame {
    display: flex;
    flex-direction: column;
  }

  .card__artists ul {
    margin: 0;
    padding: 0;
  }

  .card__artists ul li {
    list-style: none;
  }

  .card__artists .frames {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 25px;
    padding: 0 15px 25px 15px;
    margin: 0;
  }

  @media (max-width: 768px) {
    .card__artists .frames {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 25px;
    }
  }

  @media (max-width: 576px) {
    .card__artists .frames {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
      padding: 0 0px 15px;
    }
  }

  @media (max-width: 425px) {
    .card__artists .frames {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .card__artists .frames .artist {
    border-radius: 5px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    padding: 10px 10px 0;
  }

  .artist__name {
    flex: 1;
    font-size: 0.875rem;
  }

  .artist__name i {
    font-size: 0.75rem;
    color: #373737;
  }

  .artist__info {
    padding: 10px 0;
    position: relative;
  }

  .artist__links {
    position: absolute;
    right: 5px;
    top: -160px;
  }
  .artist__links a {
    background-color: #fff;
    height: 25px;
    width: 25px;
    display: inline-block;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
  .artist__links svg {
    color: #222;
    height: 20px;
    width: 20px;
  }

  .artist__image {
    height: 165px;
  }
  .artist__image img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 100%;
  }

  .card__video {
    margin-top: 1.25rem;
  }

  .card__button {
    font-size: 0;
    position: absolute;
    top: -25px;
    right: -25px;
    background-color: #fff;
    color: white;
    border: none;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .card__button {
      top: 5px;
      right: 5px;
    }
  }

  .card__button svg {
    fill: #222;
    height: 3rem;
    width: 3rem;
    transition: all 0.3s ease-in-out;
  }

  .card__button svg:hover {
    fill: #393939;
  }

  .clickable { 
    cursor: pointer; 
  }

  .clickable span {
    text-decoration: underline;
  }
`
