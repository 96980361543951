import { useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { useParams } from 'react-router-dom'

import { StyledHome } from './styledHome'
import NewsletterForm from '../newsletterForm/NewsletterForm'
import EventsList from '../eventsList/EventsList'

import { eventsEN } from '../../lib/eventsEN'
import { eventsGR } from '../../lib/eventsGR'
import { eventsDE } from '../../lib/eventsDE'

const Home = () => {
  const [language, setLanguage] = useLocalStorageState('language')
  const { lang } = useParams()

  useEffect(() => {
    lang && setLanguage(lang.toUpperCase())
  }, [])

  const now = new Date().toISOString()
  const events = { EN: eventsEN, GR: eventsGR, DE: eventsDE }[language]
  const upcomingEvents = events.filter((event) =>
    event.days.some((day) => day.endDate >= now)
  )
  const previousEvents = events.filter((event) =>
    event.days.some((day) => day.endDate < now)
  )
  return (
    <StyledHome>
      <div className="wrapper">
        
        <div className='welcome'>
          <div className="newsletter-form">
            <NewsletterForm />
          </div>
          <div className="welcome-text">
            {language === 'EN' && (
              <>
                <h1 className="main-header">
                  <span>Hey hey! Great to see you here!</span>
                  We are the Provokatsia Project team!
                </h1>
                <p>
                  Provokatsia Project is a team that organizes and supervises
                  concerts, music festivals, theatrical performances, book
                  presentations, film &amp; documentary screenings, DJ productions
                  &amp; sets as well as a variety of cultural &amp; social events
                  and much more!
                </p>
                <p>
                  Furthermore we are promoting self-organizing artists, while
                  highlighting solidarity and collaborative ventures. Provokatsia
                  acts and provokes the invisible hand of the market in order to
                  make non- commercial entities visible to the broader public.
                </p>
                <p>
                  So, welcome to our homepage, we hope you&apos;ll find something
                  interesting here and see you soon in one of our upcoming events!
                </p>
              </>
            )}

            {language === 'GR' && (
              <>
                <h1 className="main-header">
                  <span>Hey hey! Καλώς ήρθες!</span>
                  Είμαστε οι Provokatsia Project team!
                </h1>
                <p>
                  Η ομάδα Provokatsia Project διοργανώνει και επιμελείται
                  συναυλίες, μουσικά φεστιβάλ, θεατρικές παραστάσεις και
                  περφόρμανς, παρουσιάσεις βιβλίων, προβολές ντοκυμαντέρ και
                  ταινιών, DJ productions & sets, καθώς και διάφορα πολιτιστικά
                  και κοινωνικά events.
                </p>
                <p>
                  Κάτι σημαντικό που θα θέλαμε να γνωρίζεις για εμάς είναι ότι
                  προτείνουμε καλλιτέχνες που αυτό-οργανώνονται, αναδεικνύοντας
                  παράλληλα αλληλέγγυα και συνεργατικά εγχειρήματα. Μέσα από τη
                  δράση μας προβοκάρουμε το αόρατο χέρι της αγοράς με σκοπό να
                  κάνουμε ορατό το μη εμπορευματικό.
                </p>
                <p>
                  Καλώς ήρθες στην σελίδα μας, ελπίζουμε να βρεις κάτι ενδιαφέρον
                  και τα λέμε σε ένα από τα event μας!
                </p>
              </>
            )}

            {language === 'DE' && (
              <>
                <h1 className="main-header">
                  <span>Hey hey! Schön das du da bist!</span>
                  Wir sind das Provokatsia Project team!
                </h1>
                <p>
                  Das Provokatsia-Projekt Team organisiert und betreut Konzerte,
                  Musikfestivals, Theateraufführungen und Performances,
                  Buchpräsentationen, Dokumentar- und Filmvorführungen,
                  DJ-Produktionen und -Sets sowie verschiedene andere kulturelle
                  und soziale Veranstaltungen.
                </p>
                <p>
                  Darüber hinaus fördert das PP-Team selbstorganisierte Künstler
                  und Künstlerinnen, während Solidarität und kooperative Projekte
                  hervorgehoben werden. Damit provoziert es die unsichtbare Hand
                  des Marktes mit dem Ziel, das nicht Verkäufliche sichtbar zu
                  machen.
                </p>
                <p>
                  Also, willkommen auf unserer Homepage, wir hoffen, dass du was
                  spannendes für dich findest, und dass wir uns bald sehen!
                </p>
              </>
            )}
          </div>
        </div>

        <div className='events'>
          {!!upcomingEvents.length && 
            <>
              <h2>Upcoming events</h2>
              <EventsList events={upcomingEvents} />
            </>
          }
          
          <h2>Previous events</h2>
          <EventsList events={previousEvents} />
        </div>

      </div>
    </StyledHome>
  )
}

export default Home
