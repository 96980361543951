import { useState, useEffect } from "react";

const Image = ({ path, label }) => {
  const [src, setSrc] = useState("");
  const [alt, setAlt] = useState("");

  useEffect(() => {
    loadImage(path, label);
  }, [path, label]);

  const loadImage = (path, label) => {
    import(`../../assets/images/${path}`).then((image) => {
      setSrc(image.default);
      setAlt(label);
    });
  };

  return <img src={src} alt={alt} />;
};

export default Image;
