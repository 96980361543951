export default function SelectLanguage({ language, handleLanguageChange }) {
  return (
    <div>
      <label htmlFor="select-language" className="select-language"></label>
      <select
        id="select-language"
        value={language}
        onChange={handleLanguageChange}
        className="languages"
      >
        <option value="EN">EN</option>
        <option value="GR">GR</option>
        <option value="DE">DE</option>
      </select>
    </div>
  );
}
