import styled from 'styled-components'

export const StyledNewsletterForm = styled.div`
  .newsletter {
    position: relative;
    padding: 45px;
  }

  @media (max-width: 1023px) {
    .newsletter {
      margin-top: 25px;
    }
  }

  .newsletter:after {
    width: 100%;
    height: 100%;
    transform: skew(10deg);
    background-color: #3f3f3f;
    border-radius: 20px;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
  }

  .newsletter > div {
    position: relative;
    z-index: 5;
  }

  .newsletter h2 {
    margin: 0;
  }

  .newsletter h3 {
    color: var(--secondary-color);
    margin-bottom: 5px;
  }

  .newsletter .subheader {
    margin: 10px 0 25px;
    display: block;
  }

  .newsletter p {
    font-size: 0.875rem;
  }

  .newsletter form {
    position: relative;
  }

  .newsletter label {
    display: block;
  }

  .newsletter input[type='email'] {
    border: none;
    background-color: #dfdfdf;
    padding: 15px 20px;
    border-radius: 3px;
    display: block;
    outline: none;
    width: 100%;
  }

  .email-checkbox {
    display: none;
  }

  .newsletter button {
    width: 100%;
    margin-top: 5px;
  }

  .newsletter .message.success {
    color: green;
  }

  .newsletter .message.error {
    color: red;
  }

  .newsletter__permissions {
    top: calc(100% + 10px);
    position: absolute;
    left: 0;
    padding: 15px;
    background-color: #3f3f3f;
    border-radius: 5px;
  }

  .newsletter__permissions.hide {
    display: none;
  }

  .newsletter__disclaimer {
    font-size: 0.75rem;
  }

  .hideit {
    position: absolute;
    left: -5000px;
  }
`
