import parse from 'html-react-parser'

import { AiFillCloseCircle } from 'react-icons/ai'
import { StyledArtistDetails } from './styledArtistDetails'

export default function ArtistDetails({ artist, close }) {
  return (
    <StyledArtistDetails>
      <div className="card">
        <div className="card__content">
          <div>{parse(artist.description)}</div>
        </div>
        <button onClick={close} className="card__button btn">
          <AiFillCloseCircle />
        </button>
      </div>
    </StyledArtistDetails>
  )
}
