import { BsInstagram, BsFacebook } from 'react-icons/bs'
import useLocalStorageState from 'use-local-storage-state'
import { Link } from 'react-router-dom'

import ContactUs from './ContactUs'
import { StyledFooter } from './styledFooter'

export default function Footer() {
  const [language] = useLocalStorageState('language')
  
  const privacyPolicy = {
    EN: 'Privacy Policy',
    DE: 'Datenschutzerklärung',
    GR: 'Privacy Policy'
  }[language]

  return (
    <StyledFooter>
      <div className="wrapper">
        <div className="social">
          <div className="fb">
            <a
              href="https://www.facebook.com/provokatsiaProject"
              target={'_blank'} rel="noreferrer"
            >
              <BsFacebook />
            </a>
          </div>
          <div className="fb">
            <a
              href="https://www.instagram.com/provokatsia.project/"
              target={'_blank'} rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </div>
          <div>
            <ContactUs />
          </div>
        </div>

        <nav className='footer-navigation'>
          <ul>
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/privacy-policy'}>{privacyPolicy}</Link></li>
          </ul>
        </nav>
      </div>
    </StyledFooter>
  )
}
