import { CiLocationOn } from 'react-icons/ci'
import { BsTicketPerforated } from 'react-icons/bs'
import { BiHomeAlt2, BiTime, BiDoorOpen } from 'react-icons/bi'
import dayjs from 'dayjs'

import MapLink from '../../MapLink'

import { StyledEventDetails } from './styledEventDetails'

const EventDetails = ({ event }) => {
  return (
    <StyledEventDetails>
      <div className="event-basic-info">
        <div className="event-basic-info__icon">
          <BsTicketPerforated />
          <span>
            {event.entrance}
            {typeof event.entrance == 'number' && ' €'}
          </span>
        </div>

        <div className="event-basic-info__icon">
          <BiHomeAlt2 />
          <span>{event.locationName}</span>
        </div>

        <div className="event-basic-info__icon">
          <CiLocationOn />
          <MapLink url={event.googleLocation} label={event.locationAddress} />
        </div>

        <div className="event-basic-info__icon">
          <BiTime />
          <span>{event.time}</span>
        </div>

        <div className="event-basic-info__icon">
          <BiDoorOpen />
          <span>
            {event.doorsOpen} - {dayjs(event.endDate).format('HH:mm')}
          </span>
        </div>
      </div>
    </StyledEventDetails>
  )
}

export default EventDetails
