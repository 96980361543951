import parse from 'html-react-parser'

import VideoPlayer from '../VideoPlayer'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaYoutube } from 'react-icons/fa'
import { FaSpotify } from 'react-icons/fa'

import { StyledDetailedEvent } from './styledDetailedEvent'
import Image from '../image/Image'

export default function DetailedEvent({ detailedDay, popup, eventPath, onClickArtist }) {
  const { title, poster, artists, description, videoId, supplementary } =
    detailedDay

  return (
    <StyledDetailedEvent>
      <div className="card">
        <div className="card__content">
          <h2>{title}</h2>

          <div className="description">
            {poster && (
              <div className="description__poster">
                <Image path={poster} label={'the poster'} />
              </div>
            )}

            {description && (
              <div className="description__text">{parse(description)}</div>
            )}
          </div>

          {!!artists.length && (
            <div className="card__artists">
              <h3>Artists</h3>
              <ul
                className={`${typeof artists[0] !== 'string' ? 'frames' : ''}`}
              >
                {artists.map((artist) => (
                  <li key={artist.name} className="artist">
                    {typeof artist === 'string' ? (
                      <p>{artist.name}</p>
                    ) : (
                      <>
                        <div 
                          className={`artist__image${artist.description ? ' clickable' : ''}`}
                          onClick={
                            artist.description ? () => { onClickArtist(artist) } : () => {}
                          }
                        >
                          <Image
                            path={`artists/${eventPath}/${artist.image}`}
                            label={`image of the artist ${artist.name}`}
                          />
                        </div>
                        <div className="artist__info">
                          <div className={`artist__name${artist.description ? ' clickable' : ''}`}>
                            {artist.name}
                            <br />
                            <i>{artist.speciality}</i>
                            {artist.description && 
                              <>
                                <br />
                                <span onClick={() => {
                                  artist.description ? onClickArtist(artist) : (() => {})()
                                }}>
                                  Bio
                                </span>
                              </>
                            }
                          </div>
                          {(artist.youtube || artist.spotify) && (
                            <div className="artist__links">
                              {artist.spotify && (
                                <a href={artist.spotify} target={'_blank'}>
                                  <FaSpotify />
                                </a>
                              )}
                              {artist.youtube && (
                                <a href={artist.youtube} target={'_blank'}>
                                  <FaYoutube />
                                </a>
                              )}
                              
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {videoId && (
            <div class="card__video">
              <VideoPlayer videoId={videoId} />
            </div>
          )}

          {supplementary && (
            <div className="description__text">{parse(supplementary)}</div>
          )}
        </div>

        <button onClick={popup} className="card__button btn">
          <AiFillCloseCircle />
        </button>
      </div>
    </StyledDetailedEvent>
  )
}
