import React from 'react';

import useLocalStorageState from 'use-local-storage-state'
import TextEN from './TextEN'
import TextDE from './TextDE'
import TextGR from './TextGR'

export default function PrivacyPolicy() {
  const [language] = useLocalStorageState('language')
  
  const PrivacyPolicyComponent = {
    EN: () => <TextEN />,
    DE: () => <TextDE />,
    GR: () => <TextGR />,
  }[language]


  return (
    <div className="wrapper">
      <PrivacyPolicyComponent />
    </div>
  )
}
