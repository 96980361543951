import styled from 'styled-components'

export const StyledEventDetails = styled.div`
  .event-basic-info {
    border-top: 1px dashed #656565;
    border-bottom: 1px dashed #656565;
    padding: 20px 0;
  }

  .event-basic-info__icon {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .event-basic-info__icon svg {
    fill: var(--secondary-color);
    margin-right: 5px;
    height: 1.125rem;
    width: 1.125rem;
  }

  .event-basic-info__icon svg path {
    fill: var(--secondary-color);
  }

  .event-basic-info__subtitle {
    font-size: 0.75rem;
    margin-top: -10px;
  }
`
