import React from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import Image from '../image/Image'
import EventDate from '../Event/eventDate/EventDate'
import _ from 'lodash'

import { StyledEventsList } from './styledEventsList'

const EventsList = ({ events }) => {
  const navigate = useNavigate()

  const getLocations = (days) => {
    const locations = days.map((day) => day.locationName)

    return _.uniq(locations)
  }

  return (
    <StyledEventsList>
      <ul>
        {events.map((event) => {
          return (
            <li key={`event-${event.id}`} className="event">
              {event.isSuggested && (
                <div className="event__tag">Suggested event</div>
              )}
              <div
                className="event__poster"
                onClick={() => navigate(`/events/${event.url}`)}
              >
                <Image
                  path={`posters/${event.poster}`}
                  label={`poster of the event ${event.title}`}
                />
              </div>
              <div className="event__details">
                <EventDate
                  startDate={event.days[0].startDate}
                  endDate={
                    event.days.length > 1 ? event.days[0].endDate : undefined
                  }
                  type={event.days.length > 1 ? 'range' : 'sm'}
                />
                <div className="event__title">
                  <h3>
                    <Link to={`/events/${event.url}`}>{event.title}</Link>
                  </h3>
                  <p>
                    @{' '}
                    {event.days.length > 1
                      ? getLocations(event.days).map((location, index) => {
                          return `${index > 0 ? ' | ' : ''}${location}`
                        })
                      : event.days[0].locationName}
                  </p>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </StyledEventsList>
  )
}

EventsList.propTypes = {
  events: PropTypes.array,
}

export default EventsList
