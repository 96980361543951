import { useLayoutEffect } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

import Header from './components/Header'
import Event from './components/Event'
import Footer from './components/Footer'
import Home from './components/home/Home'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'

import './App.css'

const Wrapper = ({children}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

export default function App() {
  const [language, setLanguage] = useLocalStorageState('language', {
    defaultValue: 'EN',
  })

  function handleLanguageChange(event) {
    setLanguage(event.target.value)
  }
  
  return (
    <>
      <Header language={language} handleLanguageChange={handleLanguageChange} />
      <main id='main'>
        {/* <RouterProvider router={router} /> */}
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home language={language} />}/>
            <Route path="/:lang" element={<Home language={language} />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
            <Route path="/events/:url" element={<Event language={language} />}/>
            <Route path="/:lang/events/:url" element={<Event language={language} />}/>
          </Routes>
        </Wrapper>
      </main>
      <Footer />
    </>
  )
}
