import styled from 'styled-components'

export const StyledHeader = styled.header`
  .event {
    margin-top: 190px;
  }

  @media (max-width: 576px) {
    .event {
      margin-top: 150px;
    }
  }

  span {
    color: var(--secondary-color);
    padding: 7px;
  }

  a {
    display: inline-block;
    margin-top: -24px;
  }

  a img {
    transition: all 0.2s ease-in-out;
  }

  a:hover img {
    transform: rotate(10deg);
  }

  .container {
    text-align: center;
    background-color: #fff;
    height: 110px;
    margin-bottom: 190px;
  }

  .slogan {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Yanone Kaffeesatz';
    position: relative;
    z-index: 50;
  }

  .container img {
    position: relative;
    z-index: 2;
    width: 180px;
    height: auto;
    margin-top: 23px;
  }

  /* SelectLanguage Component */

  .select-language {
    color: black;
  }

  .languages {
    background-color: #ededed;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }
`
