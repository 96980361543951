import styled from "styled-components";

export const StyledFooter = styled.footer`
  background-color: #ededed;
  color: #222;

  a { color: #222; }

  .wrapper {
    padding: 20px 45px;
    display: flex;
    justify-content: space-between;
  }

  .social {
    display: flex;
    margin-right: 10px;
  }

  @media (max-width: 576px) {
    .wrapper {
      flex-direction: column;
      gap: 20px;
    }
    
    .social {
      justify-content: center;
    }
  }

  .wrapper button { font-size: 1rem; }

  .wrapper .social > div {
    margin-right: 15px;
  }

  @media (max-width: 576px) {
    .wrapper .social > div {
      margin-right: 0;
      margin: 0 8px;
    }
  }

  .wrapper svg {
    margin: 2px 5px 0 0;
    height: 1.25rem;
    width: 1.25rem;
  }

  .footer-navigation ul {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 576px) {
    .footer-navigation ul {
      text-align: center;
    }
  }
  
  .footer-navigation ul li {
    list-style: none;
    display: inline-block;
  }

  .footer-navigation ul li a {
    font-size: .75rem;
    text-transform: uppercase;
    margin-left: 10px;
  }

  @media (max-width: 576px) {
    .footer-navigation ul li a {
      margin-left: 0;
      margin: 0 8px;
    }
  }
`;
